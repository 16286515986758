import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-incrementor', templateUrl: './incrementor.component.html', styleUrls: ['./incrementor.component.scss'],
})
export class IncrementorComponent implements OnInit {

  @Input() value: any;
  @Input() units: string;
  @Input() limit: number;
  @Input() product: string;
  @Output() setValue: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('incrementer') incrementer: ElementRef;

  conversion = {
    STORAGE: 'GiB', MEMBERS: '', RECORDS: 'Month', EUR: 'Euros', DAYS: 'Days',
  };

  constructor() { }

  ngOnInit(): void {
  }

  onChange(value: Event) {
    this.incrementer.nativeElement.value = value;
    this.value = Number(value);
    this.setValue.emit(this.value);
  }

  changeValue(incrementValue: number) {
    if (this.value === null) this.value = 0;
    this.value = parseFloat(this.value);
    if (this.value + incrementValue <= 0 || this.value === null) {
      this.value = 0;
      return this.setValue.emit(this.value);
    }

    if (this.value + incrementValue >= this.limit && this.limit !== null) return this.value = this.limit;
    this.value = this.value + incrementValue;
    this.setValue.emit(this.units === 'EUR' ? this.value.toFixed(2) : this.value);
    this.incrementer.nativeElement.value = this.value;
    this.incrementer.nativeElement.focus();

  }

}
