import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { INSURANCE_OFFER_STATUS, REJECT_OFFER_BO_REASONS, SERVICES } from '../../../../common/models/app.models';
import { ApiCommonService } from '../../../../common/services/apiCommon.service';
import { InsuranceOffer, Rate } from '../../../../common/models/insurance.models';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InsuranceIntegration } from '../../../../common/models/integrations.models';

@Component({
  selector: 'app-detail-offer-modal-component',
  templateUrl: 'detail-offer-modal.component.html',
  styleUrls: ['./insurances.component.scss'],
})
export class DetailOfferModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
  INSURANCE_OFFER_STATUS = INSURANCE_OFFER_STATUS;
  ngOnInit() {
  }

  partialPrice(rate: Rate) {
    return (parseFloat(this.data?.insurable_value?.amount) * parseFloat(rate.client_price_percent)) / 100;
  }
}

@Component({
  selector: 'app-insurances',
  templateUrl: './insurances.component.html',
  styleUrls: ['./insurances.component.scss'],
})
export class InsurancesComponent implements OnInit {

  displayedColumns: string[] = ['record', 'provider', 'price', 'avaliableOn', 'state', 'offerDate', 'expiresOn', 'offer'];

  dataSource: MatTableDataSource<InsuranceOffer>;
  insurance: InsuranceOffer;
  integrations: any[];
  selectedProvider: InsuranceIntegration;
  INSURANCE_OFFER_STATUS = INSURANCE_OFFER_STATUS;
  view: 'list' | 'makeOffer' = 'list';
  totalPrice: number;
  selectedRate: Rate;
  rejectInsuranceOfferForm: FormGroup;
  showRejectForm: boolean;
  discountForm: FormGroup;
  insuranceOffersList: InsuranceOffer[];
  client: string;
  taxes: number;
  premium: number;
  exchangePrice: number;
  rateMinimumPrice: boolean;
  pageIndex = 0;
  pageSize = 10;
  totalOffers = 0;
  filteredIntegrations: InsuranceIntegration[];

  @ViewChild('paginator') paginator: MatPaginator;

  constructor(private router: Router, private dialog: MatDialog, private apiService: ApiCommonService, private fb: FormBuilder, private location: Location, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getInsurances(this.pageIndex, this.pageSize);
    this.createForm();
    this.route.queryParams.subscribe(async query => {
      if (query.offer_id && !query.make_offer) await this.showOfferDetail({ _id: query.offer_id });
      if (query.offer_id && query.make_offer) {
        await this.prepareOffer({ _id: query.offer_id });
      }
    });
  }

  checkRead(element: InsuranceOffer) {
    if (element.read) return null;
    this.apiService.markInsuranceOfferAsReaded(element._id, !element.read).subscribe(() => {
      element.read = !element.read;
    });
  }

  getNext(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getInsurances(this.pageIndex, this.pageSize);
  }

  getInsurances(pageIndex, pageCount) {
    this.apiService.getInsurances(pageIndex, pageCount).subscribe(res => {
      this.totalOffers = res.total;
      this.insuranceOffersList = res.insurance_offers;
      this.dataSource = new MatTableDataSource(res.insurance_offers);
    });
  }

  async showOfferDetail(element) {
    const res = await this.apiService.getInsurance(element._id).toPromise();
    this.insurance = res.insurance_offer;
    this.client = this.insurance.calculated_fields.available_rates[0];
    this.location.go('/', `section=1&offer_id=${this.insurance._id}`);
    return this.insurance;
  }

  async prepareOffer(element) {
    const resInsurance = await this.showOfferDetail(element);
    const res = await this.apiService.getIntegrations(this.insurance?.client_company?._id, SERVICES.INSURANCE).toPromise();
    this.integrations = res.integrations;

    const availableRates = resInsurance.calculated_fields.available_rates;
    // filtro las integrations por las que tienen rates que encajan con mi oferta, es decir, si soy forwarder, que disponga de rate para forwarder, y su soy cliente final, que disponga de alguna rate para cliente final, si esto no sucede, no aparecerán en la lista de disponibles.
    this.filteredIntegrations = this.integrations.filter(el => (availableRates.includes('FORWARDER') && el.provider?.settings?.rates.some(rate => rate.forwarder)) || (availableRates.includes('FINAL_CLIENT') && el.provider?.settings?.rates.some(rate => !rate.forwarder)));
    if (this.insurance.status === INSURANCE_OFFER_STATUS.PENDING) this.view = 'makeOffer';
  }

  closeDetail() {
    this.insurance = null;
    this.view = 'list';
    this.router.navigate([]);
    this.selectedProvider = null;
    this.selectedRate = null;
  }

  async openOfferDetailModal(element) {
    await this.showOfferDetail(element);
    this.dialog.open(DetailOfferModalComponent, { data: this.insurance });
  }

  partialPrice(rate: Rate) {
    return (parseFloat(this.insurance?.insurable_value?.amount) * parseFloat(rate.client_price_percent)) / 100;
  }

  finalPrice(rate: Rate, selectedProvider: InsuranceIntegration) {
    this.selectedRate = rate;
    const value: number = parseFloat(this.insurance.insurable_value.amount);
    this.totalPrice = (value * (Number(rate.client_price_percent) - (this.discountForm.get('discount').value || 0)) / 100);
    this.exchangePrice = this.totalPrice * this.insurance.exchange_rate;
    this.rateMinimumPrice = value * this.exchangePrice * Number(rate.provider_price_percent) < Number(selectedProvider.provider.settings.minimum_price?.amount);
    selectedProvider.provider.settings.rates.forEach(elm => elm.selected = false);
    rate.selected = true;
  }

  acceptInsuranceOffer(selectedProvider: InsuranceIntegration, selectedRate: Rate) {
    this.discountForm.markAllAsTouched();
    if (this.discountForm.invalid) return this.apiService.logError('Some fields have errors or are required');
    const value = this.discountForm.getRawValue();
    const data = {
      provider: selectedProvider.provider._id,
      rate_id: selectedRate.id,
      discount: value.discount,
    };
    if (this.totalPrice < 0) return this.apiService.logError('Estimated price it can not be negative');
    this.apiService.adminAcceptInsuranceOffer(this.insurance._id, data).subscribe(() => {
      this.getInsurances(this.pageIndex, this.pageSize);
      this.apiService.log('Insurance project launched successfully');
      this.closeDetail();
      this.selectedProvider = null;
      this.selectedRate = null;
    });
  }

  createFormDiscount(selectedProvider: InsuranceIntegration, rate: Rate) {
    this.selectedRate = rate;
    const value = selectedProvider.provider.settings.allows_discount ? selectedProvider.settings.discount : { value: 0, disabled: true };
    this.discountForm = this.fb.group({
      discount: [value, [Validators.required, this.validateDiscount(this.selectedRate)]],
    });
  }

  validateDiscount(selectedRate: Rate): (FormControl) => ValidationErrors {
    return (formcontrol: FormControl): ValidationErrors => {
      return formcontrol.value >= this.selectedRate?.provider_price_percent ? { discountBiggerThanProviderMargin: true } : null;
    };
  }

  createForm() {
    this.rejectInsuranceOfferForm = this.fb.group({
      reason: this.fb.group({
        code: [null, [Validators.required]],
        text: [null, []],
      }),
    });
  }

  adminRejectInsuranceOffer() {
    if (this.rejectInsuranceOfferForm.get('reason.code').value === REJECT_OFFER_BO_REASONS.OTHER) {
      this.rejectInsuranceOfferForm.get('reason.text').setValidators([Validators.required]);
      this.rejectInsuranceOfferForm.get('reason.text').updateValueAndValidity();
    }

    this.rejectInsuranceOfferForm.markAllAsTouched();
    if (this.rejectInsuranceOfferForm.invalid) return this.apiService.logError('Some fields have errors or are required');
    const data = this.rejectInsuranceOfferForm.getRawValue();
    this.apiService.log('Insurance project rejected correctly');

    this.apiService.adminRejectInsuranceOffer(this.insurance._id, data).subscribe(() => {
      this.getInsurances(this.pageIndex, this.pageSize);
      this.apiService.log('Insurance project rejected correctly');
      this.closeDetail();
      this.selectedProvider = null;
      this.selectedRate = null;
      this.closeRejectForm();
    });
  }

  openRejectOffer() {
    this.showRejectForm = true;
  }

  closeRejectForm() {
    this.showRejectForm = false;
  }
}
