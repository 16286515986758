<div class="container d-flex justify-content-center">
  <form *ngIf="!recovery && form" action="" [formGroup]="form" (ngSubmit)="login()" class="login-form p-5">
    <div class="d-flex justify-content-center flex-column">
      <mat-form-field>
        <input matInput placeholder="Email" formControlName="email" tabindex="2">
        <app-control-messages [control]="form.get('email')"></app-control-messages>
      </mat-form-field>
    </div>

    <div class="d-flex justify-content-center flex-column">
      <mat-form-field class="w-100">
        <input matInput placeholder="Password" [type]="!hide ? 'password' : 'text'" formControlName="password" tabindex="2">
        <app-control-messages [control]="form.get('password')"></app-control-messages>
        <div mat-icon-button class="pointer" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </div>
      </mat-form-field>
    </div>

    <div class="d-flex justify-content-center flex-column mt-5">
      <button mat-flat-button color="primary">Send</button>
      <app-control-messages [control]="form"></app-control-messages>
      <a class="formatted-link" (click)="openRecoveryPassword()">I forgot my password</a>
    </div>
  </form>

  <form *ngIf="recovery && changePasswordForm" [formGroup]="changePasswordForm" (ngSubmit)="changePassword()" class="login-form p-3">

    <mat-form-field class="mt-4 w-100">
      <input autocomplete="off" matInput [type]="!hide ? 'password' : 'text'" formControlName="password" placeholder="Enter your new password">
      <app-control-messages [control]="changePasswordForm.get('newPassword')"></app-control-messages>
    </mat-form-field>

    <mat-form-field class="w-100">
      <input matInput placeholder="Repeat your new password" [type]="!hide ? 'password' : 'text'" formControlName="repeatPassword">
      <app-control-messages [control]="changePasswordForm.get('repeatNewPassword')"></app-control-messages>
      <div mat-icon-button class="pointer" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </div>
    </mat-form-field>
    <app-control-messages [control]="changePasswordForm"></app-control-messages>

    <button mat-flat-button class="w-100 mt-4" color="primary">Change password</button>
  </form>
</div>

