import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
})
export class InputTextComponent implements OnInit {
  @Input() placeholder;
  @Input() maxLength;
  @Input() control;
  @Input() readonly;
  @Input() units;
  @Input() type = 'text';

  constructor() { }

  ngOnInit() { }

}
