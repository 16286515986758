import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search: string): string {
    const pattern = search
      .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
      .split(' ')
      .filter(t => t.length > 0)
      .join('|');
    const regex = new RegExp(pattern, 'gi');

    return search ? text.replace(regex, match => `<b>${match}</b>`) : text;
  }
}

@Pipe({ name: 'cast', pure: true })
export class CastPipe implements PipeTransform {
  transform(value: any): any {
    return value;
  }
}

@Pipe({ name: 'maxLength', pure: true })
export class MaxLength implements PipeTransform {
  transform(value: string, length: number): string {
    return value.length > length ? value.substring(0, length) + '...' : value;
  }
}

@Pipe({ name: 'sortTranslation' })
export class SortTranslationPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  async transform(array: Array<string>, prefix: string): Promise<Array<string>> {
    const data = await this.translate.get(array.map(e => prefix + e)).toPromise();
    array.sort((a: any, b: any) => {
      // Esto lo hacemos para que "Others" siempre aparezca de último en el select
      if (a === 'others') return +1;
      if (b === 'others') return -1;

      return (data[prefix + a] < data[prefix + b]) ? -1 : 1;
    });
    return array;
  }
}
