export abstract class Environment {
  abstract readonly production: boolean;
  abstract readonly url: string;
  abstract readonly bo: boolean;
}

import { Amount, BillingInfo } from './billing.models';

export enum ROLES {CREATOR = 'CREATOR', ACTOR = 'ACTOR', SUPPLIER = 'SUPPLIER', BUYER = 'BUYER', CONSIGNEE = 'CONSIGNEE', BANK = 'BANK', FWD = 'FWD', FWO = 'FWO', NOTIFY = 'NOTIFY', TRANSPORTER = 'TRANSPORTER', TAXES = 'TAXES', RECEPTION = 'RECEPTION' }

export enum DOCUMENTS {
  insurance_photos = 'document.insurance_photos', insurance_documents = 'document.insurance_documents', invoice = 'document.invoice', proforma = 'document.proforma',
  packing_list = 'document.packing_list', transport_document = 'document.transport_document', insurance = 'document.insurance',
  origin_certificate = 'document.origin_certificate', dua = 'document.dua', authenticity_certificate = 'document.authenticity_certificate',
  quality_certificate = 'document.quality_certificate', imo_declaration = 'document.imo_declaration', analysis_certificate = 'document.analysis_certificate',
  letter_of_credit = 'document.letter_of_credit', custom_authorization = 'document.custom_authorization', others = 'document.others',
  mbl = 'document.mbl', hbl = 'document.hbl', bl_release = 'document.bl_release', mawb = 'document.mawb', hawb = 'document.hawb', cmr = 'document.cmr', hcmr = 'document.hcmr',
  delivery_note = 'document.delivery_note', loi_letter = 'document.loi_letter', payment_receipt = 'document.payment_receipt',
  destination_expenses_invoice = 'document.destination_expenses_invoice', origin_expenses_invoice = 'document.origin_expenses_invoice',
  opening_card = 'document.opening_card', additional_instructions = 'document.additional_instructions', vgm = 'document.vgm', eur1_certificate = 'document.eur1_certificate',
  cim = 'document.cim', customs_requirement = 'document.customs_requirement', import_certificate = 'document.import_certificate', dv1 = 'document.dv1', c10 = 'document.c10', customs_letter = 'document.customs_letter',
  dua_import = 'dua_import', dua_export = 'dua_export', release_import = 'release_import', release_export = 'release_export', payment_letter = 'payment_letter',
  export_quotation = 'export_quotation', import_quotation = 'import_quotation', end_client_invoice = 'end_client_invoice', end_client_verification = 'end_client_verification',
}

export enum RESOURCE {
  buyer = 'buyer', consignee = 'consignee', supplier = 'supplier', reception = 'reception', fwd = 'fwd',
  fwo = 'fwo', notify = 'notify', destination = 'destination', transporter = 'transporter', deal = 'deal', edocs = 'edocs',
  transport = 'transport', payment = 'payment', financing = 'financing', invoice = 'invoice', proforma = 'proforma',
  packing_list = 'packing_list', transport_document = 'transport_document', insurance = 'insurance',
  origin_certificate = 'origin_certificate', dua = 'dua', authenticity_certificate = 'authenticity_certificate',
  quality_certificate = 'quality_certificate', imo_declaration = 'imo_declaration', analysis_certificate = 'analysis_certificate',
  letter_of_credit = 'letter_of_credit', custom_authorization = 'custom_authorization', others = 'others', payment_accept = 'payment_accept',
  buyer_comex_line = 'buyer_comex_line', buyer_debit_account = 'buyer_debit_account', compliance = 'compliance',
  compliance_buyer = 'compliance.buyer', compliance_supplier = 'compliance.supplier', compliance_fwd = 'compliance.fwd',
  compliance_fwo = 'compliance.fwo', authority = 'authority', external_services = 'external_services',
  inspection_services = 'inspection_services', watcher = 'watcher',
  mbl = 'mbl', hbl = 'hbl', bl_release = 'bl_release', mawb = 'mawb', hawb = 'hawb', cmr = 'cmr', hcmr = 'hcmr',
  delivery_note = 'delivery_note', loi_letter = 'loi_letter', payment_receipt = 'payment_receipt',
  destination_expenses_invoice = 'destination_expenses_invoice', origin_expenses_invoice = 'origin_expenses_invoice',
  insurance_photos = 'document.insurance_photos', insurance_documents = 'document.insurance_documents',
  opening_card = 'opening_card', additional_instructions = 'additional_instructions', vgm = 'vgm', eur1_certificate = 'eur1_certificate',
  transfer_payment = 'transfer_payment', form = 'form', cim = 'cim', customs_requirement = 'customs_requirement', import_certificate = 'import_certificate', dv1 = 'dv1', c10 = 'c10',
  customs_letter = 'customs_letter', dua_import = 'dua_import', dua_export = 'dua_export', release_import = 'release_import', release_export = 'release_export', payment_letter = 'payment_letter',
  export_quotation = 'export_quotation', import_quotation = 'import_quotation', end_client_invoice = 'end_client_invoice', end_client_verification = 'end_client_verification',
}

// Los totales son distintos de los que se muestran en permisos. Ya arreglaremos este desastre....espero...
export const PERMISSION_ROLES = ['SUPPLIER', 'BUYER', 'CONSIGNEE', 'FWD', 'FWO', 'NOTIFY', 'TRANSPORTER', 'DESTINATION', 'RECEPTION', 'AUTHORITY', 'EXTERNAL_SERVICES', 'INSPECTION_SERVICES', 'WATCHER', 'MANAGER', 'TAXES'];
export const PERMISSION_RESOURCES = ['buyer', 'consignee', 'supplier', 'reception', 'fwo', 'fwd', 'notify', 'transporter', 'financing', 'destination', 'deal', 'transport', 'payment', 'authority', 'external_services', 'inspection_services', 'watcher', 'manager'];
export const PERMISSION_DOCUMENTS = ['invoice', 'proforma', 'packing_list', 'transport_document', 'insurance', 'origin_certificate', 'dua', 'dua_import', 'dua_export', 'release_import', 'release_export', 'authenticity_certificate', 'quality_certificate', 'imo_declaration', 'analysis_certificate', 'custom_authorization', 'others', 'letter_of_credit',
  'mbl', 'hbl', 'bl_release', 'mawb', 'hawb', 'cmr', 'hcmr', 'delivery_note', 'loi_letter', 'payment_receipt', 'destination_expenses_invoice', 'origin_expenses_invoice', 'opening_card', 'additional_instructions', 'vgm', 'eur1_certificate', 'transfer_payment', 'form', 'import_certificate', 'dv1', 'c10', 'customs_letter', 'cim', 'customs_requirement',
  'payment_letter', 'export_quotation', 'import_quotation', 'end_client_invoice', 'end_client_verification',
];

export enum LEGALTYPES {INDIVIDUAL = 'individual', COMPANY = 'company' }

export enum REJECT_OFFER_BO_REASONS {MISSING_INFO = 'MISSING_INFO', WRONG_INFO = 'WRONG_INFO', DANGEROUS = 'DANGEROUS', DEFAULTER = 'DEFAULTER', UNINSURABLE = 'UNINSURABLE', OTHER = 'OTHER', MINIMUM_PRICE = 'MINIMUM_PRICE' }

export enum REJECT_OFFER_REASONS {HAS_INSURANCE = 'HAS_INSURANCE', DONT_LIKE_PROVIDER = 'DONT_LIKE_PROVIDER', HIGH_PRICE = 'HIGH_PRICE', DISAGREE_TERMS = 'DISAGREE_TERMS', DISAGREE_LEGAL = 'DISAGREE_LEGAL', OTHER = 'OTHER' }

export enum REJECT_OFFER_REASONS_PROVIDER {INCOMPLETE_INFO = 'INCOMPLETE_INFO', UNINSURABLE_GOODS = 'UNINSURABLE_GOODS', DANGEROUS_GOODS = 'DANGEROUS_GOODS', UNINSURABLE_COUNTRIES = 'UNINSURABLE_COUNTRIES', RISK_CLIENT = 'RISK_CLIENT', OTHER = 'OTHER'}

export enum INSURANCE_OFFER_STATUS {PENDING = 'PENDING', ADMIN_REJECTED = 'ADMIN_REJECTED', ADMIN_ACCEPTED = 'ADMIN_ACCEPTED', CLIENT_REJECTED = 'CLIENT_REJECTED', CLIENT_ACCEPTED = 'CLIENT_ACCEPTED', PROVIDER_REJECTED = 'PROVIDER_REJECTED', PROVIDER_ACCEPTED = 'PROVIDER_ACCEPTED', PAID = 'PAID', EXPIRED = 'EXPIRED', DELETED = 'DELETED', ADMIN_EXPIRED = 'ADMIN_EXPIRED', CLIENT_EXPIRED = 'CLIENT_EXPIRED', PROVIDER_EXPIRED = 'PROVIDER_EXPIRED', INVOICED = 'INVOICED' }

export enum INVOICESTATE {PENDING = 'PENDING', PAID = 'PAID', VOID = 'VOID'}

export enum RECORDSTATE {OPEN = 'OPEN', CLOSED = 'CLOSED'}

export enum BILLING_METHODS {SEPA_DIRECT_DEBIT = 'SEPA_DIRECT_DEBIT', CARD_STRIPE = 'CARD_STRIPE', SEPA_TRANSFER = 'SEPA_TRANSFER'}

export enum STATES { INCOMPLETED = 'INCOMPLETED', COMPLETED = 'COMPLETED', ACCEPTED = 'ACCEPTED', REJECTED = 'REJECTED', BLOCKED = 'BLOCKED'}

export enum FROZENGOODS {UNKNOWN = 'UNKNOWN', FROZEN = 'FROZEN', REFRIGERATED = 'REFRIGERATED', UNREFRIGERATED = 'UNREFRIGERATED'}

export enum EDOCS {EBL = 'EBL', ECMR = 'ECMR'}

// por ahora solo hay un proveeedor por tipo, lo dejo puesto aqui
export enum EDOCS_PROVIDERS {EBL = 'FIATA', ECMR = 'TRANSFOLLOW'}

export enum EDOCS_STATUS {DRAFT = 'DRAFT', PROCESSING = 'PROCESSING', ISSUED = 'ISSUED', FAILED = 'FAILED'}

export enum SERVICES {EDOCS = 'EDOCS', INSURANCE = 'INSURANCE'}

export enum ACTIONS { view = ':read', modify = ':update', update = ':create', invite = ':invite' }

export enum CHANGE_TYPES { Update = 'FIELD_UPDATED', Added = 'FIELD_ADDED', Removed = 'FIELD_REMOVED', Invite_Added = 'INVITE_ADDED', Invite_Removed = 'INVITE_REMOVED', Document_Uploaded = 'DOCUMENT_UPLOADED', Document_Updated = 'DOCUMENT_VERSION_UPLOADED', Compliance_Checked = 'COMPLIANCE_CHECKED', Array_Added= 'ARRAY_ADDED', Array_Updated = 'ARRAY_UPDATED', Array_Removed = 'ARRAY_REMOVED' }

export enum TIMELINE_PROPERTIES { code = 'code', flight = 'flight', tatc = 'tatc', type = 'type', number = 'number', date = 'date', units = 'units', HS_code = 'HS_code', fee = 'fee', box = 'box', invoice_date = 'invoice_date', good_description = 'good_description', sku = 'sku', invoice_n = 'invoice_n', value = 'value', weight = 'weight', origin = 'origin', invitations = 'invitations', deposit = 'deposit', unit_discount_value = 'unit_discount_value',
  unit_price = 'unit_price', description = 'description', open = 'open' }

export enum  TIMELINE_DIMENSIONS { box = 'box', length = 'length', height = 'height', width = 'width', stackable = 'stackable', type = 'type'}

export enum TIMELINE_FIELDS { invoice = 'invoice_value', deferred_payment = 'deferred_payment_data', payment = 'payment', itinerary = 'itinerary', transhipments = 'transhipments_allowed', parcial_shipment = 'parcial_shipment_allowed', exchange = 'exchange_agreement', dimensions = 'dimensions', type = 'type', maritim_container = 'maritim_container', maritim_type = 'maritim_type', country = 'country', delivery = 'delivery', origin = 'origin', HS_code = 'HS_code', good_origin = 'good_origin', due = 'due', lame = 'lame', customs_clearance = 'customs_clearance' }

export enum PAYMENT_TYPES_TIMELINE { cash = 'cash', days30 = 'days30', days60 = 'days60', days90 = 'days90', creditCard = 'creditCard', payment_on_due_date = 'payment_on_due_date', atSight = 'atSight', deferredPayment = 'deferredPayment' }

export enum CONVERSION_FACTOR { air = '167', maritim = '1000', railway = '1000', road = '333' }

export enum TRANSPORT_MAX_HEIGHT { air = '150', maritim = '220', railway = '220', road = '270' }

export enum BLOCKCHAIN_TRANSACTIONS { mock = 'Mock', ganache = 'Ganache', besu = 'Besu', rinkeby = 'Rinkeby', alastria = 'Alastria', lacchain = 'Lac-net', lacchaind19 = 'Lacchain' }

export enum PAYMENT_TYPES { cash = 'cash', days30 = 'days30', days60 = 'days60', days90 = 'days90', creditCard = 'creditCard'}

export enum MARITIME_CONTAINERS { __INCHES_20__ = '__INCHES_20__', __INCHES_40__ = '__INCHES_40__', __FLAT_RACK__ = '__FLAT_RACK__', __HIGH_CUBE__ = '__HIGH_CUBE__', __OPEN_SIDE__ = '__OPEN_SIDE__', __OPEN_TOP__ = '__OPEN_TOP__', __PALLET_WIDE__ = '__PALLET_WIDE__', __REEFER__ = '__REEFER__', __TANK__ = '__TANK__' }

export enum BOXES_TYPES { __PALLET__ = '__PALLET__', __BOX__ = '__BOX__', __CRATE__ = '__CRATE__', __SACK__ = '__SACK__', __BOTTLE__ = '__BOTTLE__', __DRUM__ = '__DRUM__', __BUNDLE__ = '__BUNDLE__' }

export enum AUTOCOMPLETE_OPTIONAL { BOXES = 'boxes', CONTAINER = 'container', PAYMENT_TYPES = 'paymentTypes' }

export enum CUSTOMS_CLEARANCE_CODES { GREEN = 'GREEN', RED = 'RED', ORANGE = 'ORANGE', YELLOW = 'YELLOW', NA = 'NA'}

export const INVITATION_ACTORS = ['BUYER', 'SUPPLIER', 'FWD', 'FWO', 'DESTINATION', 'RECEPTION', 'NOTIFY', 'CONSIGNEE', 'TRANSPORTER', 'EXTERNAL_SERVICES', 'AUTHORITY', 'WATCHER', 'MANAGER'];

export class File {
  id?: string;
  versions?: Version[];
  type?: string;
  index?: number;
  new?: boolean;
}

export class FileForView extends File {
  selectedVersion?: number;
}

export class Version {
  id: string;
  hash: string;
  uploaded_at?: Date;
  link?: string;
  name?: string;
  description?: string;
  type?: string;
  uploaded_by?: {
    _id?: string; name: string; company: {
      _id?: string; name: string
    }
  };
  size?: number;
  blockchain?: BC[];
  transaction?: string;
}

export class BC {
  name: string;
  upload: {
    transaction: string; url: string;
  };
}

export class Transaction {
  hash: string;
  value: string;
  createdAt: Date;
  transaction: string;
}

export class BillingData {
  EORI_NIF?: string;
  name?: string;
  address?: string;
  city?: string;
  zip_code?: string;
  country?: string;
  contact?: string;
  phone?: string;
  reference?: string;
  emails?: Email[];
  company?: string;
  createdAt?: Date;
  requestedValidationAt?: Date;
  validatedAt?: Date;
  blocked_at?: Date;
  blocked_reason?: string;
  company_logo?: string;

  constructor(data: any = {}) {
    this.EORI_NIF = data.EORI_NIF || null;
    this.name = data.name || null;
    this.address = data.address || null;
    this.city = data.city || null;
    this.zip_code = data.zip_code || null;
    this.country = data.country || null;
    this.contact = data.contact || null;
    this.phone = data.phone || null;
    this.reference = data.reference || null;
    this.emails = data.emails || [];
  }
}

export class SpecialRole {
  emails?: Email[];
  constructor(data: any = {}) {
    this.emails = data.emails || [];
  }
}

class Consignee extends BillingData {
}

class Buyer extends BillingData {
  GRN?: string;
  has_consignee?: boolean;
  comex_line?: string;
  debit_account?: string;
}

class Supplier extends BillingData {
  REX?: string;
  BIC?: string;
  account?: string;
}

class Notify extends BillingData {
}

class Destination extends BillingData {
  delivery?: string;
  preferred_schedule?: string;
  notes?: string;
}

export class RecordWrapper {
  record: Record;
  invited: number;
  is_creator: boolean;
  permissions: string[];
  roles: string[];
}

export class Port {
  country?: string;
  location?: string;
  constructor() {
    this.country = null;
    this.location = null;
  }
}

export class Itinerary {
  reception?: Port;
  shipment?: Port;
  unload?: Port;
  transfer?: Port;
  delivery?: Port;
  final?: Port;

  constructor() {
    this.reception = new Port();
    this.shipment = new Port();
    this.unload = new Port();
    this.transfer = new Port();
    this.delivery = new Port();
    this.final = new Port();
  }
}

export class Record {
  _id?: string;
  createdAt?: Date;
  sequence?: string;
  is_creator?: boolean;
  is_checked?: boolean;
  own_reference?: string;
  mrn?: string;
  buyer?: Buyer;
  consignee?: Consignee;
  supplier?: Supplier;
  reception?: BillingData;
  state?: string;
  roles?: string[];
  firstRole?: string;
  title?: string;

  deal?: {
    invoice_value?: number;
    good_origin?: string;
    currency?: string;
    weight?: number;
    net_weight?: number;
    box?: number;
    customs_clearance?: string;
    HS?: DealHS[];
    incoterm?: string;
    incoterm_ref?: string;
    shipment_date?: Date;
    last_shipped?: Date;
    ETA?: Date;
    banking_guarantee_date?: Date;
    dimensions: Dimensions[];
    dimensions_units?: string;
    issues?: Issues[]
  };

  transport?: {
    type?: string;
    mawb?: TransportFields;
    hawb?: TransportFields[];
    maritim_container?: TransportFields[];
    flights?: Flight[];
    maritim_MBL?: string;
    maritim_type?: string;
    maritim_HBL?: string[];
    ETD?: Date;
    last_shipped?: Date;
    ETA?: Date;
    lame?: string;
    maritim_vessel?: string;
    road_cmr?: {
      tatc?: string;
      code?: string,
      registration?: string,
      trailer?: string,
      driver?: string
    };
    railway_container?: any;
    transhipments_allowed?: boolean;
    parcial_shipment_allowed?: boolean;
    itinerary?: Itinerary;
  };

  payment?: {
    due?: string;
    deferred_payment_method?: Date;
    days_from_date_value?: string;
    on_due_date?: number;
    days_from_completion?: number;
    days_from_date?: number;
    exchange_agreement?: boolean;
    exchange_agreement_value?: string;
    accept?: boolean;
    not_accept?: boolean;
    rate?: number;
  };

  files?: File[];

  financing?: {
    financing_needed?: boolean; amount?: number; currency?: string; days_from_effective_date?: boolean; fix_maturity_date?: boolean; fix_maturity_date_value?: Date; currency_exchange_reference?: string; buyer_compliance?: boolean; buyer_compliance_transaction?: Transaction; supplier_compliance?: boolean; supplier_compliance_transaction?: Transaction; fwo_compliance?: boolean; fwo_compliance_transaction?: Transaction; fwd_compliance?: boolean; fwd_compliance_transaction?: Transaction; buyer_bank_compliance?: boolean; buyer_bank_compliance_transaction?: Transaction;
  };

  notify?: Notify;
  transporter?: BillingData;

  fwo?: BillingData;
  fwd?: BillingData;

  owners?: {
    creator: string;
    creator_user?: string;
  };

  // authority, external_services, inspection_services, watcher

  authority?: SpecialRole;
  inspection_services?: SpecialRole;
  external_services?: SpecialRole;
  watcher?: SpecialRole;
  manager?: SpecialRole;

  destination?: Destination;
  constructor() {
    this.buyer = new BillingData();
    this.consignee = new BillingData();
    this.supplier = new BillingData();
    this.reception = new BillingData();
    this.deal = { HS: [], dimensions: [], issues: [] };
    this.transport = {
      hawb: [],
      flights: [],
      maritim_container: [],
      maritim_HBL: [],
      itinerary: new Itinerary(),
    };
    this.payment = {};
    this.files = [];
    this.financing = {};
    this.notify = new BillingData();
    this.transporter = new BillingData();
    this.destination = new BillingData();
    this.fwo = new BillingData();
    this.fwd = new BillingData();
    this.watcher = new SpecialRole();
    this.authority = new SpecialRole();
    this.external_services = new SpecialRole();
    this.inspection_services = new SpecialRole();
    this.manager = new SpecialRole();
  }
}

export class TransportFields {
  code?: string;
  type?: string;
  tatc?: string;
  deposit?: string;
  flight?: string;
  lame?: Lame;
}

export class Lame {
  code?: string;
  date?: string;
}

export class Flight {
  number?: string;
  date?: string;
}

export class Dimensions {
  _key?: string;
  length?: number;
  width?: number;
  height?: number;
  box?: number;
  type?: string;
  stackable?: boolean;
}

export class DealHS {
  _key?: string;
  invoice_n?: string;
  invoice_date?: Date;
  HS_code?: string;
  good_description?: string;
  value?: number;
  weight?: number;
  box?: number;
  origin?: string;
  sku?: string;
  units?: number;
  fee?: number;
  unit_discount_value?: number;
  unit_price?: number;
}

export class Issues {
  code?: string;
  description?: string;
  date?: Date;
  open?: boolean;
}

export class Company extends BillingData {
  _id?: string;
  payment_method?: string;
  plan?: string;
  state?: string;
  email?: string;
  REX?: string;
  BIC?: string;
  account?: string;
  logo?: string;
  role?: string;
  rejectedReason?: string;
  files?: File[];
  fiata_id?: string;
  billing_info?: BillingInfo;
  is_blocked?: boolean;
  onboarding?: string;
  onboarding_step?: string | null;
  unpaid_charge_limits?: {
    total: Amount;
    services: {
      INSURANCE: Amount;
      EDOCS: Amount
    }
  };
  managers?: any[];
  creator_is_manager?: boolean;
  recordInvitations?: [{emails: string[], actors: string[]}];
}

export class LoginWrapper {
  user: User;
  token: string;
}

export class UserNotification {
  alert_time?: number;
  config?: {};
  aggregate_time?: number;
  pushSubscription?: PushSubscriptionJSON;
  webhook?: string;
}

export class User {
  _id?: string;
  termsAndConditions?: {
    lastVersionAccepted: boolean;
    versions: {
      accepted: Date,
      version: string,
      hash: string,
    }[];
  };
  email?: string;
  email_verified?: Date;
  password?: string;
  company?: Company;
  company_owner?: boolean;
  name?: string;
  language?: string;
  guest?: boolean;
  createdAt?: Date;
  registered_at?: Date;
  notifications?: UserNotification;
  external?: {
    segment?: string;
  };
}

export class Email {
  name: string;
  email: string;
}

export class FavoriteCompanies {
  buyer: BillingData[];
  consignee: BillingData[];
  supplier: BillingData[];
  reception: BillingData[];
  fwo: BillingData[];
  fwd: BillingData[];
  notify: BillingData[];
  transporter: BillingData[];
  destination: BillingData[];
  partners: Email[];
  constructor(data) {
    data = JSON.parse(JSON.stringify(data || {})); // inmutable
    this.buyer = data.buyer || [];
    this.consignee = data.consignee || [];
    this.supplier = data.supplier || [];
    this.reception = data.reception || [];
    this.fwo = data.fwo || [];
    this.fwd = data.fwd || [];
    this.notify = data.notify || [];
    this.transporter = data.transporter || [];
    this.destination = data.destination || [];
    this.partners = data.partners || [];
  }
  insertMyCompany(myCompany: BillingData) {
    this.buyer.unshift(myCompany);
    this.consignee.unshift(myCompany);
    this.supplier.unshift(myCompany);
    this.reception.unshift(myCompany);
    this.fwo.unshift(myCompany);
    this.fwd.unshift(myCompany);
    this.notify.unshift(myCompany);
    this.transporter.unshift(myCompany);
    this.destination.unshift(myCompany);
  }
}

export class ChatWrapper {
  chats: Conversation[];
}

export class Conversation {
  messages?: Message[];
  lastMessage?: Message;
  locked?: boolean;
  pending?: number;
  read?: number;
  receiver?: string;
  receiver_name?: string;
  receiver_actors?: string[];
  record?: string;
  record_sequence?: string;
}

export class Message {
  content?: string;
  createdAt?: Date;
  sender_company?: string;
  sender_company_name?: string;
  sender_user?: string;
  sender_user_name?: string;
}

export class PermsWrapper {
  perms: string[];
  roles: string[];
}

export class InvoiceParsed {
  documentAI: string;
  entities: {
    currency?: string;
    due_date?: Date;
    invoice_date?: Date;
    payment_terms?: string;
    supplier_tax_id?: string;
    supplier_address?: string;
    invoice_id?: string;
    supplier_name?: string;
    receiver_name?: string;
    receiver_address?: string;
    total_tax_amount?: string;
    total_amount?: string;
    net_amount?: string;
    ship_to_name?: string;
    ship_to_address?: string;
    delivery_date?: string;
    purchase_order?: string
    line_item?: string[];
  };
  images: string[];

}

export class NotificationWrapper {
  notifications: Notification[];
  page_count: number;
  page_start: number;
  total: number;
  total_unread: number;
}

export class Notification {
  _id: string;
  user: string;
  action: string;
  read: boolean;
  createdAt: Date;
  params?: NotificationParams;
  url: string;
}

export class NotificationParams {
  actor?: string;
  user_name?: string;
  sequence?: string;
  record_id?: string;
  offer_id?: string;
  type?: string;
  docId?: string;
  companyName?: string;
  days?: string;
  chat_id?: string;
  financing_id?: string;
  provider_name?: string;
  edoc_id?: string;
  edoc_type?: string;
  transportCode?: string;
  transportData?: any;
}

export class Financing {
  company?: string;
  created_at?: Date;
  open?: boolean;
  record?: { _id?: string; sequence?: string };
  role?: string;
  updated_at?: Date;
  sent_at?: Date;
  _id?: string;
  contact_phone?: number;
  is_big_company?: boolean;
}

export class EdocsWrapper {
  edocs: [];
}

export class TimelineEvent {
    changes: TimelineChange[];
    created_at: Date;
    record_id: string;
    user_email: string;
}

export class TimelineChange {
  change_type: string;
  section: string;
  new_value: any;
  old_value: any;
  field_name: string;
  subsection: string;
  subchanges: TimelineChange[];
}
