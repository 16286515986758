import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginBOComponent, ModalRecoveryPasswordComponent } from './login/login-b-o.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './authInterceptor';
import { AuthGuard } from './auth.guard';
import { FileInfoComponent } from './common/doc-info/file-info.component';
import { MaxUploadFilesHintComponent } from './common/max-upload-files-hint/max-upload-files-hint.component';
import { ProfileChangePasswordComponent, ProfileComponent } from './profile/profile.component';
import { CompaniesListComponent } from './companies-list/companies-list.component';
import { CreateCompanyComponent } from './create-company/create-company.component';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { Environment } from '../../../common/models/app.models';
import { environment } from '../environments/environment';
import { CommonModule } from '../../../common/common.module';
import { IncrementorComponent } from './common/incrementor/incrementor.component';
import { SegmentModule } from 'ngx-segment-analytics';
import { MyErrorHandler } from '../../../common/shared/error-handler';
import { HomeComponent } from './pages/home/home.component';
import { DetailOfferModalComponent, InsurancesComponent } from './insurances/insurances.component';
import { CompanyServicesComponent } from './insurances/company-services/company-services.component';
import { CompanyTppComponent } from './insurances/company-tpp/company-tpp.component';
import { NotificationsBarBOComponent } from './notifications-bar-bo/notifications-bar-bo.component';
import { Helper } from '../../../front/src/app/helper';
import { NotificationsTableBOComponent } from './notifications-bo/notifications-table-bo.component';
import { DetailEdocModalComponent, EdocsServicesComponent } from './edocs-services/edocs-services.component';

registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    LoginBOComponent,
    CompaniesListComponent,
    CompanyDetailComponent,
    FileInfoComponent,
    MaxUploadFilesHintComponent,
    ModalRecoveryPasswordComponent,
    ProfileComponent,
    ProfileChangePasswordComponent,
    CreateCompanyComponent,
    IncrementorComponent,
    HomeComponent,
    InsurancesComponent,
    CompanyServicesComponent,
    CompanyTppComponent,
    NotificationsBarBOComponent,
    NotificationsTableBOComponent,
    DetailOfferModalComponent,
    EdocsServicesComponent,
    DetailEdocModalComponent,
  ],
  imports: [CommonModule, AppRoutingModule, SegmentModule.forRoot({ apiKey: environment.segmentKey, debug: !environment.production, loadOnInitialization: !!environment.segmentKey })],
  entryComponents: [
    ModalRecoveryPasswordComponent,
    ProfileChangePasswordComponent,
  ],
  providers: [
    Helper,
    { provide: Environment, useValue: environment },
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'en' },
    { provide: ErrorHandler, useClass: MyErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
