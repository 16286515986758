import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-hover',
  templateUrl: './info-hover.component.html',
  styleUrls: ['./info-hover.component.scss'],
})
export class InfoHoverComponent {
  @Input() sentence;
  @Input() size: 'sm' | 'm';
  @Input() status = 'warning';
  @Input() style = 'solid';

}
