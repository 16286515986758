<div class="container">
  <div *ngIf="provider?.service !== 'INSURANCE'">
    <h2 class="form-header__title d-flex align-items-center">
      <mat-icon>https</mat-icon>
      Insurances
    </h2>

    <div class="d-flex ml-2 bg-limit">
      <div class="font-s mr-2">Monthly insurances spending limit</div>
      <span class="font-s mr-2">{{company?.unpaid_charge_limits?.services?.INSURANCE ? (company?.unpaid_charge_limits?.services?.INSURANCE.amount + company?.unpaid_charge_limits?.services?.INSURANCE.currency) : 'Unlimited'}} </span>
      <app-info-hover [sentence]="'You can edit the monthly limit in the customize subscription section in Account info'" [size]="'sm'"></app-info-hover>
    </div>

    <div class="d-flex">

      <div *ngIf="integrationForm" class="row mt-4">
        <div *ngFor="let integration of integrationForm.controls">
          <mat-card class="card-integration my-2 mx-4 col-3 col-lg-6">
            <div class="d-flex justify-content-between font-m">
              <mat-card-title class="font-m">{{integration.get('provider.display_name').value}}</mat-card-title>
              <mat-icon (click)="changeEnabled(integration.get('enabled'))" class="pointer" [ngClass]="{'enabled': integration.get('enabled').value}">{{integration.get('enabled').value ? 'check_circle_outline' : 'not_interested'}}</mat-icon>
            </div>
            <div class="d-flex align-items-center mb-2 input-w">
              <mat-icon class="mr-2"> mail_outline</mat-icon>
              <mat-card-subtitle [matTooltip]="integration.get('provider.settings.brokerage_contact.contact_email').value" class="input-w">{{integration.get('provider.settings.brokerage_contact.contact_email').value}}</mat-card-subtitle>
            </div>
            <div class="d-flex align-items-center">
              <mat-icon class="mr-2"> phone</mat-icon>
              <mat-card-subtitle class="m-0">{{integration.get('provider.settings.brokerage_contact.contact_phone').value}}</mat-card-subtitle>
            </div>
            <hr>
            <div class="d-flex justify-content-between align-items-center">
              <div class="mr-2 font-m">Discount</div>
               <mat-form-field class="with-units w-50 mr-0 mr-sm-3">
                <input [formControl]="integration.get('settings.discount') | cast" type="number" min="0" numeric decimals="0" matInput
                       placeholder="">
                <span class="units">%</span>
                <app-control-messages [control]="integration.get('settings.discount')"></app-control-messages>
              </mat-form-field>
            </div>
          </mat-card>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end align-items-end mt-2">
      <button (click)="saveIntegration()" mat-flat-button color="primary">Save Insurance TPP</button>
    </div>
  </div>

  <div *ngIf="edocsIntegrations?.length">
    <h2 class="mt-5 form-header__title d-flex align-items-center">
      <mat-icon class="material-icons-outlined">insert_drive_file</mat-icon>
      eDocs
    </h2>

    <div class="d-flex ml-2 bg-limit">
      <div class="font-s mr-2">Monthly insurances spending limit</div>
      <span class="font-s mr-2">{{company?.unpaid_charge_limits?.services?.EDOCS ? (company?.unpaid_charge_limits?.services?.EDOCS.amount + company?.unpaid_charge_limits?.services?.EDOCS.currency) : 'Unlimited'}} </span>
      <app-info-hover class="d-flex align-items-center" [sentence]="'You can edit the monthly limit in the customize subscription section in Account info'" [size]="'sm'"></app-info-hover>
    </div>

    <div *ngFor="let edocIntegration of edocIntegrationForm?.controls, let i = index" class="d-flex align-items-center">
      <div class="row mt-4">
        <div>
          <mat-card class="card-integration my-2 mx-4 col-3 col-lg-6">
            <div class="d-flex justify-content-between font-m">
              <mat-card-title class="font-m">{{edocIntegration.get('display_name').value}}</mat-card-title>
              <mat-icon (click)="changeEnabled(edocIntegration.get('enabled'))" class="pointer" [ngClass]="{'enabled': edocIntegration.get('enabled').value}">{{edocIntegration.get('enabled').value ? 'check_circle_outline' : 'not_interested'}}</mat-icon>
            </div>
            <div class="d-flex align-items-center mb-2 input-w">
              <mat-icon [matTooltip]="edocIntegration.get('contact_email').value" [matTooltipDisabled]="edocIntegration.get('contact_email').value.length < 16" class="mr-2 pointer"> mail_outline</mat-icon>
              <mat-card-subtitle class="input-w">{{edocIntegration.get('contact_email').value}}</mat-card-subtitle>
            </div>
            <div class="d-flex align-items-center mt-3">
              <mat-icon class="mr-2"> phone</mat-icon>
              <mat-card-subtitle class="m-0">{{edocIntegration.get('contact_phone').value}}</mat-card-subtitle>
            </div>
            <div class="d-flex align-items-center">
              <mat-icon class="material-icons-outlined mr-2">assignment</mat-icon>
              <app-input-text placeholder="apiKey" [control]="edocIntegration.get('settings.api_key')"></app-input-text>
            </div>
            <hr>
            <div class="mr-2 font-m">Discount</div>
            <div *ngFor="let avaiableDocument of edocIntegration.get('settings.documentsAvailable').value" class="d-flex justify-content-between align-items-center">
              <div class="mr-2 font-m">{{avaiableDocument}}</div>

              <mat-form-field class="with-units w-50 mr-0 mr-sm-3">
                <input [formControl]="edocIntegration.get('settings.discount.'+avaiableDocument) | cast" type="number" min="0" numeric decimals="0" matInput
                       placeholder="">
                <span class="units">%</span>
                <app-control-messages [control]="edocIntegration.get('settings.discount.'+avaiableDocument)"></app-control-messages>
              </mat-form-field>

            </div>
          </mat-card>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end align-items-end mt-2">
      <button (click)="editEdocIntegrations()" mat-flat-button color="primary">Save eDocs TPP</button>
    </div>

  </div>

  <h2 class="mt-5 form-header__title d-flex align-items-center">
    <mat-icon>https</mat-icon>
    Financing
  </h2>
  <div class="d-flex align-items-center">
    <mat-card class="basic-card my-2 mx-2">
      <div class="d-flex justify-content-between align-items-center">
        <mat-card-subtitle>Monthly insurances <br> spending limit</mat-card-subtitle>
        <div>0€</div>
      </div>
    </mat-card>
    <app-info-hover [sentence]="'You can edit the monthly limit in the customize subscription section in Account info'"></app-info-hover>
  </div>

</div>
