<div mat-dialog-title class="position-relative ">
  <div (click)="closeModal()" class="position-absolute modal-close-icon pointer"><i class="material-icons pointer"> close </i></div>
</div>
<div mat-dialog-content>
  <h1>Change password</h1>
  <form [formGroup]="changePasswordForm">
    <mat-form-field class="mt-4 w-100">
      <input autocomplete="off" matInput type="text" formControlName="oldPassword" placeholder="Enter your old password">
      <app-control-messages [control]="changePasswordForm.get('oldPassword')"></app-control-messages>
    </mat-form-field>

    <mat-form-field class="mt-4 w-100">
      <input autocomplete="off" matInput type="text" formControlName="newPassword" placeholder="Enter your new password">
      <app-control-messages [control]="changePasswordForm.get('newPassword')"></app-control-messages>
    </mat-form-field>

    <mat-form-field class="mt-4 w-100">
      <input autocomplete="off" matInput type="text" formControlName="repeatNewPassword" placeholder="Repeat your new password">
      <app-control-messages [control]="changePasswordForm.get('repeatNewPassword')"></app-control-messages>
    </mat-form-field>
    <button mat-flat-button (click)="changePassword()" [disabled]="changePasswordForm.invalid" color="primary">Send</button>
  </form>
</div>
