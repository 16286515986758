<h2 *ngIf="showHeader" class="mt-5 mb-3 form-header__title d-flex align-items-center">
  <mat-icon class="mr-2">assessment</mat-icon> {{'common.billingData.billingData' | translate}}</h2>
<div class="d-flex flex-column align-items-center justify-content-center py-3 w-100 p-4">
  <form *ngIf="billing_info_form" [formGroup]="billing_info_form"  class="paymentForm">
    <div class="pt-2">
      <div class="d-flex flex-column flex-md-row">
        <mat-form-field class="w-100 mr-0 mr-md-4">
          <input [readonly]="readOnlyForm" matInput type="text" formControlName="name" placeholder="{{'common.billingData.billingName' | translate}}*"/>
          <app-control-messages [control]="billing_info_form.get('name') | cast"></app-control-messages>
        </mat-form-field>
        <div class="d-flex flex-column flex-md-row">
          <app-autocomplete-select (selectionChange)="getStates($event)" class="mr-2 w-100" type="country" [placeholder]="'country'"
                                   [control]="billing_info_form.get('address').get('country') | cast"></app-autocomplete-select>
        </div>
        <mat-form-field class="w-100">
          <input [readonly]="readOnlyForm" matInput type="text" formControlName="tax_id" placeholder="NIF# / TIN# / EORI# / RUT#*"/>
          <app-control-messages [control]="billing_info_form.get('tax_id') | cast"></app-control-messages>
        </mat-form-field>
      </div>
      <div class="d-flex flex-column flex-md-row">
        <mat-form-field class="w-100 mr-0 mr-md-4">
          <input [readonly]="readOnlyForm" matInput type="text" formControlName="contact" placeholder="{{'common.billingData.billingContact' | translate}}*"/>
          <app-control-messages [control]="billing_info_form.get('contact') | cast"></app-control-messages>
        </mat-form-field>
        <mat-form-field class="w-100">
          <input [readonly]="readOnlyForm" matInput type="text" formControlName="email" placeholder="{{'common.billingData.billingMail' | translate}}*"/>
          <app-control-messages [control]="billing_info_form.get('email') | cast"></app-control-messages>
        </mat-form-field>
      </div>
      <div *ngIf="billing_info_form.get('tax_exemption').value" class="mb-4">
        {{'common.billingData.billingTax_exemption' | translate}}: {{('common.billingData.tax-' + (billing_info_form.get('tax_exemption').value)) | translate}}
      </div>
      <div class="d-flex flex-column flex-md-row">
        <mat-form-field [ngClass]="{'no-cursor-events': readOnlyForm}" class="w-100 mr-2">
          <mat-label>{{'common.billingData.billingLang' | translate}}*</mat-label>
          <mat-select formControlName="language">
            <mat-option *ngFor="let language of languages" [value]="language.cod">
              {{language.value}}
            </mat-option>
          </mat-select>
          <app-control-messages [control]="billing_info_form.get('language') | cast"></app-control-messages>
        </mat-form-field>
        <mat-form-field class="w-100">
          <input [readonly]="readOnlyForm" matInput OnlyNumbers type="tel" formControlName="phone" placeholder="{{'common.billingData.billingPhone' | translate}}*"/>
          <app-control-messages [control]="billing_info_form.get('phone') | cast"></app-control-messages>
        </mat-form-field>
      </div>
      <div formGroupName="address" class="d-flex flex-column">
        <div class="d-flex flex-column flex-md-row">
          <mat-form-field class="w-100 mr-2">
            <input [readonly]="readOnlyForm" matInput type="text" formControlName="line1" placeholder="{{'common.billingData.address1' | translate}}*"/>
            <app-control-messages [control]="billing_info_form.get('address').get('line1') | cast"></app-control-messages>
          </mat-form-field>
          <mat-form-field class="w-100">
            <input [readonly]="readOnlyForm" matInput type="text" formControlName="line2" placeholder="{{'common.billingData.address2' | translate}}"/>
            <app-control-messages [control]="billing_info_form.get('address').get('line2') | cast"></app-control-messages>
          </mat-form-field>
        </div>
        <div class="d-flex flex-column flex-lg-row w-100">
          <div class="d-flex flex-column flex-md-row w-100">
            <mat-form-field [ngClass]="{'no-cursor-events': readOnlyForm}" class="mr-2">
              <mat-label>{{'common.text.state' | translate}}*</mat-label>
              <mat-select  formControlName="state">
                <mat-option *ngFor="let state of states" [value]="state.id">
                  {{state.name}}
                </mat-option>
              </mat-select>
              <app-control-messages [control]="billing_info_form.get('address').get('state') | cast"></app-control-messages>
            </mat-form-field>

            <mat-form-field class="mr-2">
              <input [readonly]="readOnlyForm" matInput type="text" formControlName="city" placeholder="{{'common.billingData.city' | translate}}*"/>
              <app-control-messages [control]="billing_info_form.get('address').get('city') | cast"></app-control-messages>
            </mat-form-field>

            <mat-form-field class="w-100">
              <input [readonly]="readOnlyForm" matInput type="text" formControlName="postal_code" placeholder="{{'common.billingData.cp' | translate}}*"/>
              <app-control-messages [control]="billing_info_form.get('address').get('postal_code') | cast"></app-control-messages>
            </mat-form-field>
          </div>
        </div>
      </div>
      <app-control-messages [control]="billing_info_form"></app-control-messages>
    </div>
  </form>
</div>
