<div class="d-flex w-100">
  <form *ngIf="simpleSearchForm" [formGroup]="simpleSearchForm" (ngSubmit)="applyFilter(simpleSearchForm.get('search').value)" class="d-flex align-items-center w-100">
    <div class="formatted-outline-input d-flex justify-content-end mr-0 mr-md-2 w-100">
      <mat-form-field appearance="outline" class="w-100">
        <input (keyup)="typingSearch ? applyFilter(simpleSearchForm.get('search').value) : null" matInput formControlName="search" placeholder="{{placeholder | translate}}">
        <mat-icon *ngIf="!typingSearch" matSuffix class="pointer" (click)="applyFilter(simpleSearchForm.get('search').value)" >search</mat-icon>
      </mat-form-field>
    </div>
  </form>
</div>
