import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiCommonService } from '../../../../../common/services/apiCommon.service';
import { File, Version } from '../../../../../common/models/app.models';

@Component({
  selector: 'app-file-info',
  templateUrl: './file-info.component.html',
  styleUrls: ['./file-info.component.scss'],
})
export class FileInfoComponent implements OnInit {

  @Input() document: File;
  @Input() filesType: string;
  @Input() id: string;
  url: string;
  environment = environment;

  latestVersion: Version;
  actualVersion: number;

  link;

  constructor(private apiService: ApiCommonService) {
  }

  ngOnInit() {

    if (this.document.versions) {
      this.latestVersion = this.document.versions[this.document.versions.length - 1];
      this.actualVersion = this.document.versions.length - 1;
    }
  }

  changeVersion(action) {
    this.actualVersion = action ? this.actualVersion + 1 : this.actualVersion - 1;
    this.latestVersion = this.document.versions [this.actualVersion];
  }

  downloadFile(document, version) {

    const data = {
      id: this.id,
      location: this.filesType,
      index: document.index,
      versionIndex: version,
    };

    this.apiService.getFileDownloadLink(data).subscribe(res => {
      this.link = !res.link.includes('http') ? environment.url + res.link : res.link;
      window.open(this.link, '_blank');
    });
  }

}
