import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

export class CustomValidators {

  static matchPassword(control: FormGroup): ValidationErrors {
    const password = control.get('password').value;
    const repeatPassword = control.get('repeatPassword').value;

    return (password !== repeatPassword && password && repeatPassword) ? { confirmPassword: true } : null;
  }

  static requiredTermsAndConditions(control: FormGroup): ValidationErrors {
    return (!control.value) ? { requiredTermsAndConditions: true } : null;
  }

  static onlyLettersOrNumbers(control: FormControl): ValidationErrors {
    const REGEXP = /^[A-Za-z0-9]+$/;
    // si no tiene value, osea input vacío ( null ) o pasa la regex, no devuelvo error
    return REGEXP.test(control.value) ? null : { alfanumericInvalid: true };
  }

  static telephoneValidator(control: FormControl): ValidationErrors {
    const REGEXP = /^[0-9+(][0-9()#.\s\/ext-]{9,}$/;
    // si no tiene value, osea input vacío ( null ) o pasa la regex, no devuelvo error
    return !control.value || REGEXP.test(control.value) ? null : { telephone: true };
  }

  static onlyNumbers(control: FormControl): ValidationErrors {
    const REGEXP = /^[0-9]+$/;
    return !control.value || REGEXP.test(control.value) ? null : { numericInvalid: true };
  }

  static strongPassword(control: FormControl): ValidationErrors {
    const regexValidator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@#!%*?&)(!¡&_+|=`}{:";'<>,.-])[A-Za-z\d$@#!%*?&)(!¡&_+|=`}{:";'<>,.-]{6,}/;

    if (!control.value) return null; // if control is empty return no error

    const valid = regexValidator.test(control.value); // test the value of the control against the regexp supplied

    return valid ? null : { passInvalid: true }; // if true, return no error (no error), else return error
  }

  static biggerOrLowerThanSibling(controlName1: string, controlName2: string, condition: '>' | '<') {

    return (formgroup: FormGroup) => {
      const control1 = formgroup.get(controlName1);
      const control2 = formgroup.get(controlName2);
      let error;
      let errorName;
      const customError = {};

      if (condition === '>') {
        errorName = 'biggerThan_' + controlName2;
        if (Number(control1.value) > Number(control2.value)) error = true;
      } else {
        // aun no se usa en ningún lado, lo dejo picado.
        if (Number(control1.value) < Number(control2.value)) error = true;
        errorName = 'lowerThan_' + controlName1;
      }
      if (error) {
        customError[errorName] = true;
        control1.setErrors(customError);
        control1.markAsTouched();
      } else if (control1.errors?.[errorName]) this.removeFormControlError(control1, errorName);
    };
  }

  static removeFormControlError(control: AbstractControl, errorName: string) {
    if (control?.errors && control?.errors[errorName]) {
      delete control.errors[errorName];
      if (Object.keys(control.errors).length === 0) control.setErrors(null);
    }
  }

  static EORIcountryMatch(control: FormControl): ValidationErrors {
    const UECountries = ['BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'EL', 'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU', 'MT', 'NL', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE'];
    const parent = control.parent;

    if (!parent || !control.value) return null;
    if (!UECountries.includes(control.parent.get('address').get('country').value)) return null;
    return control.parent.get('address').get('country').value === control.value.slice(0, 2).toUpperCase() ? null : { eoriNotMatch: true };
  }

  static validateEmail(control: FormControl): ValidationErrors {
    const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // si no tiene value, osea input vacío ( null ) o pasa la regex, no devuelvo error
    return !control.value || EMAIL_REGEXP.test(control.value) ? null : { emailInvalid: true };
  }

  static HSValidator(group: FormGroup): ValidationErrors {
    let { weight, box, invoice_value } = group.getRawValue();
    const exist = { weight: !!weight, box: !!box, value: !!invoice_value };

    const HSList = group.get('HS') as FormArray;
    if (!HSList || !HSList.length) return null;

    // Recorremos cada linea poniendo como requerido aquellos campos para los q exista valor en su total
    for (const control of HSList.controls) {
      ['weight', 'box', 'value'].forEach(e => {
        if (exist[e]) control.get(e).setValidators([Validators.required]);
        else control.get(e).setValidators([]);
        control.get(e).updateValueAndValidity({ onlySelf: true, emitEvent: false });
      });
    }

    // Calculo el acumulado de los 3 atributos
    for (const HS of HSList.getRawValue()) {
      weight = weight - HS.weight;
      box = box - HS.box;
      invoice_value = parseFloat((invoice_value - HS.value).toFixed(2));
    }

    const errors: { HSWeight?, HSBox?, HSValue? } = {};

    if (exist.weight && weight) errors.HSWeight = true;
    if (exist.box && box) errors.HSBox = true;
    if (exist.value && invoice_value) errors.HSValue = true;
    return Object.keys(errors).length === 0 ? null : errors;
  }

}
