<h2 class="mt-1 form-header__title d-flex align-items-center">
  <mat-icon class="mr-2 ">people</mat-icon>
  {{'common.invitations.companyTeam' | translate}}
</h2>

<div *ngIf="!dataInvitedUsers?.data?.length" class="d-flex align-items-center font-s ml-2 py-3">
  <mat-icon class="mr-2 text-primary-color">info</mat-icon>
  {{'common.invitations.noInvitations' | translate}}</div>
<div [ngClass]="{'d-none': !dataInvitedUsers?.data?.length}" class="p-4 mt-4" id="profile-invited-users-table">

  <table mat-table [dataSource]="dataInvitedUsers" class="py-3 border w-100 p-4">
    <ng-container matColumnDef="email" class="d-flex">
      <th mat-header-cell *matHeaderCellDef> Email</th>
      <td mat-cell *matCellDef="let element" class="truncate-text" [ngClass]="{'disabled': !element.active}"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell"> {{'common.invitations.name' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="d-none d-md-table-cell" [ngClass]="{'disabled': !element.active}">
        {{element.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell text-center"> {{'common.invitations.invitationDate' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="d-none d-md-table-cell text-center" [ngClass]="{'disabled': !element.active}">
        {{element.createdAt | date: 'shortDate' : '+0000'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="companyOwner">
      <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell text-center">{{'common.invitations.companyOwner' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="d-none d-md-table-cell text-center" [ngClass]="{'disabled': !element.active}">
        <mat-checkbox [checked]="element.company_owner" [disabled]="true"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="registered_at">
      <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell text-center">{{'common.invitations.registerDate' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="d-none d-md-table-cell text-center" [ngClass]="{'disabled': !element.active}">
        {{element.registered_at ? (element.registered_at | date: 'shortDate' : '+0000') : '-'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="managers" *ngIf="!isBO">
      <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell text-center">{{'common.common.managers' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="d-none d-md-table-cell text-center" [ngClass]="{'disabled': !element.active}">
        <mat-checkbox [checked]="managers.includes(element._id)" (change)="saveManager(element._id, $event)"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="token" *ngIf="!isBO">
      <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell text-center">
        <div class="d-flex align-items-center justify-content-center font-s ml-2 py-3">
          <span>{{'common.invitations.apikeyTableHeader' | translate}}</span>
          <app-info-hover class="ml-2" [sentence]="'common.information.apiKey'"></app-info-hover>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="d-none d-md-table-cell text-center" [ngClass]="{'disabled': !element.active}">
        <div *ngIf="element.active">
          <button mat-button color="primary" *ngIf="user.company_owner && !element.token" (click)="requestApiKeyToken(element)">{{'common.invitations.apikeyBtn' | translate}}</button>
          <div *ngIf="element.token" class="d-flex align-items-center font-s ml-2 py-3">
            <span class="width-200 ml-5" matInput>{{element.token}}</span>
            <mat-icon class="ml-2 text-right pointer" matTooltip="{{'common.invitations.apikeyCopy' | translate}}" (click)="copyToClipboard(element)">file_copy</mat-icon>
            <mat-icon class="ml-2 text-right pointer" matTooltip="{{'common.invitations.apikeyDelete' | translate}}" (click)="deleteApiKeyToken(element)">clear</mat-icon>
          </div>
        </div>
        <div *ngIf="!element.active">-</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell text-center">{{'common.common.active' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="d-none d-md-table-cell text-center" [ngClass]="{'disabled': !element.active}">
        <mat-checkbox [checked]="element.active" (change)="disableUsers(element._id, $event)" [disabled]="element._id === user._id"></mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="invitedUsersColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: invitedUsersColumns;"></tr>
  </table>
  <mat-paginator #invitedUsersPaginator class="recordListPaginator w-100 d-flex justify-content-center justify-content-md-end" [pageSize]="10" [hidePageSize]="true" showFirstLastButtons></mat-paginator>
  <button *ngIf="!isBO" mat-flat-button color="primary" (click)="showAddMember()">
    <mat-icon class="mr-2 mb-1">add_circle</mat-icon>
    {{'common.buttons.showAddMember' | translate}}
  </button>
</div>

<div *ngIf="!isBO && showAddMemberForm">

  <h2 *ngIf="hasHeader" class="form-header__title d-flex align-items-center">
    <mat-icon class="mr-2">person_add</mat-icon>
    {{'common.invitations.invite' | translate}}
  </h2>
  <div *ngIf="(!!limitInvitationMembers && (limitInvitationMembers <= invitationsMemberUsed))" class="d-flex align-items-center font-s ml-2 py-3">
    <mat-icon class="mr-2 text-primary-color">info</mat-icon>
    {{'common.invitations.limitInvitations' | translate}}</div>

  <div *ngIf="user?.company?.is_blocked" class="d-flex align-items-center font-s ml-2 py-3">
    <mat-icon class="mr-2 text-primary-color">info</mat-icon>
    {{'common.invitations.invitationsBlocked' | translate}}
  </div>

  <div *ngIf="(!limitInvitationMembers || (limitInvitationMembers > invitationsMemberUsed)) && !user?.company?.is_blocked " class="py-3 w-100 p-4 profile-invite-user">
    <form *ngIf="(user?.company_owner && newCompanyUserForm) " [formGroup]="newCompanyUserForm">
      <div class="d-flex flex-column flex-md-row">
        <mat-form-field class="w-100 mr-3" id="profile-invitations-invited-username">
          <input matInput placeholder="Email" type="email" formControlName="email" tabindex="1">
          <app-control-messages [control]="newCompanyUserForm.get('email')"></app-control-messages>
        </mat-form-field>
        <div class="mt-2 d-flex align-items-center">
          <app-info-hover [sentence]="'common.information.companyOwnerInfo'"></app-info-hover>
          <p class="no-wrap mb-0 mr-3">{{'common.invitations.check' | translate}}: </p>
          <mat-slide-toggle formControlName="company_owner"></mat-slide-toggle>
        </div>
      </div>

      <div class="d-flex flex-column flex-md-row">
        <mat-form-field class="mr-2 w-100">
          <input matInput placeholder="{{'common.invitations.name' | translate}}" type="text" formControlName="name" tabindex="2">
          <app-control-messages [control]="newCompanyUserForm.get('name')"></app-control-messages>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>{{'common.invitations.language' | translate}}</mat-label>
          <mat-select formControlName="language">
            <mat-option *ngFor="let language of langs" [value]="language.cod">
              {{language.value}}
            </mat-option>
          </mat-select>
          <app-control-messages [control]="newCompanyUserForm.get('language')"></app-control-messages>
        </mat-form-field>
      </div>
      <button id="profile-invite-user" [disabled]="!permissions?.includes('company.members:create')" mat-flat-button (click)="addUserCompany(); $event.preventDefault()" class="mt-4" color="primary">{{'common.buttons.inviteUser' | translate}}</button>
    </form>
  </div>
</div>

<div class="mt-2 px-4 py-2" *ngIf="!isBO">
  <mat-checkbox [checked]="creator_is_manager" (change)="saveCreatorAsManager($event)" class="mr-2"></mat-checkbox>
  <span>{{'common.invitations.creatorAsManager' | translate}}</span>
</div>
