import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { fadeInOut } from '../../../shared/animations';

@Component({
  selector: 'app-control-messages',
  templateUrl: 'control-messages.component.html',
  styleUrls: ['control-messages.component.scss'],
  animations: [fadeInOut],
})

export class ControlMessagesComponent {
  @Input() control: AbstractControl;
  @Input() class;

  constructor() { }

  /**
   * Maps forms error messages with corresponding translation
   */
  get errorMessage(): string {
    if (this.control.touched) {
      for (const propertyName in this.control.errors) {
        if (this.control.errors.hasOwnProperty(propertyName)) {
          return propertyName;
        }
      }
    }
    return null;
  }
}
