import { NgModule } from '@angular/core';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { MatMenuModule } from '@angular/material/menu';
import { UsageComponent } from './components/usage/usage.component';
import { AutocompleteSelectComponent } from './components/common/autocomplete-select/autocomplete-select.component';
import { ControlMessagesComponent } from './components/common/control-messages/control-messages.component';
import { DatepickerComponent } from './components/common/datepicker/datepicker.component';
import { InputTextComponent } from './components/common/input-text/input-text.component';
import { UploadDocsComponent } from './components/common/upload-docs/upload-docs.component';
import { SelectFavoritesCompaniesComponent } from './components/common/select-favorites-companies/select-favorites-companies.component';
import { CustomCompiler, HttpLoaderFactory } from './i18n/common-i18n';
import { CastPipe, HighlightPipe, MaxLength, SortTranslationPipe } from './shared/customPipes';
import { MatCardModule } from '@angular/material/card';
import { FacturationDataComponent } from './components/facturation-data/facturation-data.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CompanyInvitationsComponent } from './components/company-invitations/company-invitations.component';
import { AlertBannerComponent } from './components/alert-banner/alert-banner.component';
import { NumericDirective, OnlyNumbersDirective } from './services/directives';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MatListModule } from '@angular/material/list';
import { ToastrModule } from 'ngx-toastr';
import { TextareaComponent } from './components/common/textarea/textarea.component';
import { SimpleSearcherComponent } from './components/simple-searcher/simple-searcher.component';
import { DragDropDirective } from './services/drag-drop.directive';
import { GenericModalComponent } from '../front/src/app/components/common/modals/generic-modal/generic-modal.component';
import { InfoHoverComponent } from './components/common/info-hover/info-hover.component';
import { ShipmentDataInsuranceComponent } from './components/shipment-data-insurance/shipment-data-insurance.component';
import { InfiniteScrollAutoCompleteDirective } from './shared/auto-scroll';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [
    InvoicesComponent,
    UsageComponent,
    AutocompleteSelectComponent,
    ControlMessagesComponent,
    DatepickerComponent,
    InputTextComponent,
    UploadDocsComponent,
    SelectFavoritesCompaniesComponent,
    FacturationDataComponent,
    CompanyInvitationsComponent,
    HighlightPipe,
    CastPipe,
    SortTranslationPipe,
    MaxLength,
    AlertBannerComponent,
    OnlyNumbersDirective,
    NumericDirective,
    DragDropDirective,
    NotFoundComponent,
    TextareaComponent,
    SimpleSearcherComponent,
    GenericModalComponent,
    InfoHoverComponent,
    ShipmentDataInsuranceComponent,
    InfiniteScrollAutoCompleteDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      compiler: { provide: TranslateCompiler, useClass: CustomCompiler },
    }),
    MatButtonModule,
    MatCheckboxModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatRadioModule,
    MatOptionModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatStepperModule,
    MatNativeDateModule,
    MatMenuModule,
    MatTooltipModule,
    MatIconModule,
    MatDialogModule,
    MatChipsModule,
    MatBadgeModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatCardModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    MatToolbarModule,
    MatExpansionModule,
    MatListModule,
    ToastrModule.forRoot(),
  ],
  exports: [
    BrowserModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatRadioModule,
    MatOptionModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatStepperModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatIconModule,
    MatDialogModule,
    MatChipsModule,
    MatBadgeModule,
    MatMenuModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatCardModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    InvoicesComponent,
    UsageComponent,
    AutocompleteSelectComponent,
    ControlMessagesComponent,
    DatepickerComponent,
    InputTextComponent,
    UploadDocsComponent,
    SelectFavoritesCompaniesComponent,
    HighlightPipe,
    CastPipe,
    SortTranslationPipe,
    MaxLength,
    FacturationDataComponent,
    CompanyInvitationsComponent,
    AlertBannerComponent,
    OnlyNumbersDirective,
    NumericDirective,
    MatToolbarModule,
    NotFoundComponent,
    MatListModule,
    ToastrModule,
    TextareaComponent,
    SimpleSearcherComponent,
    DragDropDirective,
    GenericModalComponent,
    InfoHoverComponent,
    ShipmentDataInsuranceComponent,
    InfiniteScrollAutoCompleteDirective,
  ],
})
export class CommonModule {}
