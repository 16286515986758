import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ROLES } from 'projects/common/models/app.models';
import { ApiCommonService } from 'projects/common/services/apiCommon.service';

@Component({
  selector: 'app-detail-edoc-modal.component',
  templateUrl: 'detail-edoc-modal.component.html',
  styleUrls: ['./edocs-services.component.scss'],
})
export class DetailEdocModalComponent implements OnInit {
  Number = Number;
  ROLES = ROLES;
  edoc: any; // TODO tipar todos los edocs dependiendo del tipo de edoc
  constructor(private apiService: ApiCommonService, public dialogRef: MatDialogRef<DetailEdocModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  ngOnInit() {
    this.apiService.getEdoc(this.data.edocId).subscribe(res => {
      this.edoc = res.edoc;
    });
  }
  close() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-edocs-services',
  templateUrl: './edocs-services.component.html',
  styleUrls: ['./edocs-services.component.scss'],
})
export class EdocsServicesComponent implements OnInit {

  displayedColumns: string[] = ['record_sequence', 'company_name', 'final_client_price', 'issuing_date', 'status', 'edoc_id', 'type'];
  transportIcons = { ECMR: 'local_shipping', EBL: 'directions_boat' };
  Number = Number;

  dataSource: MatTableDataSource<any>;
  totalEdocs = 0;
  edocsList: any[];
  pageIndex = 0;
  pageSize = 10;
  @ViewChild(MatSort) sort: MatSort;
  tableStatus = ['FAILED', 'ISSUED', 'PROCESSING'];
  searchFilter = '';

  constructor(private apiService: ApiCommonService, private dialog: MatDialog) { }

  async ngOnInit() {
    await this.getEdocs(this.pageIndex, this.pageSize, this.tableStatus);
  }

  async getEdocs(pageIndex: number, pageCount: number, statusForFilter?: string[], sortColum?: string, filter?: string) {
    const res = await this.apiService.getEdocs(pageIndex, pageCount, statusForFilter, sortColum, filter).toPromise();
    this.totalEdocs = res.total;
    this.edocsList = res.edocs;
    this.mountEdocTable(this.edocsList);
  }

  mountEdocTable(edocsList) {
    this.dataSource = new MatTableDataSource<any>(edocsList);
    this.dataSource.sort = this.sort;
  }

  async applyFilter(filterValue: string) {
    this.searchFilter = filterValue;
    await this.getEdocs(this.pageIndex, this.pageSize, this.tableStatus, null, this.searchFilter);
  }

  async getNext(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    await this.getEdocs(this.pageIndex, this.pageSize, this.tableStatus, null, this.searchFilter);
  }

  openOfferDetailModal(element) {
    this.dialog.open(DetailEdocModalComponent, { panelClass: 'modal-generic-with-grey-header', data: { edocId: element._id, transportIcons: this.transportIcons } });
  }

  async sortData(event) {
    let sortColum: string;
    if (event.direction === 'asc') {
      sortColum = '+' + event.active;
    } else if (event.direction === 'desc') {
      sortColum = '-' + event.active;
    } else {
      sortColum = '';
    }
    await this.getEdocs(this.pageIndex, this.pageSize, this.tableStatus, sortColum, this.searchFilter);
  }
}
