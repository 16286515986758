import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CacheService } from '../services/cache.service';

const helper = new JwtHelperService();

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private cacheService: CacheService, private router: Router) {
  }

  async canActivate() {

    const token = this.cacheService.getToken();

    if (!token) {
      this.router.navigate(['/login']);
      return false;
    }
    if (helper.isTokenExpired(token)) {
      this.cacheService.clear();
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
