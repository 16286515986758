import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiCommonService } from '../../../common/services/apiCommon.service';
import { Company, FavoriteCompanies, PermsWrapper, User } from '../../../common/models/app.models';
import { SubscriptionWrapper } from '../../../common/models/billing.models';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CacheService {
  private lang = null;
  private company: Company | Promise<Company>;
  private subscriptionWrapper: SubscriptionWrapper;
  private overdueNotifications = {
    days_until_overdue: undefined, days_since_invoice: undefined,
  };

  private _$favoriteCompanies: ReplaySubject<FavoriteCompanies>;
  private _$permissions: ReplaySubject<PermsWrapper>;
  private _$user: ReplaySubject<User>;

  private recordDetailInstance;
  private _queryParams: string;

  constructor(private apiService: ApiCommonService, private translate: TranslateService) { }

  private static toObservable<T>(rs: ReplaySubject<T>): Observable<T> {
    return rs.asObservable().pipe(take(1));
  }

  setUser(user: User) {
    if (!this._$user) this._$user = new ReplaySubject<User>(1);
    this._$user.next(user);
    this.company = user.company;
  }

  getUser(): Observable<User> {
    if (this._$user) return CacheService.toObservable(this._$user);
    if (this.getToken()) {
      this._$user = new ReplaySubject<User>(1);
      this.apiService.getUser().subscribe(res => this._$user.next(res));
    } else {
      const tempObs = new ReplaySubject<User>(1);
      tempObs.next(null);
      return tempObs;
    }
    return CacheService.toObservable(this._$user);
  }

  addUrlQueryParams(queryParams: any) {
    this._queryParams = queryParams;
  }

  getUrlQueryParams(): any {
    return this._queryParams;
  }

  getPermissionsWrapper(): Observable<PermsWrapper> {
    if (this._$permissions) return CacheService.toObservable(this._$permissions);
    this._$permissions = new ReplaySubject<PermsWrapper>(1);
    this.apiService.getAppPermissions().subscribe(res => this._$permissions.next(res));
    return CacheService.toObservable(this._$permissions);
  }

  resetPermissionsWrapper() {
    this._$permissions = undefined;
  }

  async getPermissions() {
    const perms = await this.getPermissionsWrapper().toPromise();
    return perms.perms;
  }

  getFavoriteCompanies(): Observable<FavoriteCompanies> {
    if (this._$favoriteCompanies) return CacheService.toObservable(this._$favoriteCompanies);
    this._$favoriteCompanies = new ReplaySubject<FavoriteCompanies>(1);
    this.apiService.getFavoriteCompanies().subscribe(res => this._$favoriteCompanies.next(res));
    return CacheService.toObservable(this._$favoriteCompanies);
  }

  setFavoriteCompanies(favoriteCompanies: FavoriteCompanies) {
    this._$favoriteCompanies.next(favoriteCompanies);
  }

  async getCompany(): Promise<Company> {
    if (!this.company) {
      this.company = await this.apiService.getCompany().toPromise();
    }
    return this.company;
  }

  setCompany(company) {
    this.company = company;
  }

  setOverdueNotifications(days_since_invoice, days_until_overdue) {
    this.overdueNotifications.days_since_invoice = days_since_invoice;
    this.overdueNotifications.days_until_overdue = days_until_overdue;
  }

  getOverdueNotifications() {
    return this.overdueNotifications;
  }

  async getSubscriptionWrapper(): Promise<SubscriptionWrapper> {
    // si paso parámetro = 'current', devuelvo current sino next. Si no paso parámetro, devuelve SubscriptionWrapper
    if (!this.subscriptionWrapper) {
      this.subscriptionWrapper = await this.apiService.getSubscription().toPromise();
    }
    return this.subscriptionWrapper;
  }

  async getSubscription(param) {
    if (!this.subscriptionWrapper) {
      this.subscriptionWrapper = await this.getSubscriptionWrapper();
    }
    return param === 'current' ? this.subscriptionWrapper.current_subscription : this.subscriptionWrapper.next_subscription;
  }

  getLang(): string {
    if (!this.lang) this.lang = localStorage.getItem('lang') || 'en';
    return this.lang;
  }

  getRealLang(): string {
    return this.lang === 'cl' ? 'es' : this.lang;
  }

  setLang(lang) {
    localStorage.setItem('lang', lang);
    this.translate.use(lang);
    this.lang = lang;
  }

  setToken(token: string, saveOnSessionStorage: boolean = false) {
    saveOnSessionStorage ? window.sessionStorage.setItem('token', token) : window.localStorage.setItem('token', token);
  }

  getToken(): string {
    return sessionStorage.getItem('token') || localStorage.getItem('token');
  }

  setValue(key: string, value: any) {
    this[key] = value;
  }

  setRecordDetailInstance(value) {
    this.recordDetailInstance = value;
  }

  getRecordDetailInstance() {
    return this.recordDetailInstance;
  }

  clearStorage() {
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    this.company = undefined;
    this.overdueNotifications = {
      days_until_overdue: undefined, days_since_invoice: undefined,
    };
    this.subscriptionWrapper = undefined;
    this._$favoriteCompanies = undefined;
    this.lang = 'en';
    this._$permissions = undefined;
    this._$user = undefined;
  }

}
