<div>
  <app-simple-searcher [placeholder]="'Filter by record ID / eDoc ID / customer name / provider name'" (search)="applyFilter($event)"></app-simple-searcher>
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="edocs-table py-3 border w-100 p-4 mat-elevation-z8">

    <ng-container matColumnDef="record_sequence">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="record_sequence" class="text-center">{{'column.record' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="text-center pl-0 pr-2">
        <div class="d-flex justify-content-center">
          <!-- <mat-icon class="icon-sm mr-2 pointer" [ngClass]="{'invisible': element.read}">check_circle</mat-icon> -->
          <b class="font-s">{{element.record.sequence}}</b>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="company_name">
      <th mat-header-cell mat-sort-header="company_name" *matHeaderCellDef>Customer / <br/>Provider</th>
      <td mat-cell *matCellDef="let element">
        <div class="font-s font-weight-bold">{{element.client_company?.name}}</div>
        <div class="font-s">{{element.provider ? element.provider.display_name : '---------'}}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="final_client_price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="final_client_price">Price</th>
      <td mat-cell *matCellDef="let element">
        <div class="font-s">{{Number(element.final_client_price?.amount) | number : '1.2-2'}} {{element.final_client_price?.currency}}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="status" class="pr-0">
        <div class="d-flex align-items-center">State
          <app-info-hover [sentence]="'ISSUED: the eDoc has been issued. DELIVERED: the eDoc has been delivered to the customer. FAILED: something went worng. We will look at it.'" [size]="'m'"></app-info-hover>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="pr-0" [ngClass]="[element.status]">
        <div>{{element.exported_data?.provider_status === 'DELIVERED' ? 'DELIVERED' : element.status}}</div>
        <div class="font-xs text-normal">{{element.updated_at | date: 'mediumDate'}}</div>

      </td>
    </ng-container>

    <ng-container matColumnDef="issuing_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="issuing_date">Issuing Date</th>
      <td mat-cell *matCellDef="let element" class="font-s">
        <div class="font-xs">{{element?.issued_at | date: 'mediumDate'}}</div>
        <div class="font-xs">{{element?.issued_at | date: 'shortTime'}}</div>
      </td>

    </ng-container>

    <ng-container matColumnDef="edoc_id">
      <th mat-header-cell *matHeaderCellDef>eDoc ID</th>
      <td mat-cell *matCellDef="let element">
        <div class="font-s font-weight-bold">{{element._id | slice:element._id.length - 5 : element._id.length}}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type of eDoc</th>
      <td mat-cell *matCellDef="let element">
        <div class="d-flex align-items-center text-grey font-s">
          <mat-icon class="mr-2">{{transportIcons[element.type]}}</mat-icon>
          <div> {{element.type}}</div>
        </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="p-3 pointer" *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'bg-noRead': !row.read}" (click)="openOfferDetailModal(row)"></tr>
  </table>

  <mat-paginator #paginator (page)="getNext($event)" [pageSizeOptions]="[10, 50, 100]" [length]="totalEdocs" [pageSize]="pageSize" [hidePageSize]="false" [ngClass]="{'d-none': !dataSource?.data.length}" showFirstLastButtons class="my-4 recordListPaginator"></mat-paginator>

</div>
