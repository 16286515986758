export const environment = {
  name: 'sandbox',
  bo: true,
  production: true,
  url: '',
  urlUploads: 'api/uploads',
  maxUploadFiles: 5,
  segmentKey: 'JwWe7GnPCcG56NADphOSt3k6ldCs3lMZ',
  captchaKey: '6LeYw3YgAAAAAKmQtrztrtRQfSXaiFyx0ADTKBHd', // key de recaptcha V3
  sentry: 'https://27490afd007b4b7bbaab0c34e7538c94@o943650.ingest.sentry.io/5899136',
};
