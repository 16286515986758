<div *ngIf="edoc && data">
  <div mat-dialog-title>
    <div class="header w-100 d-flex align-items-center justify-content-between">
      <div class=" d-flex w-100 justify-content-between">
        <div class="d-flex">
          <mat-icon class="mr-2">{{data.transportIcons[edoc.type]}}</mat-icon>
          <div class="mr-2 font-weight-bold">{{edoc.type}}</div>
          <div class="text-grey">#{{edoc._id| slice:edoc._id.length - 5 : edoc._id.length }}</div>
        </div>
        <div class="font-weight-bold">Record: {{edoc.record.sequence}}</div>
      </div>
    </div>
  </div>
  <div mat-dialog-content class="modal-with-primary-header px-4 pb-4">
    <div>
      <div class="d-flex justify-content-between">
        <div class="text-grey font-ml">Customer</div>
        <div class="pt-1">
          <div class="status-chip mb-2" [ngClass]="[edoc.status]">{{edoc.exported_data?.provider_status === 'DELIVERED' ? 'DELIVERED' : edoc.status}}</div>
          <div class="font-xs text-grey text-right mt-1">{{edoc.created_at | date: 'short'}}</div>
        </div>
      </div>
      <div>
        <div class="text-primary-color font-weight-bold font-ml mb-1">{{edoc.client_company.name}}</div>
      </div>
      <div class="d-flex justify-content-between mt-2 font-m">
        <div class="d-flex align-items-center">
          <mat-icon class="mr-1">person</mat-icon>
          <span class="font-weight-bold mr-1">Issued by:</span><span class="text-grey">{{edoc.issued_by?.email}}</span>
        </div>
        <div class="d-flex align-items-center">
          <mat-icon class="mr-1">calendar_today</mat-icon>
          <span class="font-weight-bold mr-1">Day and time:</span><span class="text-grey">{{edoc.created_at | date: 'short'}}</span>
        </div>
      </div>

      <hr class="hr mt-2">

      <div class="text-grey font-ml">Provider</div>
      <div>
        <div class="text-primary-color font-weight-bold font-ml mb-1">{{edoc.provider.display_name}}</div>
      </div>

      <div class="d-flex justify-content-between align-items-center mt-2 font-m">
        <div class="d-flex align-items-center">
          <mat-icon class="mr-1"> mail_outline</mat-icon>
          <span class="font-weight-bold mr-1">Mail address:</span><span class="text-grey">{{edoc.provider.settings.provider_contact.contact_email}}</span>
        </div>
        <div class="d-flex align-items-center">
          <mat-icon class="mr-1"> phone</mat-icon>
          <span class="font-weight-bold mr-1">Phone number:</span><span class="text-grey">{{edoc.provider.settings.provider_contact.contact_phone}}</span>
        </div>
      </div>

      <hr class="hr mt-2">

      <div class="text-grey font-ml">Price</div>
      <div class="d-flex justify-content-between align-items-center mt-2 font-m">
        <div>
          <span class="font-weight-bold">Original price:</span>
          <span class="text-grey ml-2">{{(Number(edoc.discount_price.amount) + Number(edoc.final_client_price.amount)) | number : '1.2-2'}} {{edoc.discount_price.currency}}</span>
        </div>
        <div>
          <span class="font-weight-bold">Applied discount:</span>
          <span class="text-grey ml-2">{{edoc.discount_percent}}%</span>
        </div>
        <div>
          <span class="font-weight-bold">FINAL PRICE: </span>
          <span class="text-primary-color font-ml ml-2">{{edoc.final_client_price.amount | number : '1.2-2'}} {{edoc.final_client_price.currency}}</span>
        </div>
      </div>
    </div>

  </div>
</div>
