import { ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { environment } from '../common.environment';

export class MyErrorHandler implements ErrorHandler {

  constructor() {
    if (environment.sentry) {
      Sentry.init({
        dsn: environment.sentry,
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.routingInstrumentation,
            traceXHR: false,
            traceFetch: false,
          }),
        ],
        beforeSend(event, hint) {
          return (hint && hint.originalException instanceof HttpErrorResponse) ? null : event;
        },
        tracesSampleRate: 1.0,
        environment: environment.name,
      });
    }
  }

  handleError(error) {
    if (!(error instanceof HttpErrorResponse)) Sentry.captureException(new Error(error));
    // tslint:disable-next-line:no-console
    console.error(error);
  }
}
