<div class="d-flex align-items-center">
  <mat-icon [matBadgeHidden]="notificationsUnread === 0" matBadge="{{notificationsUnread}}" matBadgeColor="warn" [matMenuTriggerFor]="notificationsList" class="pointer">notifications</mat-icon>
  <mat-menu #notificationsList="matMenu" xPosition="before">
    <a class="mark-all-as-read link-light-grey" title="Marcar todas como leídas" (click)="markAllAsRead();" *ngIf="notificationsUnread > 0">Mark all as read
      <mat-icon>close</mat-icon>
    </a>
    <ul class="notifications-list">
      <li class="notification" *ngIf="notifications.length === 0">
        <p class="text-center">No notificatons</p>
      </li>
      <li *ngFor="let notification of notifications" (click)="markAsReaded(notification, true); redirectTo(notification.url);" [ngClass]="{'unread' : !notification.read, 'pointer': notification.url, 'default': !notification.url}" class="notification d-flex align-items-center">
        <span><img class="icon-notification" [src]="'assets/images/icons/'+notification.action +'.svg'" [alt]="notification.action+ ' icon'"></span>
        <div class="notification-body d-flex flex-column">
          <div class="font-weight-bold d-flex justify-content-between">
            <span>{{notification.params.sequence }}</span>
            <span class="notification-date">{{notification.createdAt | date: 'short'}}</span>
          </div>
          <span [innerHTML]="'notifications.' + notification.action | translate: { edocType: notification.params.edoc_type, edocId: notification.params.edoc_id | slice:notification.params.edoc_id?.length - 5 : notification.params.edoc_id?.length }"></span>
          <span *ngIf="notification.params.type" class="font-weight-bold">{{('common.resources.document.' + notification.params.type | translate)}}</span>
        </div>
      </li>
    </ul>
    <a *ngIf="notifications.length > 0" class="see-all-notifications link-light-grey" title="See all" (click)="openAllNotificationsModal();">View all <span class="gray" *ngIf="notificationsUnread">({{notificationsUnread}} notifications unread)</span></a>
  </mat-menu>
</div>
