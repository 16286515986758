import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import countries from './countries.json';
import currencies from './currencies.json';
import incoterms from './incoterms.json';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ApiCommonService } from '../../../services/apiCommon.service';

@Component({
  selector: 'app-autocomplete-select',
  templateUrl: './autocomplete-select.component.html',
  styleUrls: ['./autocomplete-select.component.scss'],
})
export class AutocompleteSelectComponent implements OnInit {
  _location: string;
  filteredOptions: Observable<Option[]>;
  options: Option[] = []; // array de objetos con 2 claves, nombre del pais (name) y su clave ISO (code)
  @Input() placeholder: string;
  @Input() type: string;
  _control: FormControl;
  @Input() set control(control: FormControl) {
    this._control = control;
    this._controlWatcher();
  }
  @Input() set location(location: string) {
    if (location !== this._location) {
      this._location = location;
      this.getPorts();
    }
  }
  maxElements = 10;

  @Output() selectionChange = new EventEmitter();

  data;

  constructor(private apiService: ApiCommonService) { }

  async ngOnInit() {

    if (this.type === 'country') this.data = countries;
    else if (this.type === 'currency') this.data = currencies;
    else if (this.type === 'incoterm') this.data = incoterms;
    else if (this.type === 'port') {
      await this.getPorts();
    } else throw new Error(`Type ${this.type} is not supported`);

    this.mountOptions();
  }

  // ToDo: No es la mejor implementacion pero si la que menos afecta al resto de cosas
  getNextBatch() {
    this.maxElements = this.maxElements + 10;
    this._controlWatcher();
  }

  mountOptions() {
    this.options = [];
    Object.keys(this.data).forEach((param) => {
      this.options.push({ code: param, name: param + ' - ' + this.data[param].name, disabled: this.data[param].disabled });
    });

    this._controlWatcher();
  }

  async getPorts() {
    this.options = [];
    if (Object.keys(countries).includes(this._location.toUpperCase())) {
      this.data = await this.apiService.getLocations(this._location).toPromise();
      this.mountOptions();
    }
  }

  private _controlWatcher() {
    this.filteredOptions = this._control.valueChanges.pipe(
      startWith(this._control.value),
      map(value => this._filter(value)),
    );
  }

  displayFn() {
    // Devolvemos una funcion para que pueda tener acceso al this.data (esta funcion se ejecuta en el hijo)
    return value => (value && this.data[value]) ? value + ' - ' + this.data[value].name : undefined;
  }

  private _filter(value: string): Option[] {
    const filterValue = (value) ? value.toLowerCase() : '';
    const arr = this.options.filter(option => option.code.toLowerCase().includes(filterValue)).concat(this.options.filter(option => option.name.toLowerCase().includes(filterValue)));
    return Array.from(arr.reduce((m, t) => m.set(t.code, t), new Map()).values()).slice(0, this.maxElements); // Para quitar duplicados
  }

  verifyAutoCompleteSelection(event: MatAutocompleteSelectedEvent) {
    if (event.option.value) this._control.setValue(event.option.value);
  }

  verifySelection() {
    // Hola humano del futuro: ¿xq he dejado esta movida asi?
    // xq por desgracia si haces un click el valor que seleccionas no entra al control hasta despues de terminar de ejecutarse esta funcion
    // asi que la unica forma que he encontrado de validar tanto entradas de teclado + opciones seleccionadas del autocompletado es esta
    setTimeout(() => {
      const value = (this._control.value) ? this._control.value.toUpperCase() : null;
      // Busco todos los elementos que se corresponden con el valor del input
      const results = this.options.find(option => option.code === value);
      // Si no hay ninguno marcamos como error
      if (!results) {
        this._control.setValue(null);
        if (value) this._control.setErrors({ invalid: true });
      } else {
        this._control.setValue(value);
        this._control.setErrors(null);
        // mando al padre el valor del select para poder rellenar los select de provincia y ciudad con los valores
        this.selectionChange.emit(value);
      }
    }, 1000);
  }
}

class Option {
  code: string;
  name: string;
  disabled?: boolean;
}
