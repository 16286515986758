import { Component, Input, OnInit } from '@angular/core';
import { fadeInOut } from '../../shared/animations';

@Component({
  selector: 'app-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss'],
  animations: [fadeInOut],
})
export class AlertBannerComponent implements OnInit {

  @Input() notifications: string[];

  constructor() { }

  ngOnInit(): void {
  }

}
