<div class="container">
  <mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="changeURL({section: $event})" mat-align-tabs="center">
    <mat-tab label="Companies">
      <app-record></app-record>
    </mat-tab>

    <mat-tab label="Services">
      <ng-template matTabContent>
        <mat-tab-group [selectedIndex]="selectedSubIndex" (selectedTabChange)="changeURL({subsection: $event});  ">
          <mat-tab active label="Insurances">
            <app-insurances></app-insurances>
          </mat-tab>
          <mat-tab [disabled]="disabled" label="Financing">
            <a>Aqui ira Financing</a>
          </mat-tab>
          <mat-tab active label="eDocs">
            <app-edocs-services></app-edocs-services>
          </mat-tab>
        </mat-tab-group>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
