import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ApiCommonService } from '../../services/apiCommon.service';
import { environment } from '../../../bo/src/environments/environment';
import { INVOICE_STATE_ICONS } from '../../models/icons.models';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit {
  @Input() company;
  INVOICE_STATE_ICONS = INVOICE_STATE_ICONS;
  dataInvoices = null;
  invoiceColumns: string[] = ['date', 'invoice_number', 'amount', 'taxes', 'status', 'actions'];
  environment = environment;
  @ViewChild('invoicesPaginator') invoicesPaginator: MatPaginator;

  constructor(private apiService: ApiCommonService) { }

  ngOnInit() {
    // FixMe: magia arcana: he probado en el viewAfterinit, ngIf en la vista, timeOut montando la tabla...
    //  y esta es la unica forma en la que no sale el error ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => this.getInvoices(), 1);
  }

  getInvoices() {
    this.apiService.getCompanyInvoices(this.company._id).subscribe(res => {
      this.dataInvoices = new MatTableDataSource(res.invoices);
      this.dataInvoices.paginator = this.invoicesPaginator;
    });
  }
}
