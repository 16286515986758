import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CacheService } from 'projects/front/src/services/cache.service';
import { User, Notification } from '../../../../common/models/app.models';
import { ApiCommonService } from '../../../../common/services/apiCommon.service';
import { Helper } from '../../../../front/src/app/helper';
import { EventCommonService } from '../../../../common/services/eventCommon.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications-table-bo',
  templateUrl: './notifications-table-bo.component.html',
  styleUrls: ['./notifications-table-bo.component.scss'],
})
export class NotificationsTableBOComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<Notification>([]);
  displayedColumns: string[] = ['createdAt', 'icons', 'message', 'buttons'];
  userHasNotifications: boolean;
  notificationsUnread = 0;
  allNotifications;
  pageIndex = 0;
  pageSize = 10;
  totalNotifications;
  user: User;

  constructor(private apiService: ApiCommonService, public dialog: MatDialog, private router: Router, private notificationsModal: MatDialogRef<NotificationsTableBOComponent>, private eventService: EventCommonService, private cacheService: CacheService) { }

  async ngOnInit() {
    this.user = await this.cacheService.getUser().toPromise();
    this.getNotifications(this.pageIndex, this.pageSize);
  }

  getNotifications(start: number, count: number) {
    this.apiService.getNotifications(start, count).subscribe(res => {
      this.notificationsUnread = res.total_unread;
      this.allNotifications = res.notifications;
      this.totalNotifications = res.total;
      this.allNotifications.forEach(e => {
        e.url = Helper.buildURLFromActionBO(e.action, e.params);
      });

      this.dataSource = new MatTableDataSource<Notification>(this.allNotifications);
      this.hasNotifications(this.dataSource.filteredData);
    });
  }

  hasNotifications(notifications: Notification[]) {
    this.userHasNotifications = !!notifications.length;
  }

  markAsRead(notification: Notification, read?: boolean) {
    this.apiService.markAsReadedNotification(notification._id, read || !notification.read).subscribe(res => {
      notification.read = res.read;
      this.eventService.updateNotifications.emit();
      notification.read ? this.notificationsUnread-- : this.notificationsUnread++;
    });
  }

  async markAllAsRead() {
    await this.apiService.markAllAsRead().toPromise();
    this.allNotifications.forEach(el => el.read = true);
    this.notificationsUnread = 0;
    this.eventService.updateNotifications.emit();
  }

  closeModal() {
    this.notificationsModal.close();
  }

  getNext(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getNotifications(this.pageIndex, this.pageSize);
  }

  async redirectTo(url) {
    const queryObject = Helper.parseQuery(url);
    await this.router.navigate(['/'], { queryParams: queryObject });
  }

}
