import { EventEmitter, Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Record } from '../models/app.models';
import { InsuranceOffer } from '../models/insurance.models';

@Injectable({
  providedIn: 'root',
})
export class EventCommonService {

  public login: EventEmitter<boolean> = new EventEmitter<boolean>();
  public loading: EventEmitter<any> = new EventEmitter();
  // Emmiter que se lanza para que se guarde el expediente
  public editRecord: EventEmitter<{ callback?: (Record) => void, skipValidators?: boolean, recordWrapper?: {record: Record, file?: File} }> = new EventEmitter();
  // Emmiter que se lanza cuando ya se ha guardado el expediente
  public updatedRecord: EventEmitter<void> = new EventEmitter();
  public updateNotifications: EventEmitter<any> = new EventEmitter();
  public updateCalculator: EventEmitter<any> = new EventEmitter<any>();
  public updateInsuranceOffer: EventEmitter<InsuranceOffer> = new EventEmitter();
  private requestCont = 0;

  constructor() { }

  loadingOn(obs, message = '', isFullScreen = false) {
    this.requestCont++;
    this.loading.next({ message, isFullScreen }); // emito un evento para que todos los componentes sepan que deben deshabilitarse
    return obs.pipe(finalize(() => {
      this.loading.next(--this.requestCont !== 0);
    }));
  }
}
