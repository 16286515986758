import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Company, EDOCS, User } from 'projects/common/models/app.models';
import { ApiCommonService } from 'projects/common/services/apiCommon.service';
import { CustomValidators } from '../../../../../common/shared/custom-validators';

@Component({
  selector: 'app-company-services',
  templateUrl: './company-services.component.html',
  styleUrls: ['./company-services.component.scss'],
})
export class CompanyServicesComponent implements OnInit {
  Object = Object;
  user: User;
  providers: any[];
  provider: any;
  providerForm: FormGroup;
  params: any;
  company: Company;
  enabledCheck = false;
  enabledCompanies = false;
  service: string;
  insuranceService: any;
  @Input() disabled = true;
  en: boolean;
  insuranceButton = false;
  edocService: any;
  edocButton = false;
  edocProvider: any;
  edocForm: FormGroup;

  edocsTypes = Object.values(EDOCS);

  servicesToOffer: any = {};

  implementations = [];

  constructor(private apiService: ApiCommonService, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute) { }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.params = params.id;
    });
    // magia arcana: para evitar Afterviewcheckederror de loading del service
    setTimeout(() => {
      this.apiService.getCompanyDetail(this.params).subscribe(res => this.company = res);
    }, 0);
    await this.getProvider();

    this.createproviderForm();
    this.createEdocForm();

    this.calculateServicesToOffer();
  }

  calculateServicesToOffer() {
    this.apiService.getImplementations().subscribe(res => {
      this.implementations = res.implementations;
      this.implementations.forEach(impl => this.servicesToOffer[impl.id] = impl.types);
    });
  }

  async getProvider() {
    const res = await this.apiService.getProvider(this.params).toPromise();
    this.providers = res.providers;

    // FIXME, hacer este filter, o petición en el click de los botones selectores de servicio
    this.provider = this.providers.find(elm => elm.service === 'INSURANCE');
    this.edocProvider = this.providers.find(elm => elm.service === 'EDOCS');
  }

  addAndEditInsuranceProvider() {
    this.providerForm.markAllAsTouched();
    const data = this.providerForm.getRawValue();
    if (!data.settings.terms_and_conditions) return this.apiService.logError('Legal files are required');
    if (data.settings.rates.find(e => Number(e.provider_price_percent) > Number(e.client_price_percent))) return this.apiService.logError('The total percentage must be higher than the insurer percentage');
    if (this.providerForm.invalid) return this.apiService.logError('Some fields have errors or are required');

    if (Number(data.settings?.minimum_premium_amount) < 0) return this.apiService.logError('Minimum price it can not be negative');

    if (data.settings.rates.find(e => e.client_price_percent <= 0 || e.provider_price_percent <= 0)) return this.apiService.logError('The total percentage and the insurer percentage must be greater than 0');

    data.display_name = this.company.name;
    data.company = this.params;
    data.service = 'INSURANCE';

    const obs = this.provider?._id ? this.apiService.editProvider(this.provider._id, data) : this.apiService.addProvider(data);
    obs.subscribe(res => {
      this.provider = res.provider;
      this.apiService.log('Provider updated successfully');
    });
  }

  addAndEditEdocsProvider() {
    this.edocForm.markAllAsTouched();
    const data = this.edocForm.getRawValue();
    if (!data.settings.terms_and_conditions) return this.apiService.logError('Legal files are required');
    if (this.edocForm.invalid) return this.apiService.logError('Some fields have errors or are required');

    data.display_name = this.company.name;
    data.company = this.params;
    data.service = 'EDOCS';

    const obs = this.edocProvider?._id ? this.apiService.editProvider(this.edocProvider._id, data) : this.apiService.addProvider(data);
    obs.subscribe(res => {
      this.edocProvider = res.provider;
      this.apiService.log('Provider updated successfully');
    });
  }

  createproviderForm() {
    this.providerForm = this.formBuilder.group({
      default_integration: this.formBuilder.group({
        enabled: [this.provider?.default_integration?.enabled !== false],
        settings: this.formBuilder.group({
          discount: [0],
        }),
      }),
      settings: this.formBuilder.group({
        brokerage_contact: this.contactForm(this.provider?.settings.brokerage_contact),
        insurance_contact: this.contactForm(this.provider?.settings.insurance_contact),
        rates: this.provider ? this.formBuilder.array(this.provider?.settings.rates.map(e => this.ratesForm(e))) : this.formBuilder.array([this.ratesForm({})]),
        conditions_text: this.formBuilder.group({
          es: [this.provider?.settings?.conditions_text?.es, [Validators.required]],
          en: [this.provider?.settings?.conditions_text?.en, [Validators.required]],
        }),
        terms_and_conditions: [this.provider?.settings.terms_and_conditions, [Validators.required]],
        minimum_price_amount: [this.provider?.settings?.minimum_price?.amount, []],
        allows_discount: [this.provider?.settings?.allows_discount, []],
      }),
    });

  }

  ratesForm(rates) {
    const group = this.formBuilder.group({
      title: [rates.title, [Validators.required]],
      description: [rates.description, [Validators.required]],
      margin_percent: [rates.margin_percent, [Validators.required]],
      forwarder: [rates.forwarder ? rates.forwarder : false, [Validators.required]],
      client_price_percent: [rates.client_price_percent, [Validators.required]],
      provider_price_percent: [rates.provider_price_percent, [Validators.required]],
    }, { validators: [CustomValidators.biggerOrLowerThanSibling('client_price_percent', 'provider_price_percent', '<')] });
    return group;
  }

  contactForm(contact) {
    return this.formBuilder.group({
      company_name: [contact?.company_name, [Validators.required, Validators.minLength(3)]],
      contact_name: [contact?.contact_name, [Validators.required, Validators.minLength(3)]],
      contact_email: [contact?.contact_email, [Validators.required, Validators.email, Validators.maxLength(80)]],
      contact_phone: [contact?.contact_phone, [Validators.required, Validators.minLength(4)]],
    });
  }

  addRate() {
    const rates = this.providerForm.get('settings.rates') as FormArray;
    rates.push(this.ratesForm({}));
  }

  getControls(providerForm, path) {
    return providerForm.get(path).controls;
  }

  deleteRate(i) {
    const rates = this.providerForm.get('settings.rates') as FormArray;
    rates.removeAt(i);
  }

  activatedCompanies(boolean) {
    this.enabledCompanies = boolean;
    const data = { enabled: this.enabledCompanies };
    const obs = this.provider?._id ? this.apiService.updateIntegrations(this.provider._id, data) : this.apiService.updateIntegrations(this.edocProvider._id, data);
    obs.subscribe(res => this.apiService.log('State of companies changed successfully'));
  }

  createEdocForm() {
    this.edocForm = this.formBuilder.group({
      settings: this.formBuilder.group({
        provider_contact: this.contactForm(this.edocProvider?.settings.provider_contact),
        rates: this.edocProvider ? this.formBuilder.array(this.edocProvider?.settings.rates.map(e => this.ratesEdocForm(e))) : this.formBuilder.array([this.ratesEdocForm({})]),
        conditions_text: this.formBuilder.group({
          es: [this.edocProvider?.settings?.conditions_text?.es, [Validators.required]],
          en: [this.edocProvider?.settings?.conditions_text?.en, [Validators.required]],
        }),
        implementation: [this.edocProvider?.settings?.implementation, [Validators.required]],
        allows_discount: [this.edocProvider?.settings?.allows_discount, []],
        edoc_data: [{}],
        terms_and_conditions: [this.edocProvider?.settings.terms_and_conditions, [Validators.required]],
      }),
      default_integration: this.formBuilder.group({
        enabled: [this.edocProvider?.default_integration?.enabled],
        settings: this.formBuilder.group({
          discount: this.formBuilder.group({
            EBL: ['0'],
            ECMR: ['0'],
            // TODO recorrer los tipos de edocs y meterlos de forma dinámica
          }),
        }),
      }),
    });
  }

  ratesEdocForm(rates) { // se está enviando currency porque back lo pide aunque sea siempre EUR
    const group = this.formBuilder.group({
      document_type: [rates.document_type, [Validators.required]],
      provider_price: this.formBuilder.group({
        amount: [rates.provider_price?.amount, [Validators.required]],
        currency: ['EUR'],
      }),
      margin: this.formBuilder.group({
        amount: [rates.margin?.amount, [Validators.required]],
        currency: ['EUR'],
      }),
    });
    return group;
  }

  getEdocControls(edocForm, path) {
    return edocForm.get(path).controls;
  }

  addEdocRate() {
    this.calculateServicesToOffer();
    const rates = this.edocForm.get('settings.rates') as FormArray;
    rates.push(this.ratesEdocForm({}));
  }

  deleteEdocRate(i) {
    const rates = this.edocForm.get('settings.rates') as FormArray;
    rates.removeAt(i);
    this.calculateServicesToOffer();
  }

  edocTotal(rate) {
    return parseFloat(rate.controls.provider_price.value.amount) + parseFloat(rate.controls.margin.value.amount);
  }

}
