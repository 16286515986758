<h2 class="mt-5 form-header__title d-flex align-items-center">
  <mat-icon class="mr-2 ">list_alt</mat-icon>
  {{'common.invoices.invoice' | translate}}
</h2>
<div *ngIf="!dataInvoices?.data.length" class="d-flex align-items-center font-s ml-2 py-3">
  <mat-icon class="mr-2 text-primary-color">info</mat-icon>
  {{'common.invoices.noInvoices' | translate}}
</div>
<div *ngIf="!dataInvoices?.data " class=" d-flex flex-column align-items-center justify-content-center">
  <mat-spinner diameter="50"></mat-spinner>
  <div class="font-weight-bold mt-2">{{'common.loading.gettingInvoices' | translate}}</div>
</div>

<div [ngClass]="{'d-none': !dataInvoices?.data.length}" class="mt-4 overflow-auto p-4">
  <table mat-table [dataSource]="dataInvoices" class="py-3 border w-100 p-4">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef class="word-break"> {{'common.invoices.date' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="word-break">
        <span class="d-none d-lg-table-cell">{{element.invoiced_at | date: 'dd/MM/yy'}}</span>
        <span class="d-table-cell d-lg-none">{{element.invoiced_at | date: 'MM/yy'}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="invoice_number">
      <th mat-header-cell *matHeaderCellDef class="word-break d-none d-md-table-cell"> {{'common.invoices.number' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="word-break d-none d-md-table-cell">{{element.invoice_number}} </td>
    </ng-container>

    <ng-container matColumnDef="taxes">
      <th mat-header-cell *matHeaderCellDef class="word-break"> {{'common.invoices.amount' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="word-break">{{element.total?.amount}} {{element.total?.currency}} </td>
    </ng-container>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef class="word-break d-none d-md-table-cell"> {{'common.invoices.taxes' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="word-break d-none d-md-table-cell">{{element.tax !== null ? ('common.invoices.taxes' | translate) + '(' + element.tax?.amount + ' ' + element.tax?.currency + ')' : ('common.invoices.exempt' | translate) }} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="word-break"> {{'common.invoices.status' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="word-break">
        <span class="d-flex d-lg-none" [class]="element.status"><mat-icon>{{INVOICE_STATE_ICONS[element.status]}}</mat-icon></span>
        <span class="d-none d-lg-flex">{{('common.invoices.' + element.status) | translate}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <!--      {{'common.invoices.invoice' | translate}}-->
      <th mat-header-cell *matHeaderCellDef class="word-break"></th>
      <td mat-cell *matCellDef="let element" class="word-break">

        <div class="d-none d-lg-flex justify-content-end">
          <a [href]="element.stripe_pdf_url" matTooltip="{{'common.invoices.downloadInvoice' | translate}}" class="profile-download-invoice" target="_blank">
            <mat-icon class="mr-2 text-primary-color">assignment_returned</mat-icon>
          </a>
          <a *ngIf="element.status !== 'PAID'" matTooltip="{{'common.invoices.payStripe' | translate}}" [href]="element.stripe_page_url" class="profile-view-invoice-stripe" target="_blank">
            <mat-icon class="mr-2 text-primary-color">payment</mat-icon>
          </a>
        </div>


        <!--actions menu mobile-->

        <div class="d-flex d-lg-none" [ngClass]="{'d-none': element.is_blocked, 'd-flex justify-content-end': !element.is_blocked}">
          <mat-icon [matMenuTriggerFor]="menu1">more_vert</mat-icon>
        </div>
        <mat-menu yPosition="below" #menu1="matMenu">
          <a [href]="element.stripe_pdf_url" target="_blank" class="reset-link">
            <button mat-menu-item>
              <mat-icon>assignment_returned</mat-icon>
              <span>{{'common.invoices.downloadInvoice' | translate}}</span>
            </button>
          </a>
          <a [href]="element.stripe_page_url" target="_blank" class="reset-link">
            <button mat-menu-item>
              <mat-icon>payment</mat-icon>
              <span>{{'common.invoices.payStripe' | translate}}</span>
            </button>
          </a>
        </mat-menu>

        <!--actions menu mobile-->

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="invoiceColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: invoiceColumns;"></tr>

  </table>
  <mat-paginator #invoicesPaginator class="recordListPaginator w-100 d-flex justify-content-center justify-content-md-end" [pageSize]="10" [hidePageSize]="true" showFirstLastButtons></mat-paginator>
</div>
