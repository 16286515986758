import { Component, OnInit } from '@angular/core';
import { CacheService } from '../../services/cache.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiCommonService } from '../../../../common/services/apiCommon.service';
import { CustomValidators } from '../../../../common/shared/custom-validators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  user;
  constructor(private userService: CacheService, private dialog: MatDialog) { }

  ngOnInit() {
    this.user = this.userService.user;
  }

  openChangePasswordModal() {
    // tslint:disable-next-line:no-use-before-declare
    this.dialog.open(ProfileChangePasswordComponent);
  }
}

@Component({
  selector: 'app-modal-recovery-password',
  templateUrl: './modal-recovery-password.component.html',
})
export class ProfileChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  constructor(private modalChangePassword: MatDialogRef<ProfileChangePasswordComponent>, private formBuilder: FormBuilder, private apiService: ApiCommonService) { }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
        oldPassword: ['', [Validators.required, Validators.minLength(4)]],
        newPassword: ['', [Validators.required, Validators.minLength(4)]],
        repeatNewPassword: ['', [Validators.required, Validators.minLength(4)]],
      },
      {
        validator: CustomValidators.matchPassword,
      });
  }

  changePassword() {
    this.apiService.log('Your password has been changed');
    this.closeModal();
  }

  closeModal() {
    this.modalChangePassword.close();
  }
}
