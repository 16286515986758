<div class="alert alert-warning w-100 my-4" [@fadeInOut]>
  <div class="alert-icon">
    <mat-icon>info</mat-icon>
  </div>
  <div class="alert-text">
    <h5>Important</h5>
    <ul>
      <li *ngFor="let notification of notifications" class="font-s">{{'common.alerts.' + notification.key | translate : { params: notification.days } }}</li>
    </ul>
  </div>
</div>
