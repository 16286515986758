<div *ngIf="!recoverySended">
  <div mat-dialog-title class="position-relative ">
    <div (click)="closeModal()" class="position-absolute modal-close-icon pointer"><i class="material-icons pointer"> close </i></div>
  </div>
  <div mat-dialog-content>
    <h1>Recovery password</h1>
    <p>You can recover your password by entering your email here: </p>
    <form [formGroup]="recoveryForm">
      <mat-form-field class="mt-4 w-100">
        <input autocomplete="off" matInput type="email" formControlName="email" placeholder="Enter your email">
        <app-control-messages [control]="recoveryForm.get('email')"></app-control-messages>
      </mat-form-field>
      <button mat-flat-button (click)="sendEmail()" [disabled]="recoveryForm.get('email').invalid" color="primary">Send</button>
    </form>
  </div>
</div>

<div *ngIf="recoverySended">
  <h1 class="text-home font-weight-bold title-1">The email has been sended!</h1>
  <p class="py-4 cont-3"> Look on your spam folder</p>
  <button mat-stroked-button color="primary" (click)="closeModal()" class="d-flex justify-content-center my-3 mb-4 bg-primary-color radius-0">Come back</button>
</div>

