import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import provinces from './provinces.json';

import csc from 'country-state-city';
import { Company } from '../../models/app.models';
import { CustomValidators } from '../../shared/custom-validators';
import { environment } from '../../common.environment';

@Component({
  selector: 'app-facturation-data', templateUrl: './facturation-data.component.html', styleUrls: ['./facturation-data.component.scss'],
})
export class FacturationDataComponent implements OnInit {

  @Input() set billing_info(value) {
    if (value) {
      this.createPaymentForm(value);
    }
  }
  @Input() company: Company;
  @Input() showHeader = true;
  billing_info_form: FormGroup;
  states;
  languages = [{ cod: 'es', value: 'Español' }, { cod: 'en', value: 'English' }];
  country;
  eori;
  readOnlyForm = environment.bo;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
  }

  getStates(countryCod) {
    this.billing_info_form.get('tax_id').markAsTouched();
    this.billing_info_form.get('tax_id').setValue(this.eori);

    if (countryCod !== this.country) {
      this.billing_info_form.get('address').get('state').setValue(null);
    }
    if (countryCod === 'ES') {
      return this.states = provinces;
    }
    this.states = csc.getStatesOfCountry(csc.getCountryByCode(countryCod).id);

  }

  async createPaymentForm(billing_info?) {
    if (billing_info && billing_info.address && billing_info.address.country) {
      this.country = billing_info.address.country;
    }

    this.billing_info_form = this.fb.group({
      email: [billing_info.email, [Validators.required, CustomValidators.validateEmail]],
      tax_exemption: [billing_info.tax_exemption],
      tax_id: [billing_info.tax_id, [Validators.required, CustomValidators.onlyLettersOrNumbers, CustomValidators.EORIcountryMatch]],
      name: [billing_info.name, [Validators.required]],
      contact: [billing_info.contact, [Validators.required]],
      phone: [billing_info.phone, [Validators.required]],
      language: [billing_info.language, [Validators.required]],
      address: this.fb.group({
        city: [billing_info.address ? billing_info.address.city : null, [Validators.required]],
        country: [billing_info.address ? billing_info.address.country : null, [Validators.required]],
        line1: [billing_info.address ? billing_info.address.line1 : null, [Validators.required]],
        line2: [billing_info.address ? billing_info.address.line2 : null],
        postal_code: [billing_info.address ? billing_info.address.postal_code : null, [Validators.required]],
        state: [billing_info.address ? billing_info.address.state : null, [Validators.required]],
      }),
    });

    this.eori = this.billing_info_form.get('tax_id').value;

    if (billing_info.address && billing_info.address.country) {
      this.getStates(billing_info.address.country);
    }
  }

}
