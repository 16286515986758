
<div class="ml-2 font-m d-flex flex-column" *ngIf="latestVersion">
  <ul>
    <li>Type: {{document.type}}</li>
    <li>Last uploaded: {{latestVersion.name}}</li>
    <li *ngIf="latestVersion.description">Description: {{latestVersion.description}}</li>
    <li>Hash: {{latestVersion.hash}}</li>
    <li>Created at: {{latestVersion.uploaded_at | date: 'short'}}</li>
    <li>Uploaded by: {{latestVersion.uploaded_by?.name}} from {{latestVersion.uploaded_by?.company?.name}}</li>
    <li (click)="downloadFile(document, actualVersion)">Link: <a class="link">download</a> </li>
    <li class="truncate" *ngFor="let BC of latestVersion.blockchain">
      BC {{'documents.transaction' | translate}}:
      <a [href]="BC.upload.url" target="_blank">{{BC.name}}</a>
    </li>
  </ul>
<!--  <div class="d-flex justify-content-center align-items-center">-->
<!--    <mat-icon [ngClass]="{'disabled': actualVersion === document.versions.length -1 }" (click)="changeVersion(true)" class="icon-l pointer d-flex justify-content-center align-items-center text-primary-color">keyboard_arrow_left</mat-icon>-->
<!--    {{document.versions.length - actualVersion}} / {{document.versions.length}}-->
<!--    <mat-icon [ngClass]="{'disabled': actualVersion === 0 }" (click)="changeVersion(false)" class="icon-l pointer d-flex justify-content-center align-items-center text-primary-color">keyboard_arrow_right</mat-icon>-->
<!--  </div>-->

</div>
