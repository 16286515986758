<div class="overflow-detail modal-width">
  <div *ngIf="data">
    <div class="d-flex justify-content-end">
    </div>
    <app-shipment-data-insurance [insuranceOffer]="data"></app-shipment-data-insurance>
  </div>

  <div *ngIf="data && ![INSURANCE_OFFER_STATUS.ADMIN_REJECTED, INSURANCE_OFFER_STATUS.ADMIN_EXPIRED, INSURANCE_OFFER_STATUS.PENDING].includes(data.status)" class="">
    <div *ngIf="data" class="mt-3 font-m row">
      <div class="col-6">
        <div class="pointer px-4 py-2 detail">
          <div class="d-flex justify-content-around align-items-center row">
            <div class="col-8 font-s text-primary-color wrap font-weight-bold">{{data.rate.title}}</div>
            <div class="col-4 font-m font-weight-bold d-flex justify-content-end">{{data.rate.client_price_percent}}%</div>
          </div>
          <div class="d-flex row justify-content-around align-items-center">
            <div class="col-12 font-xs d-flex justify-content-end">{{partialPrice(data.rate) | number: '1.2-2'}} {{data?.insurable_value?.currency}}</div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div><span class="font-weight-bold ">Applied discount: </span>{{data.discount}}%</div>
        <div><span class="font-weight-bold font-m">{{'common.insurance.finalPrice' | translate}}: </span> <span class="font-m text-primary-color">{{data.offered_client_price?.amount * data.exchange_rate | number:'1.2-2'}} EUR</span></div>
        <div *ngIf="data.offered_client_price?.currency !== 'EUR'"><span class="font-s text-grey font-weight-bold">{{'common.insurance.estimatedChange' | translate}}: </span> <span class="font-s text-grey">{{data.offered_client_price?.amount | number: '1.2-2'}} {{data.offered_client_price?.currency}}</span></div>
      </div>
    </div>

      <div class="mt-1 d-flex row mt-3 font-m">
        <div class="col-6">
          <div><span class="font-weight-bold font-m">{{'common.insurance.insuranceCarrier' | translate}}: </span> <span>{{data.provider?.settings?.insurance_contact.company_name}}</span></div>
          <div class="d-flex text-light-grey">
            <span class="material-icons-outlined mr-2 font-l">mail</span>
            <mat-card-subtitle [matTooltip]="data.provider?.settings?.insurance_contact.contact_email" class="input-w">{{data.provider?.settings?.insurance_contact.contact_email}}</mat-card-subtitle>
          </div>
          <div class="d-flex text-light-grey">
            <span class="material-icons-outlined mr-2 font-l">settings_phone</span>
            <span>{{data.provider?.settings?.insurance_contact.contact_phone}}</span>
          </div>
        </div>
        <div class="col-6">
          <div><span class="font-weight-bold font-m">{{'common.insurance.brokerage' | translate}}: </span> <span>{{data.provider?.settings?.brokerage_contact.company_name}}</span></div>
          <div>
            <div class="d-flex text-light-grey">
              <span class="material-icons-outlined mr-2 font-l">mail</span>
              <mat-card-subtitle [matTooltip]="data.provider?.settings?.brokerage_contact.contact_email" class="input-w">{{data.provider?.settings?.brokerage_contact.contact_email}}</mat-card-subtitle>
            </div>
            <div class="d-flex text-light-grey">
              <span class="material-icons-outlined mr-2 font-l">settings_phone</span>
              <span>{{data.provider?.settings?.brokerage_contact.contact_phone}}</span>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
