import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-simple-searcher',
  templateUrl: './simple-searcher.component.html',
  styleUrls: ['./simple-searcher.component.scss'],
})
export class SimpleSearcherComponent implements OnInit {

  simpleSearchForm: FormGroup;
  @Output() search: EventEmitter<string> = new EventEmitter();
  @Input() placeholder;
  @Input() typingSearch = false;
  @Input() set value(value) {
    this.simpleSearchForm = new FormGroup({ search: new FormControl(value || '') });
  }

  constructor() { }

  ngOnInit(): void {
    this.simpleSearchForm = new FormGroup({ search: new FormControl( '') });
  }

  applyFilter(filterValue: string) {
    if (filterValue === '') filterValue = undefined;
    this.search.emit(filterValue);
  }

}
