import * as lodash from 'lodash';
import { TranslateCompiler } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import es from './es.json';
import en from './en.json';
import cl from './cl.json';
import esFront from '../../front/src/assets/i18n/es.json';
import clFront from '../../front/src/assets/i18n/cl.json';

export class CustomCompiler implements TranslateCompiler {
  common = { es, en, cl };
  compile(value: string, lang: string): string {
    // tslint:disable-next-line:no-console
    console.error('Si lees esto investiga quien te llama [common-i18n]');
    return value;
  }

  compileTranslations(translation: any, lang: string) {
    // TODO si necesitamos chileno en el BO algún día va a explotar
    if (lang === 'cl') {
      // el fichero chileno hereda del español y sobreescribe las claves personalizadas
      const translateClFront: any = lodash.merge(esFront, clFront);
      translateClFront.common = lodash.merge(es, cl);
      return translateClFront;
    }
    translation.common = this.common[lang];
    return translation;
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const compareI18N = (esObject, enObject, path) => {
  const esKeys = Object.keys(esObject);
  const enKeys = Object.keys(enObject);
  if (esKeys.length !== enKeys.length) return { equals: false, reason: `Not same number of keys in ${path} --${esKeys} vs ${enKeys}` };
  for (let i = 0; i < esKeys.length; i++) {
    if (esKeys[i] !== enKeys[i]) return { equals: false, reason: `Not same value in position ${i}: ${esKeys[i]} !== ${enKeys[i]}` };
    if (!lodash.isString(esObject[esKeys[i]])) {
      const res = compareI18N(esObject[esKeys[i]], enObject[enKeys[i]], path + ' - ' + esKeys[i]);
      if (!res.equals) return res;
    }
  }
  return { equals: true };
};
