<h2 class="mt-5 form-header__title d-flex align-items-center">
  <mat-icon class="mr-2">assessment</mat-icon>
  {{'common.usage.usage' | translate}}</h2>
<div *ngIf="usage" class="row d-flex flex-column align-items-center justify-content-md-around flex-md-row mt-4 p-4">
  <mat-card class="basic-card my-2">
    <mat-card-header>
      <mat-card-title class="text-center text-uppercase">{{'common.usage.RECORDS' | translate}} <span class="font-xs">({{'common.usage.month' | translate}})</span></mat-card-title>
      <mat-card-subtitle class="mb-0 text-center">
        <span [ngClass]="{'text-primary-color': usage?.RECORDS?.excess > 0}" class="font-weight-bold spent">{{usage?.RECORDS?.current}} </span>
        {{'common.of' | translate}}
        <span *ngIf="subscription?.plan?.base_plan_id !== PLANS.FREEMIUM"> {{usage?.RECORDS?.included !== null && subscription?.plan?.base_plan_id !== PLANS.SAND ? usage?.RECORDS?.included : ('common.usage.unlimited' | translate)}} </span>
        <span *ngIf="subscription?.plan?.base_plan_id === PLANS.FREEMIUM"> {{usage?.RECORDS?.limit}} </span>
        <div *ngIf="usage?.RECORDS?.excess > 0" class="font-xs mt-2 text-primary-color text-capitalize">*{{'common.usage.excess' | translate}}: {{usage?.RECORDS?.excess}}</div>
      </mat-card-subtitle>
    </mat-card-header>
  </mat-card>

  <mat-card class="basic-card my-2">
    <mat-card-header>
      <mat-card-title class="text-center text-uppercase">{{'common.usage.MEMBERS' | translate}}</mat-card-title>
      <mat-card-subtitle class="mb-0 text-center">
        <span [ngClass]="{'text-primary-color': usage?.MEMBERS?.excess > 0}" class="font-weight-bold spent">{{usage?.MEMBERS?.current}} </span>
        {{'common.of' | translate}}
        <span> {{usage?.MEMBERS?.included !== null ? usage?.MEMBERS?.included : ('common.usage.unlimited' | translate)}} </span>
        <div *ngIf="usage?.MEMBERS?.excess > 0" class="font-xs mt-2 text-primary-color text-capitalize">*{{'common.usage.excess' | translate}}: {{usage?.MEMBERS?.excess}}</div>
      </mat-card-subtitle>
    </mat-card-header>
  </mat-card>

  <mat-card class="basic-card my-2">
    <mat-card-header>
      <mat-card-title class="text-center text-uppercase">{{'common.usage.STORAGE' | translate}}</mat-card-title>
      <mat-card-subtitle class="mb-0 text-center">
        <span [ngClass]="{'text-primary-color': usage?.STORAGE?.excess > 0}" class="font-weight-bold spent">{{usage?.STORAGE?.current | number:'1.2-2'}}</span>GiB
        {{'common.of' | translate}}
        <span *ngIf="subscription?.plan?.base_plan_id !== PLANS.FREEMIUM"> {{usage?.STORAGE?.included !== null ? (usage?.STORAGE?.included + 'GiB') : ('common.usage.unlimited' | translate)}} </span>
        <span *ngIf="subscription?.plan?.base_plan_id === PLANS.FREEMIUM"> {{usage?.STORAGE?.limit}} </span>
        <div *ngIf="usage?.STORAGE?.excess > 0" class="font-xs mt-2 text-primary-color text-capitalize">*{{'common.usage.excess' | translate}}: {{usage?.STORAGE?.excess | number:'1.2-2'}}GiB</div>
      </mat-card-subtitle>
    </mat-card-header>
  </mat-card>
</div>


<h2 class="mt-5 form-header__title d-flex align-items-center">
  <mat-icon class="mr-2">assessment</mat-icon>
  {{'common.charges.charges' | translate}}</h2>
<div *ngIf="charges" class="row d-flex flex-column align-items-center justify-content-md-around flex-md-row mt-4 p-4">
  <div *ngFor="let service of SERVICES">
    <mat-card class="basic-card my-2">
      <mat-card-header>
        <mat-card-title class="text-center text-uppercase">{{('common.charges.' + service.toLowerCase()) | translate}}</mat-card-title>
        <mat-card-subtitle class="mb-0 text-center">
          <span> {{charges[service]._ALL_.amount || 0}} {{ charges[service]._ALL_.currency || 'EUR'}} </span>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </div>
</div>


<div *ngIf="!usage && !(eventService.loading | async)" class="d-flex align-items-center font-s ml-2 py-3">
  <mat-icon class="mr-2 text-primary-color">info</mat-icon>
  The company is not accepted yet.
</div>
