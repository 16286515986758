import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { AbstractControl, FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { CacheService } from '../services/cache.service';
import { GenericModalData } from './components/common/modals/generic-modal/generic-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { NotificationParams } from '../../../common/models/app.models';
import { ApiCommonService } from '../../../common/services/apiCommon.service';
import packageJSON from '../../../../package.json';

const helper = new JwtHelperService();
const actors = ['buyer', 'consignee', 'supplier', 'reception', 'deal', 'transport', 'fwd', 'fwo', 'documents', 'edocs', 'notify', 'transporter', 'payment', 'financing', 'destination', 'compliance', 'invitations', 'visibility_matrix', 'insurance'];
const roles = ['buyer', 'consignee', 'supplier', 'reception', 'fwd', 'fwo', 'notify', 'transporter', 'destination', 'manager', 'authority', 'external_services', 'inspection_services', 'watcher'];
const specialRoles = ['manager', 'authority', 'external_services', 'inspection_services', 'watcher'];
const languages = [{ cod: 'es', value: 'Español' }, { cod: 'en', value: 'English' }, { cod: 'cl', value: 'Chileno' }];
const notificationsConfigurables = ['record-upload-new-doc', 'record-upload-new-version', 'modify-deal-details', 'modify-actor-details', 'record-inactive', 'invite-to-chat', 'new-messages-chat', 'exclude-from-chat', 'insurance'];
// record-financing es visible, pero solo webhooks son configurables,
// ToDo mejorar esta gestión para poder pasarle que clase de notificacion está activada por defecto
const notificationsNoConfigurables = ['record-financing'];

const docHash = {
  llerandi: '29b5b3d3a78719b57213afa116c6f7c760a89485d96c2657044fb38d8c327694',
  oskar: '20d73620fc63dbd147bb4e98028e771795542b83444d2b76f4b326e53e97cbb5',
  responsible_declaration: '38671d0c8c347f6f5a9a17d2aab0cd145a389383eb88e5d57ab5b9863f722fe9',
};

@Injectable()
export class Helper {

  constructor(private location: Location, private router: Router, private cacheService: CacheService, private translate: TranslateService, private apiService: ApiCommonService) {
    setInterval(() => this.checkLastVersion(), 86400000); // 24h in seconds
  }

  static validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  static getImageUrl(logo: string): string {
    return (logo.includes('http')) ? logo : environment.url + logo;
  }

  static getActors(): string[] {
    return actors;
  }

  static getRoles(): string[] {
    return roles;
  }

  static getSpecialRoles(): string[] {
    return specialRoles;
  }

  static getPlanDaysLeft(planMaxEndAt: Date): number {
    const a = new Date(planMaxEndAt).getTime() - new Date().getTime();
    // 86400000 es un día en milisegundos
    return a / 86400000;
  }

  static hasViewPermissions(matrix, participants: any[]) {
    for (const participant of Object.values(participants)) {
      participant.permissions = [];
      participant.allow = [];
      for (const actor of participant.from) {
        if (actor !== 'creator') participant.permissions = participant.permissions.concat(matrix[actor.toUpperCase()]);
      }
    }

    for (const participant1 of Object.values(participants)) {
      for (const participant2 of Object.values(participants)) {
        for (const actor1 of participant1.from) {
          for (const actor2 of participant2.from) {
            // si cualquiera de los dos es creador o si se ven entre ellos
            if (actor1 === 'creator' || actor2 === 'creator' || (participant1.permissions.includes(actor2 + ':read') && participant2.permissions.includes(actor1 + ':read'))) {
              if (!participant1.allow.includes(participant2._id)) participant1.allow.push(participant2._id);
              if (!participant2.allow.includes(participant1._id)) participant2.allow.push(participant1._id);
            }
          }
        }
      }
    }
    return participants;
  }

  static formatParticipants(participants) {
    return participants.map(el => ({ user: el._id }));
  }

  static deleteMyUserFromParticipants(participants, userId) {
    return participants.filter(el => el.user !== userId);
  }

  static getControls(form, path) {
    return form.get(path).controls;
  }

  // limpia los valores repetidos de un array y lo devuelve el resultado
  // solo valores primitivos
  static getUniqueValues(array: string[]): any[] {
    return [...new Set(array)];
  }

// limpia undefined y nulls de un objeto de forma recursiva
  static cleanObject(object: any) {
    Object
      .entries(object)
      .forEach(([k, v]) => {
        if (v && typeof v === 'object') {
          this.cleanObject(v);
        }
        if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined) {
          if (Array.isArray(object)) {
            // FIXME problema de tipos, hablar con Pablo
            // object.splice(k, 1);
          } else {
            delete object[k];
          }
        }
      });
    return object;
  }

  static getFormValidationErrors(absCtrl: AbstractControl): ValidationErrors {
    let errors: ValidationErrors = absCtrl.errors;
    if (absCtrl instanceof FormGroup) {
      Object.keys(absCtrl.controls).map(elem => {
        const res = Helper.getFormValidationErrors(absCtrl.controls[elem]);
        if (res) errors = { ...res, ...errors };
      });
    }
    if (absCtrl instanceof FormArray) {
      absCtrl.controls.map(elem => {
        const res = Helper.getFormValidationErrors(elem);
        if (res) errors = { ...res, ...errors };
      });
    }
    return errors;
  }

  static logError(traza: string, obj?) {
    // tslint:disable-next-line:no-console
    console.log('TRAZA:', traza, obj); // Aqui no deberia llegar nunca, si ha llegado tendras mas info de lo que ha sucedido
  }

  static isTokenExpired(token): boolean {
    return helper.isTokenExpired(token);
  }

  static getTimeToExpireToken(token) {
    if (this.isTokenExpired(token)) return 0;
    const expireTime = helper.getTokenExpirationDate(token).getTime();
    const difference = expireTime - new Date().getTime();
    return (difference > 2147483647) ? 2147483647 : difference; // 2147483647 is maxInt32
  }

  static buildURLFromAction(action: string, params: NotificationParams, isGuest: boolean): string {
    const recordDetailURL = isGuest ? '/record-detail-guest' : '/record-detail';
    const messageURL = isGuest ? '/messages-guest' : '/messages';
    const urls = {
      'record-upload-new-version': `${recordDetailURL}/${params.record_id}/documents/${params.type}/${params.docId}`,
      'record-upload-new-doc': `${recordDetailURL}/${params.record_id}/documents/${params.type}/${params.docId}`,
      'modify-deal-details': `${recordDetailURL}/${params.record_id}/deal`,
      'modify-actor-details': `${recordDetailURL}/${params.record_id}/${params.actor}`,
      'record-invite-company': `${recordDetailURL}/${params.record_id}/${params.actor}`,
      'record-invite-guest': `${recordDetailURL}/${params.record_id}/${params.actor}`,
      'record-inactive': `${recordDetailURL}/${params.record_id}`,
      'record-financing': `${recordDetailURL}/${params.record_id}?financing=${params.financing_id}`,
      'new-messages-chat': `${messageURL}/id/${params.chat_id}`,
      'invite-to-new-chat': `${messageURL}/id/${params.chat_id}`,
      'invite-to-chat': `${messageURL}/id/${params.chat_id}`,
      'exclude-from-chat': undefined,
      'insurance-new-offer-user': `${recordDetailURL}/${params.record_id}/insurance`,
      'insurance-provider-accepts-offer': `${recordDetailURL}/${params.record_id}/insurance`,
      'insurance-provider-rejects-offer': `${recordDetailURL}/${params.record_id}/insurance`,
      'insurance-user-interested-offer': `${recordDetailURL}/${params.record_id}/insurance`,
      'edoc-failed': `${recordDetailURL}/${params.record_id}/edocs`,
    };
    return urls[action];
  }

  static buildURLFromActionBO(action: string, params: NotificationParams): string {
    const urls = {
      'insurance-new-offer-available': `/?section=1&offer_id=${params.offer_id}&make_offer=true`,
      'edoc-issued': `?section=1?subsection=2`,
      'edoc-delivered': `?section=1?subsection=2`,
      'edoc-failed': `?section=1?subsection=2`,
      // section=1?subsection=2
    };
    return urls[action];
  }

  static getAvailableLangs() {
    return languages;
  }

  static changeCommaValue(value) {
    return value.replace(/,/g, '.');
  }

  // recibe por parámetro si quieres todas las notificaciones existentes o solo las configurables
  static getAllNotifications(configurable: boolean): string[] {
    const data: string[] = configurable ? notificationsConfigurables : notificationsConfigurables.concat(notificationsNoConfigurables);
    return [...data];
  }

  static getDocHash(name) {
    return docHash[name];
  }

  static parseQuery(queryString) {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('?');
    for (const i of pairs) {
      const pair = i.split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

  checkLastVersion() {
    this.apiService.getLastVersion().subscribe(serverVersion => {
      if (serverVersion && serverVersion !== packageJSON.version) {
        // tslint:disable-next-line:no-console
        console.log('Version nueva: ' + serverVersion + ' Version antigua' + packageJSON.version);
        this.apiService.logWarn('common.common.new-release', true);
        setTimeout(() => window.location.reload(), 5000);
      }
    });
  }

  // sin evento de cambio de ruta
  redirectTo(url: string) {
    const queriesParams = '?' + (this.router.url.split('?')[1] || '');
    this.location.go(url + queriesParams);
    window.location.reload();
  }

  getTermsAndConditions(lang?): string {
    return `assets/documents/${lang || this.cacheService.getRealLang()}/termsAndConditions.pdf`;
  }

  async translateModal(data: { title?: string | { title?: string, params: { [key: string]: string; } }, content?: string | { content?: string, params: { [key: string]: string; } }, buttons?: { label: string, value: any, styles?: 'primary' | 'cancel' }[] }): Promise<GenericModalData> {
    const modalData: GenericModalData = { buttons: [] };
    for (const button of data.buttons) {
      modalData.buttons.push({ label: await this.translate.get(button.label).toPromise(), value: button.value, styles: button.styles || 'primary' });
    }
    const dataObject: any = data;
    if (dataObject.content) modalData.content = await this.translate.get(dataObject.content?.content || dataObject.content, dataObject.content?.params || {}).toPromise();
    if (dataObject.title) modalData.title = await this.translate.get(dataObject.title?.title || dataObject.title, dataObject.title?.params || {}).toPromise();
    return modalData;
  }

}
