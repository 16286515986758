<div *ngIf="company" class="container mb-5">
  <div class="d-flex justify-content-end mb-4">
    <button mat-stroked-button routerLink="/" class="mt-4" color="primary">Go back</button>
  </div>
  <div class="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
    <h2>Company detail</h2>
    <div *ngIf="company.state !== 'INCOMPLETED'" class="d-flex flex-column flex-md-row justify-content-end mt-2">
      <!--      <button mat-raised-button class="px-5 my-2 mr-2" color="primary" (click)="hasLegalType()"> {{company?.state === STATES.ACCEPTED ? 'Resend welcome email' : 'Approve'}}</button>-->
      <!--      <button mat-raised-button class="px-5 my-2" color="primary" (click)="rejectCompany()">Reject</button>-->
    </div>
  </div>
  <div class="bg-primary-light px-4 py-3 d-flex justify-content-between">
    <h3 class="mb-0">{{company.name}}</h3>
    <h3 class="mb-0">{{company.EORI_NIF}}</h3>
  </div>
  <form *ngIf="newCompanyForm" [formGroup]="newCompanyForm" (keydown.enter)="$event.preventDefault()">

    <mat-tab-group mat-align-tabs="center">
      <mat-tab label="Account info">

        <div class="row justify-content-around mx-3">

          <mat-card class="basic-card big-subtitle d-flex align-items-center col-10 col-lg-5 col-xl-5 mx-1 border mb-3 mb-lg-0">
            <div class="d-flex justify-content-center w-100">
              <mat-card-header class="w-50">
                <mat-card-title>ROLE</mat-card-title>
                <mat-card-subtitle>{{company.role}}</mat-card-subtitle>
                <mat-card-subtitle class="align-items-center justify-content-center">
                  <mat-checkbox [checked]="!!provider || !!edocProvider" [disabled]="provider || edocProvider" (change)="createProvider()"></mat-checkbox>
                  <div class="font-m ml-2">Provider</div>
                </mat-card-subtitle>

              </mat-card-header>
              <div class="border-right d-none d-lg-flex"></div>
              <mat-card-header class="w-50 d-none d-lg-flex">
                <mat-card-title>STATE</mat-card-title>
                <mat-card-subtitle [ngClass]="{'text-danger': company.state === 'REJECTED', ' text-success': company.state === 'ACCEPTED'}">{{conversion[company.state]}}</mat-card-subtitle>
              </mat-card-header>
            </div>
          </mat-card>

          <mat-card class="basic-card big-subtitle d-flex d-lg-none mb-3 mb-lg-0 col-10 col-lg-5 col-xl-5 mx-1 border">
            <mat-card-header class="w-50">
              <mat-card-title>STATE</mat-card-title>
              <mat-card-subtitle [ngClass]="{'text-danger': company.state === 'REJECTED', ' text-success': company.state === 'ACCEPTED'}">{{conversion[company.state]}}</mat-card-subtitle>
            </mat-card-header>
          </mat-card>

          <mat-card *ngIf="company.state !== STATES.INCOMPLETED && subscriptionWrapper" class="basic-card big-subtitle col-10 col-lg-5 col-xl-5 mx-1 border">
            <mat-card-header>
              <mat-card-title>Company subscription</mat-card-title>
              <mat-card-subtitle class="mb-0 justify-content-center">{{subscriptionWrapper.current_subscription ? (subscriptionWrapper.current_subscription.plan.is_custom && !subscriptionWrapper.current_subscription.plan.base_plan_id ? 'CUSTOM' : subscriptionWrapper.current_subscription.plan.base_plan_id) : 'This company has no plan assigned yet'}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div *ngIf="subscriptionWrapper?.current_subscription?.plan && subscriptionWrapper?.next_subscription?.start_at" class="d-flex flex-column align-items-center mt-3 alert-warning p-1">
                <div class="d-flex">
                  <mat-icon class="mr-2 text-primary-color">info</mat-icon>
                  <span
                    [innerHTML]="'plans.nextSubscription' | translate: {actual: ('plans.' + (subscriptionWrapper?.current_subscription?.plan.base_plan_id || 'CUSTOM') | translate), next: ('plans.' + (subscriptionWrapper?.next_subscription?.plan.base_plan_id || 'CUSTOM') | translate), date: (subscriptionWrapper?.next_subscription?.start_at | date : 'medium')}"></span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="row justify-content-start ml-4 my-4">
          <mat-card-subtitle *ngIf="displayServices && !provider" class="d-flex col-6 col-lg-5 col-xl-5">
            <app-info-hover></app-info-hover>
            <div class="font-xs text-primary-color">To register this company as a provider, you must choose and set the service this company provides</div>
          </mat-card-subtitle>
        </div>

        <h2 class="mt-5 mb-3 form-header__title d-flex align-items-center">
          <mat-icon class="mr-2">create</mat-icon>
          Customize subscription
        </h2>

        <app-alert-banner *ngIf="notifications.length" [notifications]="notifications"></app-alert-banner>

        <mat-form-field class="px-5 py-2">
          <mat-label>Account plan</mat-label>
          <mat-select (selectionChange)="changePlanParameters( $event.value)" formControlName="base_plan_id">
            <mat-option></mat-option>
            <mat-option *ngFor="let plan of plans" [value]="plan.base_plan_id ? plan.base_plan_id : null">
              {{plan.base_plan_id}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <section id="customizer" *ngIf="customPlan" class="mt-4">
          <div class="row d-flex flex-column align-items-center justify-content-md-between flex-md-row mt-4 mx-1">

            <mat-card *ngFor="let product of customPlan.products" class="basic-card mb-3 mb-lg-0 title-with-icon">
              <mat-card-header class="header-with-icon w-100">
                <mat-card-title class="w-100 mb-0">
                  <div class="header-icon">
                    <mat-icon>{{icons[product.id]}}</mat-icon>
                  </div>
                  <span class="header-icon-text">{{'common.usage.' + product.id | translate}}</span>
                </mat-card-title>

              </mat-card-header>
              <mat-card-content class="mb-0">

                <div class="border-bottom mb-2 p-3">
                  <div class="border-bottom font-weight-bold d-flex justify-content-between"><span>Included</span>
                    <div class="font-s d-flex align-items-center">
                      Unlimited
                      <mat-checkbox [checked]="product.included === null" (change)="product.included = $event.checked ? null : 0" class="font-s mb-0 ml-1"></mat-checkbox>
                    </div>
                  </div>
                  <app-incrementor [product]="product.id" (setValue)="product.included = $event" [units]="product.id" [value]="product.included" [limit]="product.limit"></app-incrementor>
                </div>

                <div class="border-bottom my-2 p-3">
                  <div class="border-bottom font-weight-bold">Price extra unit</div>
                  <app-incrementor [product]="product.id" (setValue)="product.extra_price.amount = $event" [units]="product.extra_price.currency" [value]="product.extra_price.amount"></app-incrementor>
                </div>

                <div class="border-bottom mt-2 p-3">
                  <div class="border-bottom font-weight-bold d-flex justify-content-between"><span>Limit</span>
                    <div class="font-s d-flex align-items-center">
                      Unlimited
                      <mat-checkbox [checked]="product.limit === null" (change)="product.limit = $event.checked ? null : 0" class="font-s mb-0 ml-1"></mat-checkbox>
                    </div>
                  </div>
                  <app-incrementor [product]="product.id" (setValue)="product.limit = $event" [units]="product.id" [value]="product.limit"></app-incrementor>
                </div>

              </mat-card-content>
            </mat-card>
          </div>

          <h2 class="mt-5 mb-3 d-flex align-items-center">
            Services
          </h2>

          <!-- card de LIMITE de INSURANCE -->
          <div *ngIf="limitForm" [formGroup]="limitForm" class="row d-flex flex-column align-items-center justify-content-md-start flex-md-row mt-4 mx-1">
            <mat-card class="basic-card mb-3 mb-lg-0 title-with-icon mr-4">
              <mat-card-header class="header-with-icon w-100">
                <mat-card-title class="w-100 mb-0">
                  <div class="header-icon">
                    <mat-icon>lock</mat-icon>
                  </div>
                  <span class="header-icon-text">INSURANCES</span>
                </mat-card-title>
              </mat-card-header>
              <div>
                <mat-card-content class="mb-0">
                  <div class="border-bottom mb-2 p-3">
                    <div class="border-bottom font-weight-bold d-flex justify-content-between"><span>Limit</span>
                      <div class="font-s d-flex align-items-center">
                        Unlimited
                        <mat-checkbox [checked]="limitForm.get('INSURANCE.services_amount').value === null" (change)="checkDisable($event)" class="font-s mb-0 ml-1"></mat-checkbox>
                      </div>
                    </div>
                    <div class="my-2 d-flex justify-content-between align-items-center">
                      <span class="mr-4">Month</span>
                      <input min="0" numeric decimals="5" matInput type="number" class="w-50 border" [maxLength]="5" [formControl]="limitForm.get('INSURANCE.services_amount')">
                    </div>
                  </div>
                </mat-card-content>
              </div>
            </mat-card>
            <!-- card de LIMITE de INSURANCE -->


            <!-- card de LIMITE de EDOCS -->
            <!-- TODO no está enganchado aun a back el limite de edocs -->
            <mat-card class="basic-card mb-3 mb-lg-0 title-with-icon">
              <mat-card-header class="header-with-icon w-100">
                <mat-card-title class="w-100 mb-0">
                  <div class="header-icon">
                    <mat-icon>lock</mat-icon>
                  </div>
                  <span class="header-icon-text">EDOCS</span>
                </mat-card-title>
              </mat-card-header>
              <div>
                <mat-card-content class="mb-0">
                  <div class="border-bottom mb-2 p-3">
                    <div class="border-bottom font-weight-bold d-flex justify-content-between"><span>Limit</span>
                      <div class="font-s d-flex align-items-center">
                        Unlimited
                        <mat-checkbox [checked]="limitForm.get('EDOCS.services_amount').value === null" (change)="checkDisable($event)" class="font-s mb-0 ml-1"></mat-checkbox>
                      </div>
                    </div>
                    <div class="my-2 d-flex justify-content-between align-items-center">
                      <span class="mr-4">Month</span>
                      <input min="0" numeric decimals="5" matInput type="number" class="w-50 border" [maxLength]="5" [formControl]="limitForm.get('EDOCS.services_amount')">
                    </div>
                  </div>
                </mat-card-content>
              </div>
            </mat-card>
            <!-- card de LIMITE de EDOCS -->

          </div>


          <div class="row px-3 d-flex flex-column align-items-center justify-content-between flex-md-row mt-4">
            <mat-card class="w-100 border">
              <div [ngClass]="{'justify-content-md-end': customPlan.base_plan_id !== PLANS.WOOD, 'justify-content-md-between': customPlan.base_plan_id === PLANS.WOOD}" class="d-flex flex-column flex-sm-row align-items-center px-4">

                <div *ngIf="customPlan.base_plan_id === 'WOOD'" class="mb-3 mb-sm-0">
                  <div class="font-weight-bold d-flex justify-content-between"><span class="mr-2">Days of trial plan</span>
                    <div class="font-s d-flex align-items-center">
                      Unlimited
                      <mat-checkbox [checked]="timeLimit === null" (change)="timeLimit = $event.checked ? null : 0" class="font-s mb-0 ml-1"></mat-checkbox>
                    </div>
                  </div>
                  <app-incrementor (setValue)="timeLimit = $event" [units]="'DAYS'" [value]="timeLimit"></app-incrementor>
                </div>

                <div>
                  <div class="font-weight-bold d-flex justify-content-center ">Total price</div>
                  <app-incrementor (setValue)="customPlan.price.amount = $event" [units]="customPlan.price.currency" [value]="(customPlan.base_plan_id !== 'WOOD' ? customPlan.price.amount : 0) | cast" [limit]="(customPlan.base_plan_id !== 'WOOD' ? null : 0) | cast"></app-incrementor>
                </div>

              </div>
            </mat-card>
          </div>

          <div class="d-flex justify-content-end m-2">
            <button mat-flat-button (click)="saveCustomPlan()" [disabled]="!newCompanyForm.get('base_plan_id').value" color="primary" class="px-5 my-3">Save PLAN</button>
          </div>
        </section>

      </mat-tab>

      <mat-tab *ngIf="displayServices || provider || edocProvider" label="Services">
        <app-company-services></app-company-services>
      </mat-tab>

      <mat-tab label="Company data">

        <div class="container border p-2">
          <h3 class="text-center">{{'BO.companyorparticular' | translate}}</h3>
          <mat-radio-group (change)="changeLegalType($event.value)" [value]="billingInfo?.legal_type" aria-label="Select an option" class="d-flex flex-row-reverse justify-content-around mt-4">
            <mat-radio-button class="text-capitalize" *ngFor="let legal_type of legal_types" [value]="legal_type.id">{{legal_type.name}}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="mt-5">
          <form [formGroup]="newCompanyForm" (keydown.enter)="$event.preventDefault()" class="p-5 text-left" *ngIf="newCompanyForm">
            <app-input-text class="position-relative" [control]="newCompanyForm.get('name')" [maxLength]="60" [placeholder]="'companyName*'"></app-input-text>
            <app-input-text [control]="newCompanyForm.get('address')" [maxLength]="60" [placeholder]="'Address*'"></app-input-text>
            <app-input-text [control]="newCompanyForm.get('city')" [maxLength]="35" [placeholder]="'City*'"></app-input-text>
            <app-input-text [control]="newCompanyForm.get('zip_code')" [maxLength]="9" [placeholder]="'Zip*'"></app-input-text>
            <app-autocomplete-select type="country" class="position-relative" [placeholder]="'country*'" [control]="newCompanyForm.get('country') | cast"></app-autocomplete-select>
            <app-input-text [control]="newCompanyForm.get('contact')" [maxLength]="60" [placeholder]="'Contact*'"></app-input-text>
            <app-input-text [control]="newCompanyForm.get('phone')" [maxLength]="50" [placeholder]="'Phone*'"></app-input-text>
            <app-input-text [control]="newCompanyForm.get('EORI_NIF')" [maxLength]="25" [placeholder]="'EORI*'"></app-input-text>
            <app-input-text [control]="newCompanyForm.get('REX')" [maxLength]="35" [placeholder]="'REX'"></app-input-text>
          </form>
        </div>
      </mat-tab>
      <mat-tab label="Documents">

        <h2 class="mt-5 mb-4 form-header__title d-flex align-items-center">
          <mat-icon class="mr-2">chrome_reader_mode</mat-icon>
          Documents
        </h2>
        <div class="d-flex align-items-center px-5" *ngIf="!company?.files.length">
          <mat-icon class="mr-1 text-primary-color">info</mat-icon>
          No uploaded documents
        </div>
        <div *ngFor="let file of company.files" class="border-bottom">
          <app-file-info [document]="file" [id]="company._id" [filesType]="'company'"></app-file-info>
        </div>

      </mat-tab>
      <mat-tab label="Members">
        <app-company-invitations [user]="user" [subscription]="subscriptionShow" [companyId]="company._id"></app-company-invitations>
      </mat-tab>

      <mat-tab *ngIf="company?.state === STATES.ACCEPTED && company?.role !== 'TAXES'" label="Invoices">
        <app-usage [company]="company" [subscription]="subscription"></app-usage>
        <app-invoices [company]="company"></app-invoices>
        <app-facturation-data *ngIf="billingInfo" [billing_info]="billingInfo" [company]="company"></app-facturation-data>
      </mat-tab>

      <mat-tab label="TPP">
        <ng-template matTabContent>
          <app-company-tpp></app-company-tpp>
        </ng-template>
      </mat-tab>

    </mat-tab-group>
  </form>
</div>


<div *ngIf="loading" class="loading">
  <div class="font-weight-bold">Loading...</div>
  <mat-spinner></mat-spinner>
</div>


