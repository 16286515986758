import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { STATES } from '../../../../common/models/app.models';
import { ApiCommonService } from '../../../../common/services/apiCommon.service';
import { EventCommonService } from '../../../../common/services/eventCommon.service';

@Component({
  selector: 'app-record',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss'],
})
export class CompaniesListComponent implements OnInit {

  STATES = STATES;
  displayedColumns: string[] = ['companyName', 'date', 'state'];
  dataSource = null;
  alldata;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private apiService: ApiCommonService, public eventService: EventCommonService) { }

  ngOnInit() {
    // magia arcana: para evitar Afterviewcheckederror de loading en el html de app
    setTimeout(() => this.getCompanies(), 0);
  }

  getCompanies(search?: string) {
    this.apiService.getCompanies(search).subscribe(res => {
      this.alldata = res;
      const allCompaniesWithoutGuest = this.alldata.filter(el => !el.guest);
      this.updateTable(allCompaniesWithoutGuest);
    });
  }

  updateTable(data) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    if (filterValue === '') filterValue = undefined;
    this.getCompanies(filterValue);
  }
}
