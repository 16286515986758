<div class="select-autocomplete position-relative w-100" *ngIf="data">
  <mat-form-field class="w-100">
    <input type="text" placeholder="{{('common.autocomplete.'+ placeholder | translate) || 'Select ' + type}}" matInput [formControl]="_control" autocomplete="nope" [matAutocomplete]="auto" (blur)="verifySelection()">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn()" (optionSelected)="verifyAutoCompleteSelection($event)" (optionsScroll)="getNextBatch()">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.code" [disabled]="option.disabled">
        <span [innerHTML]="option.name | highlight: _control.value || ''"></span>
      </mat-option>
      <mat-option *ngIf="(filteredOptions | async).length === maxElements" disabled="true">
        <span> {{'common.common.more' | translate}} </span>
      </mat-option>
    </mat-autocomplete>


    <app-control-messages [control]="_control"></app-control-messages>
  </mat-form-field>
  <mat-icon class="autocomplete-icon">search</mat-icon>
</div>
