export const environment = {
  name: 'local',
  bo: false,
  production: false,
  url: 'http://localhost:8080', // ej: https://pre.myecustoms.com
  urlTracking: 'https://connect.track-trace.com/for/usyncro/',
  urlUploads: 'http://localhost:8080/api/uploads',
  maxUploadFiles: 5,
  maxFileSize: 0.1, // tamaño máximo de archivo en subida
  segmentKey: '',
  stripeKey: 'pk_test_jMhXAY9p8und0Xzik3gWR2iC00bw9huQgg',
  captchaKey: '6LeJxnUgAAAAAJK57Y8h8SxoBJCbTKwOW20_niur', // key de captcha v3
  enableCaptcha: true,
  intercomKey: 'z2cghonq',
  lastDaysTrial: 7,
  billing: true,
  webPushVAPIDPublicKey: 'BD6cnAJfZmcfNW97uRJ5h2cz8gFY3vwC4A59pUbQiXvylK8LxkuNrwSjomp53TwTVK6rq6qThzT4aEbVgiFIUNw',
  enableCookiebot: true,
  sentry: '',
  simple: true,
};
