import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Company, EDOCS, SERVICES } from 'projects/common/models/app.models';
import { ApiCommonService } from 'projects/common/services/apiCommon.service';

@Component({
  selector: 'app-company-tpp',
  templateUrl: './company-tpp.component.html',
  styleUrls: ['./company-tpp.component.scss'],
})
export class CompanyTppComponent implements OnInit {

  @Input() disabled = false;
  enabled: boolean;
  companyId: string;
  integrationForm: FormArray;
  edocIntegrationForm: FormArray;
  company: Company;
  providers: any;
  provider: any;
  service: string;
  insuranceIntegrations: any[];
  edocsIntegrations: any[];
  EdocsTypes = Object.values(EDOCS);

  constructor(private apiService: ApiCommonService, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder) { }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async params => {
      this.companyId = params.id;
      await this.getIntegrations();
      await this.getProvider();
      this.createInsuranceIntegrationForm();
      this.createEdocsIntegrationForm();
      this.getCompanyDetail();
    });
  }

  async getIntegrations() {
    const insuranceIntWrapper = await this.apiService.getIntegrations(this.companyId, SERVICES.INSURANCE).toPromise();
    const edocIntWrapper = await this.apiService.getIntegrations(this.companyId, SERVICES.EDOCS).toPromise();

    this.insuranceIntegrations = insuranceIntWrapper.integrations;
    this.edocsIntegrations = edocIntWrapper.integrations;
  }

  createInsuranceIntegrationForm() {
    this.integrationForm = this.formBuilder.array(this.insuranceIntegrations?.map(elm => this.insuranceIntFormBuilder(elm)));
  }

  createEdocsIntegrationForm() {
    this.edocIntegrationForm = this.formBuilder.array(this.edocsIntegrations?.map(elm => this.edocsIntFormBuilder(elm)));
  }

  edocsIntFormBuilder(elm) {
    elm.provider.settings.rates.forEach(rate => {
      const totalPrice = Number(rate.provider_price.amount) + Number(rate.margin.amount);
      rate.maxDiscount = rate.margin.amount * 100 / totalPrice;
    });

    const discount: any = {};
    elm.documentsAvailable = elm.provider.settings.rates.map(el => el.document_type);

    elm.documentsAvailable.forEach(doc => {
      const maxDiscountAvaiable = elm.provider.settings.rates.find(el => el.document_type === doc).maxDiscount;
      discount[doc] = [elm?.settings?.discount[doc] || '0', [Validators.required, Validators.max(maxDiscountAvaiable)]];
    });

    const formgroup = this.formBuilder.group({
      id: [elm?._id, []],
      enabled: [elm.enabled, [Validators.required]],
      display_name: [elm.provider.display_name, []],
      contact_email: [elm.provider.settings.provider_contact.contact_email, []],
      contact_phone: [elm.provider.settings.provider_contact.contact_phone, []],
      settings: this.formBuilder.group({
        documentsAvailable: [elm.documentsAvailable, []],
        discount: this.formBuilder.group(discount),
        api_key: [elm?.settings?.api_key, []],
      }),
    });

    return formgroup;
  }

  insuranceIntFormBuilder(elm) {
    return this.formBuilder.group({
      provider: this.formBuilder.group({
        display_name: [elm.provider?.display_name, [Validators.required]],
        settings: this.formBuilder.group({
          brokerage_contact: this.formBuilder.group({
            contact_email: [elm.provider?.settings?.brokerage_contact?.contact_email, Validators.required],
            contact_phone: [elm.provider?.settings?.brokerage_contact?.contact_phone, Validators.required],
          }),
        }),
      }),
      enabled: [elm.enabled, [Validators.required]],
      settings: this.formBuilder.group({
        discount: [{ value: elm.settings?.discount, disabled: !elm.provider?.settings?.allows_discount }, [Validators.required]],
      }),
      _id: [elm._id],
    });
  }

  changeEnabled(control) {
    control.setValue(!control.value);
    control.markAsTouched();
  }

  saveIntegration() {
    const integrationTouched = this.integrationForm.controls.filter(elm => elm.touched).map(elm => elm.value);
    integrationTouched.forEach(elm => {
      this.apiService.updateIntegration(elm._id, elm).subscribe(res => {
        this.apiService.log('Insurance TPP saved successfully');
      });
    });
  }

  editEdocIntegrations() {
    this.edocIntegrationForm.markAllAsTouched();
    if (this.edocIntegrationForm.invalid) return this.apiService.logError('Some fields has errors');

    if (this.edocIntegrationForm.valid) {
      const integrationsTouched = this.edocIntegrationForm.controls.filter(elm => elm.touched).map(elm => elm.value);
      integrationsTouched.forEach(elm => {
        delete elm.settings.documentsAvailable; // borro este array que necesitaba para montar el form
        this.apiService.updateIntegration(elm.id, elm).subscribe(res => {
          this.apiService.log('Edocs TPP saved successfully');
        });
      });
    }
  }

  getCompanyDetail() {
    this.apiService.getCompanyDetail(this.companyId).subscribe(res => this.company = res);
  }

  async getProvider() {
    const res = await this.apiService.getProvider(this.companyId).toPromise();
    this.providers = res.providers;
    this.provider = this.providers.find(elm => elm.service === 'INSURANCE');
  }

}
