<div class="input-group my-2 d-flex justify-content-between">
  <span class="input-group-addon addon-inline input-source-observer">{{conversion[units]}}</span>
  <div class="d-flex justify-content-between p-1">
    <span class="input-group-btn d-flex align-items-center justify-content-center">
    <mat-icon class="text-primary-color pointer" (click)="changeValue(-1)">remove_circle</mat-icon>
  </span>
    <input OnlyNumbers [ngClass]="{'bg-light-grey': value === null}" #incrementer (ngModelChange)="onChange($event)" [ngModel]="value" type="text" class="form-control" min="0">
    <span class="input-group-btn d-flex align-items-center justify-content-center">
        <mat-icon class="text-primary-color pointer" (click)="changeValue(1)">add_circle</mat-icon>
  </span>
  </div>
</div>
