import { Component, Input, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  @Input() disabled = true;
  selectedIndex = 0;
  selectedSubIndex = 0;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(({ section, subsection }) => {
      if (section) this.selectedIndex = section;
      if (subsection) this.selectedSubIndex = subsection;
    });
  }

  async changeURL(event: { section?: MatTabChangeEvent, subsection?: MatTabChangeEvent }) {
    const query: any = {};
    if (event.subsection) query.subsection = event.subsection.index;
    if (event.section) query.section = event.section.index;
    await this.router.navigate(['/'], { queryParams: query, queryParamsHandling: 'merge', skipLocationChange: false, replaceUrl: true });
  }
}
