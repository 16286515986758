import { Component, OnInit } from '@angular/core';
import { noop } from 'rxjs';
import { PushBOService } from '../../services/pushBO.service';
import { ApiCommonService } from '../../../../common/services/apiCommon.service';
import { EventCommonService } from '../../../../common/services/eventCommon.service';
import { User, Notification } from '../../../../common/models/app.models';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsTableBOComponent } from '../notifications-bo/notifications-table-bo.component';
import { Helper } from '../../../../front/src/app/helper';
import { CacheService } from '../../services/cache.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications-bar-bo',
  templateUrl: './notifications-bar-bo.component.html',
  styleUrls: ['./notifications-bar-bo.component.scss'],
})
export class NotificationsBarBOComponent implements OnInit {

  notifications: Notification[] = [];
  notificationsUnread = 0;
  allNotifications = [];
  maxNotifications = 5;

  params = {};
  user: User;

  constructor(private apiService: ApiCommonService, private pushService: PushBOService, private router: Router, private eventService: EventCommonService, private cacheService: CacheService, private dialog: MatDialog) { }

  async ngOnInit() {
    this.user = await this.cacheService.getUser();
    if (this.user) this.getNotifications();

    this.eventService.login.subscribe(res => { if (res) this.getNotifications(); });
    this.eventService.updateNotifications.subscribe(() => { this.getNotifications(); });
    this.pushService.updateNotifications.subscribe(() => { this.getNotifications(); });
  }

  getNotifications() {
    this.apiService.getNotifications(0, 10).subscribe(res => {
      this.notificationsUnread = res.total_unread;
      this.allNotifications = res.notifications;
      this.allNotifications.forEach(e => { e.url = Helper.buildURLFromActionBO(e.action, e.params); });
      this.notifications = this.allNotifications.slice(0, this.maxNotifications);
    });
  }

  markAsReaded(notification: Notification, read?: boolean) {
    this.apiService.markAsReadedNotification(notification._id, read || !notification.read).subscribe(res => {
      if (!notification.read) this.notificationsUnread--;
      notification.read = res.read;
    });
  }

  markAllAsRead() {
    this.apiService.markAllAsRead().subscribe(noop);
    this.allNotifications.forEach(el => el.read = true);
    this.notificationsUnread = 0;
  }

  openAllNotificationsModal() {
    this.dialog.open(NotificationsTableBOComponent, { autoFocus: false });
  }

  async redirectTo(url) {
    const queryObject = Helper.parseQuery(url);
    // esto genera evento de cambio de ruta por lo que el componente entra por el activatedRoute y carga la vista en funcion de los query que recibe por url
    await this.router.navigate(['/'], { queryParams: queryObject });
  }

}
