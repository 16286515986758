export enum PLANS {MONTHLY = 'MONTHLY', QUARTERLY = 'QUARTERLY', SEMIANNUAL = 'SEMIANNUAL', DEMO = 'DEMO', ANNUAL = 'ANNUAL', FREEMIUM = 'FREEMIUM', WOOD = 'WOOD', SAND = 'SAND', PROMO_SUB = 'PROMO_SUB'}

export type PROMOTIONAL_CODES = 'ENT' | 'ENTCOLAB' | 'PROMOSUB' | 'CUSTOMSPROMO';

export enum PROMOTIONAL_PLANS {ENT = 'ENTERPRISE', ENTCOLAB = 'COLLABORATOR', PROMOSUB = 'PROMO_SUB', CUSTOMSPROMO = 'CUSTOMS_PROMO'}

export enum CURRENYICONS { EUR = 'euro_symbol', USD = 'attach_money'}

export class Plan {
  id?: string;
  base_plan_id: string;
  price?: Amount;
  selected?: boolean;
  products?: [
    {
      id: string;
      included?: number;
      limit: number;
      extra_price?: Amount;
    }
  ];
  time_limit: Date;
  is_custom: boolean;
  is_modified: boolean;
  is_free: boolean;
  is_code_restricted: boolean;
}

export class Amount {
  amount: string;
  currency: string;
}

export class PlansWrapper {
  plans: Plan[];
}

export class Address {
  city?: string;
  country?: string;
  line1?: string;
  line2?: string;
  postal_code?: number;
  state?: string;

  constructor(address) {
    this.city = address.city;
    this.country = address.country;
    this.line1 = address.line1;
    this.line2 = address.line2;
    this.postal_code = address.postal_code;
    this.state = address.state;
  }
}

export class BillingInfo {
  method?: string;
  card_token?: string;
  name?: string;
  phone?: number;
  email?: string;
  address?: Address;
  card_last_four?: number;
  base_plan_id?: string;
  tax_id: string;

  constructor(data) {
    this.method = data.method;
    this.card_token = data.card_token;
    this.name = data.card_token;
    this.phone = data.phone;
    this.email = data.email;
    this.base_plan_id = data.base_plan_id;
    this.address = new Address(data.address ? data.address : {});
  }
}

export class UsageWrapper {
  usage: Usage;
}

export class Usage {
  RECORDS?: ProductUsage;
  MEMBERS?: ProductUsage;
  STORAGE?: ProductUsage;
}

export class ProductUsage {
  current?: number;
  effective?: number;
  excess?: number;
  included?: number;
  limit?: number;
}

export class InvoicesWrapper {
  invoices: Invoice[];
}

export class Invoice {
  created_at: Date;
  status: string;
  amount: number;
  currency: string;
  period_start: Date;
  period_end: Date;
  is_paid: true;
  download_link: string;
}

export class SubscriptionWrapper {
  current_subscription: Subscription;
  next_subscription: Subscription;
}

export class Subscription {
  company?: string;
  enabled?: true;
  plan?: Plan;
  start_at?: Date;
  max_end_at?: Date;
  created_at?: Date;
  updated_at?: Date;
  days_left?: number;
}
