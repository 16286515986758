import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginBOComponent } from './login/login-b-o.component';
import { AuthGuard } from './auth.guard';
import { ProfileComponent } from './profile/profile.component';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { NotFoundComponent } from 'projects/common/components/not-found/not-found.component';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  // { path: '', component: CompaniesListComponent, canActivate: [AuthGuard] },
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginBOComponent },
  { path: 'company-detail/:id', component: CompanyDetailComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  // { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  // { path: 'create-company', component: CreateCompanyComponent, canActivate: [AuthGuard] },
  { path: '**', component: NotFoundComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
