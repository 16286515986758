<div *ngIf="view === 'list'">
  <table mat-table [dataSource]="dataSource" class="py-3 border w-100 p-4 mat-elevation-z8">
    <ng-container matColumnDef="record">
      <th mat-header-cell *matHeaderCellDef class="text-center">{{'column.record' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="text-center pl-0 pr-2">
        <div class="d-flex justify-content-center">
          <mat-icon class="icon-sm mr-2 pointer" [ngClass]="{'invisible': element.read}" (click)="checkRead(element)">check_circle</mat-icon>
          <b (click)="openOfferDetailModal(element)">{{element.record.sequence}}</b>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="provider">
      <th mat-header-cell *matHeaderCellDef>Customer / <br/>Provider</th>
      <td mat-cell *matCellDef="let element" (click)="openOfferDetailModal(element)">
        <div class="font-s font-weight-bold">{{element.record_fields?.actors?.creator?.name}}</div>
        <div class="font-s">{{element.provider ? element.provider?.display_name : '---------'}}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>Price</th>
      <td mat-cell *matCellDef="let element" (click)="openOfferDetailModal(element)">
        <div *ngIf="element.offered_client_price" class="font-weight-bold font-xs">{{element.offered_client_price?.amount * element.exchange_rate | number: '1.2-2'}} EUR</div>
        <div *ngIf="!element.offered_client_price">---------</div>
        <div class="font-xs">{{element?.rate?.client_price_percent ? (element?.rate?.client_price_percent + '%') : '---------'}}</div>
      </td>

    </ng-container>

    <ng-container matColumnDef="avaliableOn">
      <th mat-header-cell *matHeaderCellDef>Avaliable On</th>
      <td mat-cell *matCellDef="let element" (click)="openOfferDetailModal(element)">
        <div class="font-s">{{element.created_at | date: 'shortDate'}}</div>
        <div class="font-s">{{element.created_at | date: 'shortTime'}}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>
        <div class="d-flex justify-content-start align-items-center ml-2">
          {{'column.state' | translate}}
          <app-info-hover [sentence]="'helper.infoState' | translate" [size]="'m'"></app-info-hover>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" (click)="openOfferDetailModal(element)">
        <div class="d-flex justify-content-start align-items-center ml-2">
          <div *ngIf="element.status !== INSURANCE_OFFER_STATUS.PENDING">
            <div [ngClass]="[element.status]">
              {{('common.insurance_status.' + element.status)| translate}}</div>
            <div class="font-date-s">
              {{element.admin_accepted_at || element.client_accepted_at || element.provider_accepted_at || element.expires_at || element.paid_at | date: 'short'}}
            </div>
          </div>
          <button *ngIf="element.status === INSURANCE_OFFER_STATUS.PENDING" (click)="prepareOffer(element);view = 'makeOffer'" mat-flat-button color="primary" class="font-s"> {{'buttons.make_offer' | translate}}</button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="offerDate">
      <th mat-header-cell *matHeaderCellDef>Insurance project date</th>
      <td mat-cell *matCellDef="let element" (click)="openOfferDetailModal(element)">
        <div class="font-s"> {{element.admin_accepted_at ? (element.admin_accepted_at | date: 'shortDate') : '---------'}}</div>
        <div class="font-s"> {{element.admin_accepted_at ? (element.admin_accepted_at | date: 'shortTime') : '---------'}}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="expiresOn">
      <th mat-header-cell *matHeaderCellDef>Expires On</th>
      <td mat-cell *matCellDef="let element" class="font-s" (click)="openOfferDetailModal(element)">{{element.expires_at | date: 'shortDate'}}</td>
    </ng-container>

    <ng-container matColumnDef="offer">
      <th mat-header-cell *matHeaderCellDef class="text-center pr-0">Insurance project #</th>
      <td mat-cell *matCellDef="let element" class="text-center pr-0" (click)="openOfferDetailModal(element)">{{element._id| slice:element._id.length - 5 : element._id.length}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="p-3 pointer" *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'bg-noRead': !row.read}"></tr>
  </table>

  <mat-paginator #paginator (page)="getNext($event)" [pageSizeOptions]="[10, 50, 100]" [length]="totalOffers" [pageSize]="pageSize" [hidePageSize]="false" [ngClass]="{'d-none': !dataSource?.data.length}" showFirstLastButtons class="my-4 recordListPaginator"></mat-paginator>

</div>

<div *ngIf="view === 'makeOffer'" class="w-100 py-4">
  <mat-card class="border mat-elevation-z8">
    <div class="d-flex mb-2 align-items-center" (click)="closeDetail(); view = 'list'">
      <mat-icon color="primary" class="pointer icon-l border-right">arrow_back</mat-icon>
      <div class="pointer ml-2 text-primary-color">back</div>
    </div>

    <div class="d-flex">
      <app-shipment-data-insurance class="offer h-100" [insuranceOffer]="insurance"></app-shipment-data-insurance>
      <div class="d-block ml-4 choose-offer">
        <div class="font-weight-bold font-s mb-2">Choose Provider:</div>
        <mat-form-field class="w-100">
          <mat-label class="font-s">Provider</mat-label>
          <mat-select>
            <div *ngFor="let integration of filteredIntegrations">
              <div *ngIf="integration.enabled">
                <mat-option [value]="integration" (click)="selectedProvider = integration; selectedRate = null">{{integration?.provider?.display_name}}</mat-option>
              </div>
            </div>
          </mat-select>
        </mat-form-field>

        <div *ngIf="selectedProvider">
          <div class="font-weight-bold font-s mb-2">Choose rate for insurance project:</div>
          <mat-card class="row m-2 p-0 d-flex justify-content-center overflow">
            <div *ngFor="let rate of selectedProvider.provider.settings.rates" class="d-flex">
              <div *ngIf="client === 'FORWARDER' && rate.forwarder" class="m-2 p-0 w-100 col-12 col-lg-10 max-card">
                <div (click)="createFormDiscount(selectedProvider, rate); finalPrice(rate, selectedProvider);" [ngClass]="{'bg-rate': rate?.selected, 'choose': !rate?.selected}" class="pointer px-2 py-2">
                  <div class="d-flex row justify-content-around align-items-center">
                    <div class="col-8 font-s text-primary-color wrap font-weight-bold">{{rate.title}}</div>
                    <div class="col-4 font-m font-weight-bold d-flex justify-content-end">{{rate.client_price_percent}}%</div>
                  </div>
                  <div class="d-flex row justify-content-around align-items-center">
                    <div class="col-6 font-xs wrap">{{rate.description}}</div>
                    <div class="col-6 font-xs d-flex justify-content-end">{{partialPrice(rate) | number: '1.2-2'}} {{insurance?.insurable_value?.currency}}</div>
                  </div>
                </div>
              </div>

              <div *ngIf="client !== 'FORWARDER' && !rate.forwarder" class="m-2 p-0 w-100 col-12 col-lg-10 max-card">
                <div (click)="createFormDiscount(selectedProvider, rate); finalPrice(rate, selectedProvider)" [ngClass]="{'bg-rate': rate?.selected, 'choose': !rate?.selected}" class="pointer px-4 py-2">
                  <div class="d-flex row justify-content-around align-items-center">
                    <div class="col-8 font-s text-primary-color wrap font-weight-bold">{{rate.title}}</div>
                    <div class="col-4 font-m font-weight-bold d-flex justify-content-end">{{rate.client_price_percent}}%</div>
                  </div>
                  <div class="d-flex row justify-content-around align-items-center">
                    <div class="col-6 font-xs wrap">{{rate.description}}</div>
                    <div class="col-6 font-xs d-flex justify-content-end">{{partialPrice(rate) | number: '1.2-2'}} {{insurance?.insurable_value?.currency}}</div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
        </div>

        <div *ngIf="selectedRate">
          <div class="font-weight-bold font-s mb-2 mt-2">Chosen rate:</div>
          <div class="choose-hover py-2">
            <div class="d-flex row justify-content-around align-items-center">
              <div class="d-flex col-5 align-items-center">
                <div class="font-s text-primary-color wrap input-w">{{selectedRate.title}}</div>
              </div>
              <div class="d-flex align-items-center">
                <div class="col-4 font-m font-weight-bold">{{selectedRate.client_price_percent}}%</div>
                <div class="col-8 font-xs">{{partialPrice(selectedRate) | number: '1.2-2'}} {{insurance?.insurable_value?.currency}}</div>
              </div>
            </div>
          </div>

          <div class="row d-flex mt-2">
            <div *ngIf="discountForm" class="col-6 d-flex align-items-center">
              <div class="font-weight-bold font-xs mr-1">Applied discount:</div>
              <app-info-hover [sentence]="'This discount will apply only to this particular project.'" [size]="'sm'"></app-info-hover>
              <input (keyup)="finalPrice(selectedRate, selectedProvider)" min="0" numeric decimals="5" matInput class="discount-input" [maxLength]="5" [formControl]="discountForm.get('discount')"><span class="font-xs">%</span>

            </div>
            <div class="col-6 d-flex align-items-center">
              <div class="font-weight-bold font-xs">Final price: <span *ngIf="!rateMinimumPrice" class="total">  {{totalPrice | number: '1.2-2'}} {{insurance?.insurable_value?.currency}} </span>
                <span *ngIf="rateMinimumPrice" class="minimum">  ({{totalPrice | number: '1.2-2'}} {{insurance?.insurable_value?.currency}}) </span> <br/>
                <span *ngIf="rateMinimumPrice" class="font-weight-bold font-xs text-primary-color"> {{selectedProvider.provider.settings.minimum_price?.amount | number: '1.2-2'}} {{selectedProvider.provider.settings.minimum_price?.currency}}</span></div>
            </div>

            <div *ngIf="discountForm.get('discount').errors?.discountBiggerThanProviderMargin" class="d-flex col-12 mt-2">
              <mat-icon class="warming icon-sm mr-1">warning</mat-icon>
              <div class="font-xs warming">The discount cannot be greater than the provider percentage</div>
            </div>
            <div *ngIf="rateMinimumPrice" class="d-flex col-12 mt-2">
              <mat-icon class="warming icon-sm mr-1">warning</mat-icon>
              <div class="font-xs warming">This rate does not reach the insurer's minimum price, <br/> so it cannot be launched.</div>
            </div>
          </div>

          <div class="position-relative mt-2">
            <div class="d-flex justify-content-center mt-2">
              <button (click)="openRejectOffer()" mat-stroked-button color="primary" class="mr-4 px-4">Discard</button>
              <button mat-flat-button color="primary" (click)="acceptInsuranceOffer(selectedProvider, selectedRate)" class="px-4" [disabled]="rateMinimumPrice">Make insurance project</button>
            </div>

            <div *ngIf="showRejectForm && rejectInsuranceOfferForm" [formGroup]="rejectInsuranceOfferForm" class="reject-options">
              <div (click)="closeRejectForm()" class="close pointer">X</div>
              <mat-radio-group [formControl]="rejectInsuranceOfferForm.get('reason.code')" aria-label="Select an option" class="font-s d-flex flex-column">
                <mat-radio-button class="mt-2" value="MISSING_INFO">Missing information</mat-radio-button>
                <mat-radio-button class="mt-2" value="WRONG_INFO">Wrong information</mat-radio-button>
                <mat-radio-button class="mt-2" value="DANGEROUS">Dangerous goods</mat-radio-button>
                <mat-radio-button class="mt-2" value="DEFAULTER">Risk customer</mat-radio-button>
                <mat-radio-button class="mt-2" value="UNINSURABLE">Uninsurable country</mat-radio-button>
                <mat-radio-button class="mt-2" value="MINIMUM_PRICE">Does not meet minimum price</mat-radio-button>
                <mat-radio-button class="mt-2" value="OTHER">Another reason:
                  <div style="font-size: 1px; display: inline; margin-left: 4px">
                    <mat-form-field class="choose-offer">
                      <mat-label></mat-label>
                      <input [formControl]="rejectInsuranceOfferForm.get('reason.text')" matInput>
                      <app-control-messages [control]="rejectInsuranceOfferForm.get('reason.text')"></app-control-messages>
                    </mat-form-field>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
              <button (click)="adminRejectInsuranceOffer()" mat-stroked-button color="primary" class="ml-2 mt-2">Enviar</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
