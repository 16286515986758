import { Component, Input, OnInit } from '@angular/core';
import { PLANS, Usage } from '../../models/billing.models';
import { ApiCommonService } from '../../services/apiCommon.service';
import { EventCommonService } from '../../services/eventCommon.service';
import { ChargesWrapper } from '../../models/charges.models';
import { SERVICES } from '../../models/app.models';

@Component({
  selector: 'app-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.scss'],
})
export class UsageComponent implements OnInit {
  Object = Object;
  SERVICES = Object.values(SERVICES);
  @Input() company;
  @Input() subscription;
  usage: Usage;
  charges: ChargesWrapper;
  PLANS = PLANS;

  constructor(private apiService: ApiCommonService, public eventService: EventCommonService) { }

  ngOnInit() {
    if (this.company && this.company.state === 'ACCEPTED') {
      this.getUsage();
      this.getInsuranceUsage();
    }
  }

  getUsage() {
    this.apiService.getCompanyUsage(this.company._id).subscribe(res => {
      this.usage = res.usage;
      this.usage.STORAGE.current = Math.round(this.usage.STORAGE.current * 100) / 100;
    });
  }

  getInsuranceUsage() {
    this.apiService.getCompanyCharges(this.company._id).subscribe(res => this.charges = res);
  }

}
