import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CacheService } from '../../services/cache.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { environment } from '../../environments/environment';
import { ApiCommonService } from '../../../../common/services/apiCommon.service';
import { CustomValidators } from '../../../../common/shared/custom-validators';
import { EventCommonService } from '../../../../common/services/eventCommon.service';

@Component({
  selector: 'app-login',
  templateUrl: './login-b-o.component.html',
  styleUrls: ['./login-b-o.component.scss'],
})
export class LoginBOComponent implements OnInit {

  form: FormGroup;
  changePasswordForm: FormGroup;
  recovery = false;
  hide = false;
  recoveryToken;

  constructor(private apiService: ApiCommonService, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private cacheService: CacheService, private eventService: EventCommonService, private router: Router, private dialog: MatDialog, private reCaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit() {
    this.cacheService.clear();
    this.activatedRoute.queryParams.subscribe(params => {
      if (!params.recovery) {
        this.createLoginForm();
      } else {
        this.recovery = true;
        this.recoveryToken = params.token;
        this.createRecoveryForm();
      }
    });
  }

  createLoginForm() {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email, Validators.minLength(4)]],
      password: [null, [Validators.required]],
    });
  }

  createRecoveryForm() {
    this.changePasswordForm = this.formBuilder.group({
        password: ['', [Validators.required, Validators.minLength(4)]],
        repeatPassword: ['', [Validators.required, Validators.minLength(4)]],
      },
      {
        validator: CustomValidators.matchPassword,
      });
  }

  async login() {
    const data = this.form.getRawValue();
    data.captcha_response = await this.reCaptchaV3Service.executeAsPromise(environment.captchaKey, 'login_bo', { useGlobalDomain: true });
    this.apiService.login(data).subscribe(({ user, token }) => {
      if (token) {
        this.cacheService.setUser(user, token);
        this.eventService.login.emit(true);
        this.router.navigate(['/']);
      } else {
        this.form.setErrors({ wrongCredentials: true });
      }
    }, () => this.form.setErrors({ wrongCredentials: true }));
  }

  changePassword() {
    const data = { password: this.changePasswordForm.get('repeatPassword').value };
    this.apiService.changePassword(data, this.recoveryToken).subscribe(res => {
      this.apiService.log('Your password has been changed');
      this.recovery = false;
      this.router.navigate(['/login']);
    });
  }

  openRecoveryPassword() {
    // tslint:disable-next-line:no-use-before-declare
    this.dialog.open(ModalRecoveryPasswordComponent);
  }
}

@Component({
  selector: 'app-modal-recovery-password',
  templateUrl: './modal-recovery-password.component.html',
})
export class ModalRecoveryPasswordComponent implements OnInit {
  recoveryForm: FormGroup;
  recoverySended = false;

  constructor(private modalRecoveryPassword: MatDialogRef<ModalRecoveryPasswordComponent>, private formBuilder: FormBuilder, private apiService: ApiCommonService, private reCaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit() {
    this.recoveryForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  async sendEmail() {
    const data = this.recoveryForm.getRawValue();
    data.captcha_response = await this.reCaptchaV3Service.executeAsPromise(environment.captchaKey, 'change_password_bo', { useGlobalDomain: true });
    this.apiService.requestRecoveryEmail(data).subscribe(() => {
      this.recoverySended = true;
    });
  }

  closeModal() {
    this.modalRecoveryPassword.close();
  }
}
