export enum ICONS {
  read = 'remove_red_eye',
  update = 'edit',
  invite = 'group_add',
  create = 'backup',
  air = 'flight',
  road = 'local_shipping',
  maritim = 'directions_boat',
  railway = 'directions_railway',
}

export enum CURRENYICONS {
  EUR = 'euro_symbol',
  USD = 'attach_money',
}

export enum INVOICE_STATE_ICONS {
  PAID= 'done',
  VOID = 'clear',
  PENDING = 'alarm',
}
