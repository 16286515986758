<div class="">
  <div>
    <span class="font-weight-bold">{{'common.text.shipInfo' | translate}}</span> <span class="ml-3 font-s text-light-grey">{{'common.text.data' | translate}}</span>

    <div class="card-header radius p-2 mt-3 d-flex flex-column">
      <div class="d-flex row">
        <span class="col-5 font-weight-bold font-l mr-4">{{insuranceOffer?.record?.sequence}}</span>
        <span class="col-5 ml-2 text-light-grey">{{insuranceOffer?.client_company?.name}}</span>
      </div>
      <div class="row text-primary-color d-flex align-items-center">
        <span class="col-5 mr-4 font-m">Insurance project #{{insuranceOffer?._id.substr(insuranceOffer?._id.length - 5)}}</span>
        <div *ngIf="insuranceOffer" class="d-flex">
          <mat-icon class="col-5 material-icons-outlined mr-2">account_circle</mat-icon>
          <div *ngFor="let actor of insuranceOffer.calculated_fields.creator_actors; let last = last">
            <span *ngIf="actor !== 'creator'">{{actor.toUpperCase() === ROLES.FWD || actor.toUpperCase() === ROLES.FWO ? actor.toUpperCase() : actor.charAt(0).toUpperCase() + actor.slice(1)}}<span *ngIf="!last">/</span></span>
          </div>
        </div>
      </div>
    </div>
    <div class="border font-s">
      <div class="row d-flex">
        <div class="col-6">
          <div class="py-2 ml-2">
            <span class="font-weight-bold">{{'common.text.invoice_value' | translate}}:</span><span> {{insuranceOffer?.record_fields?.invoice_value | currency: insuranceOffer?.record_fields.invoice_currency : 'code'}}</span>
          </div>
          <div *ngIf="insuranceOffer?.record_fields?.ports">
            <span *ngFor="let port of Object.keys(insuranceOffer?.record_fields?.ports)" class="">
              <div *ngIf="port === 'shipment'" class="ml-2 py-2">
                <span class="font-weight-bold">{{('common.ports.' + port) | translate}}/{{'common.text.origin' | translate}}: </span><span>{{countries[insuranceOffer?.record_fields?.ports[port].country]?.name.toUpperCase()}} / {{insuranceOffer?.record_fields?.ports[port].country}}</span>
              </div>
              <div *ngIf="port === 'unload'" class="ml-2 py-2">
                <span class="font-weight-bold">{{('common.ports.' + port) | translate}}/{{'common.text.destiny' | translate}}: </span><span>{{countries[insuranceOffer?.record_fields?.ports[port].country]?.name.toUpperCase()}} / {{insuranceOffer?.record_fields?.ports[port].country}}</span>
              </div>
            </span>
          </div>
          <div class="py-2 ml-2">
            <span class="font-weight-bold">{{'common.insurance.latest' | translate}}:</span><span> {{insuranceOffer?.record_fields?.last_shipped | date: 'shortDate' : '+0000'}}</span>
          </div>
        </div>
        <div class="col-6">
          <div>
            <div class="py-2">
              <span class="font-weight-bold">{{'common.text.insurance_value' | translate}}: </span><span> {{insuranceOffer?.record_fields?.invoice_value * 1.1 | currency: insuranceOffer?.record_fields.invoice_currency : 'code'}} </span>
            </div>
          </div>
          <div class="d-flex">
            <div class="py-2">
              <span class="font-weight-bold">{{'common.insurance.range' | translate}}:</span><span> {{insuranceOffer?.calculated_fields?.shipping_type}}</span>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="py-2 d-flex align-items-center">
              <span class="font-weight-bold mr-1">{{'common.insurance.frozen' | translate}}: </span>
              <span [ngSwitch]="insuranceOffer?.calculated_fields?.refrigeration_type">
                <span *ngSwitchCase="'UNKNOWN'" class="d-flex align-items-center refrigeration"><span>UNKNOWN</span></span>
                <span *ngSwitchCase="'UNREFRIGERATED'" class="d-flex align-items-center"><span>NONE</span></span>
                <span *ngSwitchCase="'FROZEN'" class="d-flex align-items-center refrigeration"><span>FROZEN</span><mat-icon class="icon-s refrigeration">ac_unit</mat-icon></span>
                <span *ngSwitchCase="'REFRIGERATED'" class="d-flex align-items-center refrigeration"><span>REFRIGERATED</span><mat-icon class="icon-s refrigeration">kitchen</mat-icon></span>
              </span>
            </div>
          </div>
          <div>
            <div class="py-2">
              <span class="font-weight-bold">{{'common.insurance.incoterm' | translate}}:</span><span> {{insuranceOffer?.record_fields?.incoterm}}</span>
            </div>
          </div>
        </div>
      </div>

      <div *ngFor="let hs of insuranceOffer?.record_fields?.goods; let index = index" class="grey-box p-2 mt-2 mx-2">
        <div class="d-flex">
          <div class="d-flex justify-content-center align-items-center circle mr-2">
            <div class="number">{{index + 1}}</div>
          </div>
          <div>
            <div><span class="font-weight-bold mr-2">{{'common.insurance.hsCode' | translate}}:</span> <span>{{hs.code}}</span></div>
            <div><span class="font-weight-bold mr-2">{{'common.insurance.hsDescription' | translate}}:</span> <span>{{hs.description}}</span></div>
          </div>
        </div>
      </div>

      <div *ngIf="insuranceOffer">

        <div class="p-2 mt-2 mx-2">
          <span [ngSwitch]="insuranceOffer?.record_fields?.transport_type">
            <span *ngSwitchCase="'road'"  class="d-flex align-items-end mb-1"><mat-icon class="mr-1">local_shipping</mat-icon><span class="font-weight-bold">{{'common.insurance.points' | translate}}</span></span>
            <span *ngSwitchCase="'railway'" class="d-flex align-items-end mb-1"><mat-icon class="mr-1">directions_railway</mat-icon><span class="font-weight-bold">{{'common.insurance.points' | translate}}</span></span>
            <span *ngSwitchCase="'air'" class="d-flex align-items-end mb-1"><mat-icon class="mr-1">flight</mat-icon><span class="font-weight-bold">{{'common.insurance.airports' | translate}}</span></span>
            <span *ngSwitchCase="'maritim'" class="d-flex align-items-end mb-1"><mat-icon class="mr-1">directions_boat</mat-icon><span class="font-weight-bold">{{'common.insurance.port' | translate}}</span></span>
          </span>

          <div class="d-flex flex-wrap">
            <div *ngFor="let port of Object.keys(insuranceOffer?.record_fields?.ports)">
              <div class="port-card p-2 d flex align-items-center justify-content-center border w-100">
                <div class="font-weight-bold font-s">{{('common.ports.' + port) | translate}}</div>
                <div>{{'common.insurance.country' | translate}}:</div>
                <div>{{insuranceOffer?.record_fields?.ports[port].country}} - {{countries[insuranceOffer?.record_fields?.ports[port].country].name}}</div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
