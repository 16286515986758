<div class="container">
  <div class="row">
    <div class="col-6 breadcrumb" *ngIf="userHasNotifications">
      <span class="font-m">{{'notifications.totalNotifications' | translate : { total: totalNotifications } }}</span><span class="previous font-m" *ngIf="notificationsUnread > 0"> <span>{{'notifications.unreadNotification' | translate: { unreadTotal: notificationsUnread } }} </span> </span>
    </div>
    <div class="col-6 d-flex justify-content-end mb-3" *ngIf="userHasNotifications">
      <button type="button" mat-flat-button color="primary" title="{{'notifications.markAllAsRead' | translate}}" (click)="markAllAsRead()">
        <mat-icon>close</mat-icon>
        {{'notifications.markAllAsRead' | translate}}
      </button>
    </div>
    <div class="col-12 search-results-table">
      <mat-card class="my-4">
        <mat-card-content *ngIf="dataSource">
          <div *ngIf="!userHasNotifications">
            <div class="clearfix mb-sm"></div>
            <p class="description no-items">
              <mat-icon>error_outline</mat-icon>
              {{'notifications.noNotificatons' | translate}}
            </p>
          </div>
          <table mat-table [dataSource]="dataSource" class="w-100">
            <ng-container matColumnDef="createdAt">
              <td mat-cell *matCellDef="let element" class="p-0">
                <div class="d-flex flex-column align-items-center mx-2">
                  <span class="font-weight-bold">{{element.params.sequence}}</span>
                  <span class="font-xs text-light-grey no-wrap"> {{element.createdAt | date: 'short'}}</span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="icons">
              <td mat-cell *matCellDef="let element">
                <span><img class="icon-notification" [src]="'assets/images/icons/'+element.action +'.svg'" [alt]="element.action+ ' icon'"></span>
              </td>
            </ng-container>


            <ng-container matColumnDef="message">
              <td mat-cell *matCellDef="let element">

                <span [innerHTML]="('notifications.' + element.action) | translate: {
                  actor: (('common.resources.' + element.params.actor) | translate).toLowerCase(),
                  sequence: element.params.sequence,
                  companyName: element.params.companyName,
                  days: element.params.days,
                  user_name: element.params.user_name,
                  provider_name: element.params.provider_name,
                  edocId: element.params.edoc_id | slice:element.params.edoc_id?.length - 5 : element.params.edoc_id?.length,
                  edocType: element.params.edoc_type
                }">

                </span>
                <span *ngIf="element.params.type" class="font-weight-bold">{{('common.resources.document.' + element.params.type) | translate}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="buttons">
              <td mat-cell *matCellDef="let element">
                <div class="d-flex justify-content-start align-items-center">
                  <a *ngIf="element.url" (click)="markAsRead(element, true); closeModal(); redirectTo(element.url)" class="formatted-link mx-1 pointer">{{'actions.read' | translate}}</a>
                  <div class="mx-2">|</div>
                  <a (click)="markAsRead(element, !element.read)" [routerLink]="" class="mx-1 formatted-link no-wrap">{{('notifications.' + (!element.read ? 'markAsRead' : 'markAsUnread')) | translate}}</a>
                </div>
              </td>
            </ng-container>

            <tr mat-row class="border-top border-right border-left" *matRowDef="let row; columns: displayedColumns; let i = index" [ngClass]="{'isNew' : !dataSource.filteredData[i].read}"></tr>
          </table>

          <mat-paginator #notificationsPaginator (page)="getNext($event)" [pageSizeOptions]="[10, 50, 100]" [length]="totalNotifications" [pageSize]="pageSize" [hidePageSize]="false" [ngClass]="{'hide': !userHasNotifications}" showFirstLastButtons></mat-paginator>

        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
