import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { ApiCommonService } from '../../../../common/services/apiCommon.service';
import { Plan, PLANS } from '../../../../common/models/billing.models';
import { Company, File, LEGALTYPES, SERVICES, STATES, User } from '../../../../common/models/app.models';
import { fadeInOut } from '../../../../common/shared/animations';
import { Helper } from '../../../../front/src/app/helper';
import { InsuranceProvider } from '../../../../common/models/providers.models';

@Component({
  selector: 'app-record-detail', templateUrl: './company-detail.component.html', styleUrls: ['./company-detail.component.scss'], animations: [fadeInOut],
})
export class CompanyDetailComponent implements OnInit {

  PLANS = PLANS;
  user: User = { name: '', company: {} };
  files = {};
  form: FormGroup;
  loading = false;
  newCompanyForm: FormGroup;
  company: Company;
  hasDocuments = false;
  STATES = STATES;
  plans: Plan[] = [];
  subscription;
  subscriptionShow;
  legal_types = [];
  timeLimit = 45;
  subscriptionWrapper;
  displayServices = false;
  companId: string;
  providers: any[];
  provider: InsuranceProvider;
  service: string;
  limitForm: FormGroup;
  edocProvider: any;

  icons = {
    RECORDS: 'assignment', STORAGE: 'cloud', MEMBERS: 'group',
  };

  conversion = {
    ACCEPTED: 'ACCEPTED', INCOMPLETED: 'INCOMPLETED', COMPLETED: 'COMPLETED', REJECTED: 'REJECTED', STORAGE: 'GiB', MEMBERS: 'Members', RECORDS: 'month', EUROS: 'euros',
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;

  customPlan;
  billingInfo;
  notifications = [];
  constructor(private formBuilder: FormBuilder, private apiService: ApiCommonService, private activatedRoute: ActivatedRoute, private router: Router) {}

  async ngOnInit() {
    Object.keys(LEGALTYPES).map(el => { this.legal_types.push({ id: el, name: LEGALTYPES[el] }); });

    this.getAvailablePlans();

    // magia arcana: para evitar Afterviewcheckederror de loading del service
    setTimeout(() => {
      this.activatedRoute.params.subscribe(params => {
        this.companId = params.id;
        if (params.id) {
          this.apiService.getCompanyDetail(params.id).subscribe(res => {
            if (!res) {
              this.apiService.logError('Not found');
              this.router.navigate(['/']);
            } else {
              this.company = res;
              this.apiService.getBillingInfo(params.id).subscribe(billingInfo => {
                this.billingInfo = billingInfo;
              });
              this.getSubscription();

              // le añado el indice del array de documentos a cada elemento
              this.company.files.map((el, index) => {
                el.index = index;
              });
              this.checkHasDocuments(res.files);
              this.buildForm(res);
              this.buildLimitForm(res);
            }
          });
          this.getProvider();
        }
      });
    }, 0);

  }

  async getProvider() {
    const res = await this.apiService.getProvider(this.companId).toPromise();
    this.providers = res.providers;
    this.provider = this.providers.find(elm => elm.service === 'INSURANCE');
    this.edocProvider = this.providers.find(elm => elm.service === 'EDOCS');
  }

  changePlanParameters(plan: string, firstCall?) {
    if (plan) {
      if (firstCall && this.subscription) {
        return this.customPlan = this.plans.find(el => el.id === this.subscription.plan.base_plan_id);
      }
      if (!this.subscription) {
        this.customPlan = this.plans.find(el => el.id === plan);
      }
      this.customPlan = this.plans.find(el => el.base_plan_id === plan);
      if (this.customPlan.products.RECORDS) {
        const array = [];
        Object.keys(this.customPlan.products).map(el => {
          this.customPlan.products[el].base_plan_id = el;
          array.push(this.customPlan.products[el]);
        });
        this.customPlan.products = array;
      }

    }
    this.calculateNotifications();
  }
  saveCustomPlan() {
    const deepCopyCustomPlan = JSON.parse(JSON.stringify(this.customPlan));
    deepCopyCustomPlan.base_plan_id = this.newCompanyForm.get('base_plan_id').value !== 'CUSTOM' ? this.newCompanyForm.get('base_plan_id').value : null;

    if (!deepCopyCustomPlan.base_plan_id || (deepCopyCustomPlan.base_plan_id && deepCopyCustomPlan.base_plan_id !== 'WOOD')) delete deepCopyCustomPlan.time_limit;
    delete deepCopyCustomPlan.id;
    if (deepCopyCustomPlan.base_plan_id && deepCopyCustomPlan.base_plan_id === 'WOOD') deepCopyCustomPlan.time_limit = this.timeLimit;
    if (!deepCopyCustomPlan.base_plan_id && deepCopyCustomPlan.is_custom) deepCopyCustomPlan.time_limit = null;
    this.apiService.setSubscription(deepCopyCustomPlan, this.company._id).subscribe(res => {
      if (res) this.apiService.log('Subscription saved!');
      this.subscription = res;
      this.subscriptionShow = res;
      this.getSubscription();
    });

    this.limitForm.markAllAsTouched();
    const servicesLimitsObject = {};
    Object.values(SERVICES).forEach(service => {
      servicesLimitsObject[service] = !this.limitForm.get(service + '.services_amount').value ? null : { amount: this.limitForm.get(service + '.services_amount').value, currency: 'EUR' };
    });

    const data = {
      unpaid_charge_limits: {
        total: null,
        services: servicesLimitsObject,
      },
    };

    this.apiService.editCompany2(this.company._id, data).toPromise();
  }

  getAvailablePlans() {
    this.apiService.getPlans().subscribe(res => {
      this.plans = res.plans;
      if (res) {
        const customPlan = this.subscription ? this.subscription.plan : JSON.parse(JSON.stringify(this.plans[0]));
        customPlan.id = 'CUSTOM';
        customPlan.base_plan_id = 'CUSTOM';
        this.plans.push(customPlan);
      }
    });
  }

  checkHasDocuments(files: File[]) {
    Object.values(files).map(el => {
      if (el[0]) {
        this.hasDocuments = true;
      }
    });
  }

  getSubscription() {
    this.apiService.getSubscription(this.company._id).subscribe(res => {
      this.subscriptionWrapper = res;
      if (res.next_subscription === null && res.current_subscription === null) {
        this.customPlan = null;
        this.newCompanyForm.get('base_plan_id').setValue(this.customPlan ? this.customPlan.id : this.customPlan);
        this.changePlanParameters(this.customPlan ? this.customPlan.id : this.customPlan, true);
        return null;
      }
      this.subscription = res.next_subscription === null ? JSON.parse(JSON.stringify(res.current_subscription)) : JSON.parse(JSON.stringify(res.next_subscription));
      if (this.subscription.plan.time_limit || this.subscription.plan.time_limit === null) { this.timeLimit = this.subscription.plan.time_limit === null ? null : this.subscription.plan.time_limit; }
      this.subscriptionShow = JSON.parse(JSON.stringify(this.subscription));
      this.newCompanyForm.get('base_plan_id').setValue((!!this.subscription && !!this.subscription.plan && !!this.subscription.plan.base_plan_id) ? this.subscription.plan.base_plan_id : 'CUSTOM');

      this.customPlan = this.newCompanyForm.get('base_plan_id').value === 'CUSTOM' ? this.subscription.plan : this.plans.find(el => el.base_plan_id === this.newCompanyForm.get('base_plan_id').value);
      if (this.subscriptionShow) {
        this.customPlan = this.subscriptionShow.plan;
      }
      this.customPlan.price.amount = Number(this.customPlan.price.amount);
      this.getAvailablePlans();

      this.calculateNotifications();
    });
  }

  calculateNotifications() {
    this.notifications = [];
    if (this.company.state === 'ACCEPTED') {
      if (this.subscription?.plan.time_limit) this.notifications.push({ key: 'daysLeftBO', days: Math.round(Helper.getPlanDaysLeft(this.subscription.max_end_at)) });
      if (this.subscription?.plan.base_plan_id === this.newCompanyForm.get('base_plan_id').value && this.subscription?.plan.is_modified) this.notifications.push({ key: 'alreadyModify' });
      if (this.newCompanyForm.get('base_plan_id').value === 'CUSTOM') this.notifications.push({ key: 'customPlan' });
    }
  }

  buildForm(company) {
    this.newCompanyForm = this.formBuilder.group({
      _id: [company._id],
      name: [company.name, [Validators.required, Validators.maxLength(30)]],
      base_plan_id: [this.subscription && this.subscription.plan.base_plan_id ? this.subscription.plan.base_plan_id : null, [Validators.required]],
      address: [company.address, [Validators.required, Validators.maxLength(30)]],
      city: [company.city, [Validators.required, Validators.maxLength(35)]],
      zip_code: [company.zip_code, [Validators.required, Validators.maxLength(9)]],
      country: [company.country, [Validators.required, Validators.maxLength(2)]],
      contact: [company.contact, [Validators.required, Validators.maxLength(60)]],
      phone: [company.phone, [Validators.required, Validators.maxLength(50)]],
      EORI_NIF: [company.EORI_NIF, [Validators.required, Validators.maxLength(25)]],
      REX: [company.REX, [Validators.maxLength(35)]],
      BIC: [company.BIC, [Validators.maxLength(40)]],
      account: [company.account, [Validators.maxLength(40)]],
      filesData: this.formBuilder.group({}),
    });

    this.newCompanyForm.disable();
    this.newCompanyForm.get('base_plan_id').enable();
  }

  buildLimitForm(company) {
    const activeServices = Object.values(SERVICES);
    const object = {};
    activeServices.forEach(service => {
      object[service] = this.formBuilder.group({
        services_amount: [company.unpaid_charge_limits.services[service]?.amount, [Validators.required]],
      });
    });
    this.limitForm = this.formBuilder.group(object);
  }

  changeLegalType(legal_type) {
    if (legal_type || !this.billingInfo.legal_type) {
      this.billingInfo.legal_type = legal_type;
      delete this.billingInfo.method;
      this.apiService.setBillingInfo({ legal_type }, '', this.newCompanyForm.get('_id').value).subscribe(billing => {
        this.billingInfo = billing;
      });
    }
  }

  createProvider() {
    if (this.provider) this.apiService.logWarn('You cannot deactivate this provider. Contact technical support to manage it');
    else this.displayServices = !this.displayServices;
  }

  checkDisable(event) {
    event.checked ? this.limitForm.get('services_amount').disable() : this.limitForm.get('services_amount').enable();
  }
}
