<div class="container border p-2">
  <h3 class="text-center">This company provides the following services</h3>
  <div class="d-flex justify-content-center mt-4 mb-4">
    <button mat-stroked-button color="primary" class="mr-4" (click)="insuranceService = providerForm; edocService = false; insuranceButton = true; edocButton = false" [ngClass]="{'primary': insuranceButton}">Insurances</button>
    <button mat-stroked-button color="primary" class="mr-4" [ngClass]="{'disabled': provider?.service !== 'FINANCING'}">Financing</button>
    <button mat-stroked-button color="primary" (click)="edocService = edocForm; edocButton = true; insuranceService = false; insuranceButton = false" [ngClass]="{'primary': edocButton}">eDocs</button>
  </div>
</div>

<!-- TODO: separar por componentes. Aquí empieza INSURANCE -->
<div *ngIf="insuranceService">
  <form *ngIf="providerForm" [formGroup]="providerForm">


    <h2 class="mt-5 form-header__title d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <div>
          <mat-icon class="mr-2">local_police</mat-icon>
        </div>
        <span>Insurance</span>
      </div>
      <div>
        <mat-checkbox [formControl]="providerForm.get('default_integration.enabled')" class="font-s mb-0 ml-1">Activated for every new client</mat-checkbox>
      </div>
    </h2>

    <div class="d-flex justify-content-end mt-4">
      <button mat-stroked-button color="primary" (click)="activatedCompanies(true)" class="mr-2" [ngClass]="{'disabled': !provider, 'primary': enabledCompanies}">Activated for existing companies</button>
      <button mat-stroked-button color="primary" (click)="activatedCompanies(false)" [ngClass]="{'disabled': !provider, 'primary': provider && !enabledCompanies}">Deactivated for existing companies</button>
    </div>

    <div class="font-weight-bold my-2 mx-4">Rates</div>
    <div class="font-m w-25 mx-4">
      <div class="d-flex align-items-center">
        <mat-form-field>
          <input type="number" min="0" numeric decimals="5" matInput placeholder="{{'common.text.insurances.minimumPrice' | translate}}" [formControl]="providerForm.get('settings.minimum_price_amount')" [maxLength]="5">
          <app-control-messages [control]="providerForm.get('settings.minimum_price_amount')"></app-control-messages>
        </mat-form-field>
        <span class="font-s">EUR</span>
      </div>
      <mat-checkbox [formControl]="providerForm.get('settings.allows_discount')" class="font-s mb-0 ml-1">Allow discounts</mat-checkbox>
    </div>

    <div class="row d-flex mx-1">

      <div *ngFor="let card of getControls(providerForm, 'settings.rates'); let i = index;" class="col-6">
        <mat-card class="basic-card my-4">
          <div class="d-flex justify-content-between font-m">
            <app-input-text placeholder="insurances.rate" [maxLength]="100" [control]="card.get('title')"></app-input-text>
            <mat-icon (click)="deleteRate(i)" class="icon-sm pointer" color="primary">delete</mat-icon>
          </div>
          <div class="d-flex flex-column flex-lg-row">
            <mat-form-field class="w-100 mr-2">
              <input [formControl]="card.get('provider_price_percent')" type="number" min="0" numeric decimals="5" matInput placeholder="{{'common.text.insurances.insurer' | translate}}">
              <app-control-messages [control]="card.get('provider_price_percent')"></app-control-messages>
            </mat-form-field>
            <mat-form-field class="w-100 mr-2">
              <input [formControl]="card.get('client_price_percent')" type="number" min="0" numeric decimals="5" matInput placeholder="{{'common.text.insurances.total' | translate}}">
              <app-control-messages [control]="card.get('client_price_percent')"></app-control-messages>
            </mat-form-field>
          </div>
          <app-input-text placeholder="insurances.description" [maxLength]="100" [control]="card.get('description')"></app-input-text>
          <mat-checkbox class="font-s mb-0 ml-1" [formControl]="card.get('forwarder')">Forwarder</mat-checkbox>
          <div class="d-flex justify-content-between align-items-center">
            <div class="mr-2">Margin Usyncro</div>
            <div class="d-flex justify-content-center align-items-center">
              <mat-form-field class="input-w">
                <input type="number" min="0" numeric decimals="5" matInput [formControl]="card.get('margin_percent')" [maxLength]="5">
                <app-control-messages [control]="card.get('margin_percent')"></app-control-messages>
              </mat-form-field>
              <span>%</span>
            </div>
          </div>
        </mat-card>
      </div>


      <!-- card para añdir tarifa -->
      <div class="col-3">
        <mat-card class="card my-4 pointer" (click)="addRate()">
          <div class="d-flex justify-content-center align-items-center card-center pointer">
            <mat-icon color="primary" class="mr-2">add_circle_outline</mat-icon>
            <mat-card-content class="font-m text-primary-color mb-0">Add rate</mat-card-content>
          </div>
        </mat-card>
      </div>

    </div>

    <h2 class="mt-5 form-header__title d-flex align-items-center">
      <mat-icon class="mr-2">portrait</mat-icon>
      Contact for insurances
    </h2>

    <div class="w-100 d-flex flex-column flex-sm-row ng-star-inserted">
      <div class="d-flex flex-column py-3 w-100 p-4">
        <div class="font-weight-bold">Brokerage contact</div>
        <div class="d-flex align-items-center">
          <mat-icon class="mr-2">domain</mat-icon>
          <app-input-text class="w-100" placeholder="insurances.companyName" [maxLength]="50" [control]="providerForm.get('settings.brokerage_contact.company_name')"></app-input-text>
        </div>
        <div class="d-flex align-items-center">
          <mat-icon class="mr-2">person</mat-icon>
          <app-input-text class="w-100" placeholder="insurances.contactName" [maxLength]="50" [control]="providerForm.get('settings.brokerage_contact.contact_name')"></app-input-text>
        </div>
        <div class="d-flex align-items-center">
          <mat-icon class="mr-2"> mail_outline</mat-icon>
          <app-input-text class="w-100" placeholder="insurances.contactEmail" [maxLength]="60" [control]="providerForm.get('settings.brokerage_contact.contact_email')"></app-input-text>
        </div>
        <div class="d-flex align-items-center">
          <mat-icon class="mr-2"> phone</mat-icon>
          <app-input-text class="w-100" placeholder="insurances.contactPhone" [maxLength]="15" [control]="providerForm.get('settings.brokerage_contact.contact_phone')"></app-input-text>
        </div>
      </div>

      <div class="d-flex flex-column py-3 w-100 p-4">
        <div class="font-weight-bold">Insurance contact</div>
        <div class="d-flex align-items-center">
          <mat-icon class="mr-2">domain</mat-icon>
          <app-input-text class="w-100" placeholder="insurances.companyName" [maxLength]="50" [control]="providerForm.get('settings.insurance_contact.company_name')"></app-input-text>
        </div>
        <div class="d-flex align-items-center">
          <mat-icon class="mr-2">person</mat-icon>
          <app-input-text class="w-100" placeholder="insurances.contactName" [maxLength]="50" [control]="providerForm.get('settings.insurance_contact.contact_name')"></app-input-text>
        </div>
        <div class="d-flex align-items-center">
          <mat-icon class="mr-2"> mail_outline</mat-icon>
          <app-input-text class="w-100" placeholder="insurances.contactEmail" [maxLength]="60" [control]="providerForm.get('settings.insurance_contact.contact_email')"></app-input-text>
        </div>
        <div class="d-flex align-items-center">
          <mat-icon class="mr-2"> phone</mat-icon>
          <app-input-text class="w-100" placeholder="insurances.contactPhone" [maxLength]="15" [control]="providerForm.get('settings.insurance_contact.contact_phone')"></app-input-text>
        </div>
      </div>
    </div>

    <h2 class="mt-5 form-header__title d-flex align-items-center mb-4">
      <mat-icon class="material-icons-outlined mr-2">local_police</mat-icon>
      Insurance conditions
    </h2>
    <app-textarea class="mx-4 white-space" [control]="providerForm.get('settings.conditions_text.es')" [maxLength]="1500" [placeholder]="'insurances.insurancesConditionsEs'"></app-textarea>
    <app-textarea class="mx-4 white-space" [control]="providerForm.get('settings.conditions_text.en')" [maxLength]="1500" [placeholder]="'insurances.insurancesConditions'"></app-textarea>

    <div class="mt-2">
      <div class="font-weight-bold mb-2">Legal Files</div>
      <div class="d-flex mx-4"><span class="font-m text-light-grey">Select the provider to set the legal files (Insurance conditions)</span>
        <div class="ml-2 font-s text-light-grey font-weight-bold">
          <mat-radio-group [formControl]="providerForm.get('settings.terms_and_conditions')">
            <mat-radio-button value="oskar" class="mb-0 ml-1">Oskar Schunk</mat-radio-button>
            <mat-radio-button value="llerandi" class="mb-0 ml-2">Llerandi</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </form>

  <div class="d-flex justify-content-end mt-2">
    <button mat-flat-button color="primary" (click)="addAndEditInsuranceProvider()">Save insurances configuration</button>
  </div>
</div>

<!-- TODO: separar por componentes. Aquí empieza EDOCS -->
<div *ngIf="edocService">
  <div *ngIf="edocForm" [formGroup]="edocForm">
    <h2 class="mt-5 form-header__title d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <div>
          <mat-icon class="material-icons-outlined">insert_drive_file</mat-icon>
        </div>
        <span>eDocs</span>
      </div>
      <div>
        <mat-checkbox [formControl]="edocForm.get('default_integration.enabled')" class="font-s mb-0 ml-1">Activated for every new client</mat-checkbox>
      </div>
    </h2>

    <div class="d-flex justify-content-end mt-4">
      <button mat-stroked-button color="primary" (click)="activatedCompanies(true)" class="mr-2" [ngClass]="{'disabled': !edocProvider, 'primary': enabledCompanies}">Activated for existing companies</button>
      <button mat-stroked-button color="primary" (click)="activatedCompanies(false)" [ngClass]="{'disabled': !edocProvider, 'primary': edocProvider && !enabledCompanies}">Deactivated for existing companies</button>
    </div>

    <mat-form-field class="w-25 mx-4">
      <mat-label class="font-s">Select Api</mat-label>
      <mat-select [formControl]="edocForm.get('settings.implementation')" class="text-capitalize">
        <div>
          <mat-option *ngFor="let implementation of implementations" [value]="implementation.id" class="text-capitalize">{{implementation.id.toLowerCase()}}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>

    <div class="font-weight-bold my-2 mx-4">Type of doc</div>

    <div class="row d-flex mx-1">
      <div *ngFor="let rate of getEdocControls(edocForm, 'settings.rates'); let i = index;" class="col-6">
        <mat-card *ngIf="implementations" class="basic-card my-4">
          <div class="d-flex justify-content-between align-items-center font-m">
            <mat-form-field class="w-100 mr-2">
              <mat-label class="font-s">Choose eDoc</mat-label>
              <mat-select [formControl]="rate.get('document_type')" (selectionChange)="calculateServicesToOffer()">
                <mat-option *ngFor="let service of servicesToOffer[edocForm.get('settings.implementation').value]" [value]="service">{{service}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-icon (click)="deleteEdocRate(i)" class="icon-m pointer" color="primary">delete</mat-icon>
          </div>

          <div class="d-flex flex-column flex-lg-row">
            <mat-form-field class="w-100 mr-2">
              <input [formControl]="rate.get('provider_price.amount')" type="number" min="0" numeric decimals="5" matInput placeholder="{{'common.text.edocs.edocPrice' | translate}}">
              <app-control-messages [control]="rate.get('provider_price.amount')"></app-control-messages>
            </mat-form-field>
            <mat-form-field class="w-100 mr-2">
              <input [formControl]="rate.get('margin.amount')" type="number" min="0" numeric decimals="5" matInput placeholder="{{'common.text.edocs.benefit' | translate}}">
              <app-control-messages [control]="rate.get('margin.amount')"></app-control-messages>
            </mat-form-field>
          </div>

          <div class="d-flex justify-content-between">
            <div class="text-grey">Total</div>
            <div>{{edocTotal(rate) ? edocTotal(rate) : 0}} €</div>
          </div>

        </mat-card>
      </div>

      <div *ngIf="servicesToOffer?.length" class="col-3">
        <mat-card class="card my-4 pointer">
          <div (click)="addEdocRate()" class="d-flex justify-content-center align-items-center card-center pointer">
            <mat-icon color="primary" class="mr-2">add_circle_outline</mat-icon>
            <mat-card-content class="font-m text-primary-color mb-0">Add type of edoc</mat-card-content>
          </div>
        </mat-card>
      </div>
    </div>

    <div class="d-flex mb-0 mx-4">
      <div class="text-grey mr-2">This provider allow discounts</div>
      <mat-slide-toggle [formControl]="edocForm.get('settings.allows_discount')"></mat-slide-toggle>
    </div>


    <h2 class="mt-5 form-header__title d-flex align-items-center">
      <mat-icon class="mr-2">portrait</mat-icon>
      Contact for eDocs
    </h2>

    <div class="d-flex flex-column py-3 w-50 p-4">
      <div class="font-weight-bold">Provider contact</div>
      <div class="d-flex align-items-center">
        <mat-icon class="mr-2">domain</mat-icon>
        <app-input-text class="w-100" placeholder="insurances.companyName" [maxLength]="50" [control]="edocForm.get('settings.provider_contact.company_name')"></app-input-text>
      </div>
      <div class="d-flex align-items-center">
        <mat-icon class="mr-2">person</mat-icon>
        <app-input-text class="w-100" placeholder="insurances.contactName" [maxLength]="50" [control]="edocForm.get('settings.provider_contact.contact_name')"></app-input-text>
      </div>
      <div class="d-flex align-items-center">
        <mat-icon class="mr-2"> mail_outline</mat-icon>
        <app-input-text class="w-100" placeholder="insurances.contactEmail" [maxLength]="60" [control]="edocForm.get('settings.provider_contact.contact_email')"></app-input-text>
      </div>
      <div class="d-flex align-items-center">
        <mat-icon class="mr-2"> phone</mat-icon>
        <app-input-text class="w-100" placeholder="insurances.contactPhone" [maxLength]="15" [control]="edocForm.get('settings.provider_contact.contact_phone')"></app-input-text>
      </div>
    </div>

    <h2 class="mt-5 form-header__title d-flex align-items-center mb-4">
      <mat-icon class="material-icons-outlined">description</mat-icon>
      Legal for eDocs
    </h2>
    <app-textarea class="mx-4 white-space" [control]="edocForm.get('settings.conditions_text.es')" [maxLength]="1500" [placeholder]="'edocs.edocConditionsEs'"></app-textarea>
    <app-textarea class="mx-4 white-space" [control]="edocForm.get('settings.conditions_text.en')" [maxLength]="1500" [placeholder]="'edocs.edocConditions'"></app-textarea>
    <div class="font-weight-bold my-2 mx-4">Legal files</div>
    <div class="d-flex mx-4"><span class="font-m text-light-grey">Select the provider to set the legal files (eDocs conditions)</span>
      <div class="ml-2 font-s text-light-grey font-weight-bold">
        <mat-radio-group [formControl]="edocForm.get('settings.terms_and_conditions')">
          <mat-radio-button value="fiata" class="mb-0 ml-1">Fiata</mat-radio-button>
          <mat-radio-button value="transfollow" class="mb-0 ml-2">Transfollow</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end mt-2">
    <button mat-flat-button color="primary" (click)="addAndEditEdocsProvider()">Save eDocs configuration</button>
  </div>
</div>
