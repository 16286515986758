import io from 'socket.io-client';
import { EventEmitter, Injectable } from '@angular/core';
import { CacheService } from './cache.service';
import { EventCommonService } from '../../../common/services/eventCommon.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PushBOService {

  public updateNotifications: EventEmitter<void> = new EventEmitter<void>();

  private socket: SocketIOClient.Socket;

  constructor(private cacheService: CacheService, private eventService: EventCommonService) {
    this.cacheService.getToken() ? this.connect() : this.disconnect();
    this.eventService.login.subscribe(() => {
      this.cacheService.getToken() ? this.connect() : this.disconnect();
    });
  }

  connect() {
    this.socket = io(environment.url, { query: { token: this.cacheService.getToken() }, path: '/api/socket.io' });

    this.socket.on('notification.new', notification => this.updateNotifications.emit(notification));
  }

  disconnect() {
    this.socket.disconnect();
  }

}
