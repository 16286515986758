import { Injectable, Provider } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { noop, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BillingInfo, InvoicesWrapper, PlansWrapper, SubscriptionWrapper, UsageWrapper } from '../models/billing.models';
import { EventCommonService } from './eventCommon.service';
import {
  ChatWrapper,
  Company,
  Conversation, EDOCS, EdocsWrapper,
  Environment,
  FavoriteCompanies,
  Financing,
  LoginWrapper,
  Notification,
  NotificationWrapper,
  PermsWrapper,
  Record,
  User,
  UserNotification,
} from '../models/app.models';
import { ToastrService } from 'ngx-toastr';
import { Chat, ChatsWrapper } from '../models/chat.models';
import { InsuranceOfferDetailWrapper, InsuranceOfferWrapper } from '../models/insurance.models';
import { ChargesWrapper } from '../models/charges.models';
import { Router } from '@angular/router';
import { ProvidersWrapper, ProviderWrapper } from '../models/providers.models';

@Injectable({ providedIn: 'root' })
export class ApiCommonService {

  endpoint = this.environment.url + '/api';

  constructor(private environment: Environment, private router: Router, private http: HttpClient, public snackBar: ToastrService, private translate: TranslateService, private eventService: EventCommonService) {
  }

  // SHARED
  getLastVersion(): Observable<string> {
    // Parece que la version 9 de angular no tiene tipado el responseType: 'text'
    // @ts-ignore
    return this.http.get<string>('/assets/version.txt', { responseType: 'text' }).pipe(map(res => res.replace('\n', '')), catchError(err => this.handleError(err)));
  }

  signUp(data): Observable<any> {
    return this.eventService.loadingOn(this.http.post<any>(`${this.endpoint}/user`, data)).pipe(catchError(err => this.handleError(err)));
  }

  login(data): Observable<any> {
    const param = !this.environment.bo ? 'user' : 'user-admin';
    return this.http.post<any>(`${this.endpoint}/${param}/login`, data).pipe(catchError(err => this.handleError(err)));
  }

  getUser(): Observable<User> {
    const param = !this.environment.bo ? 'user' : 'user-admin';
    return this.http.get<User>(`${this.endpoint}/${param}`).pipe(catchError(err => this.handleError(err)));
  }

  editUser(data: { name?: string, pushSubscription?: PushSubscriptionJSON, notifications?: UserNotification }): Observable<User> {
    return this.http.put<User>(`${this.endpoint}/user/me`, data).pipe(catchError(err => this.handleError(err)));
  }

  resendValidationEmail(): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/user/resend-email-verification`, {}).pipe(catchError(err => this.handleError(err)));
  }

  validateEmail(data: { email_token: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`${this.endpoint}/user/email-verification`, data).pipe(catchError(err => this.handleError(err)));
  }

  getBillingInfo(companyId?): Observable<any> {
    const param = !this.environment.bo ? 'own' : companyId;
    return this.http.get<any>(`${this.endpoint}/billing/${param}/info`).pipe(catchError(err => this.handleError(err)));
  }

  getCompanyInvoices(companyId?): Observable<InvoicesWrapper> {
    const param = !this.environment.bo ? 'own' : companyId;
    return this.eventService.loadingOn(this.http.get<InvoicesWrapper>(`${this.endpoint}/billing/${param}/invoices`), 'gettingInvoices').pipe(catchError(err => this.handleError(err)));
  }

  getCompanyUsage(companyId?): Observable<UsageWrapper> {
    const param = !this.environment.bo ? 'own' : companyId;
    return this.http.get<UsageWrapper>(`${this.endpoint}/billing/${param}/usage`).pipe(catchError(err => this.handleError(err)));
  }

  getCompanyCharges(companyId?): Observable<ChargesWrapper> {
    const param = !this.environment.bo ? 'own' : companyId;
    return this.http.get<ChargesWrapper>(`${this.endpoint}/billing/${param}/charges/usage`).pipe(catchError(err => this.handleError(err)));
  }

  getPlans(code?: string): Observable<PlansWrapper> {
    const query = code ? `?promotional_code=${code}` : '';
    return this.http.get<PlansWrapper>(`${this.endpoint}/billing/base-plans${query}`).pipe(catchError(err => this.handleError(err)));
  }

  getPlan(plan: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/billing/base-plans/${plan}`).pipe(catchError(err => this.handleError(err)));
  }

  setBillingInfo(data, message, companyId?, fullscreen?): Observable<BillingInfo> {
    const param = !this.environment.bo ? 'own' : companyId;
    return this.eventService.loadingOn(this.http.patch<any>(`${this.endpoint}/billing/${param}/info`, data), message, fullscreen).pipe(catchError(err => this.handleError(err)));
  }

  getSubscription(companyId?): Observable<SubscriptionWrapper> {
    const param = !this.environment.bo ? 'own' : companyId;
    return this.http.get<any>(`${this.endpoint}/billing/${param}/subscription`).pipe(catchError(err => this.handleError(err)));
  }

  getInvitedUsers(companyId?) {
    const param = !this.environment.bo ? 'own' : companyId;
    return this.http.get<any>(`${this.endpoint}/company/${param}/members`).pipe(catchError(err => this.handleError(err)));
  }

  requestApiKeyToken(userId): Observable<{ token: string }> {
    return this.http.post<{ token: string }>(`${this.endpoint}/user/api-key`, userId).pipe(catchError(err => this.handleError(err)));
  }

  deleteApiKeyToken(userId): Observable<void> {
    return this.http.delete<void>(`${this.endpoint}/user/api-key/${userId}`).pipe(catchError(err => this.handleError(err)));
  }

  setSubscription(data, companyId?): Observable<any> {
    const param = !this.environment.bo ? 'own' : companyId;
    return this.http.put<any>(`${this.endpoint}/billing/${param}/subscription`, data).pipe(catchError(err => this.handleError(err)));
  }

  // Recovery password
  requestRecoveryEmail(data) {
    const param = !this.environment.bo ? 'user' : 'user-admin';
    return this.http.post(`${this.endpoint}/${param}/recovery/`, data).pipe(catchError(err => this.handleError(err)));
  }

  // FRONT SERVICES//

  // USERS

  addUserCompany(data): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/user/add-company`, data).pipe(catchError(err => this.handleError(err)));
  }

  // ONBOARDING
  getCompany(): Observable<Company> {
    return this.http.get<Company>(`${this.endpoint}/company/me`).pipe(catchError(err => this.handleError(err)));
  }

  getUserOnboarding(token: string): Observable<User> {
    return this.http.get<User>(`${this.endpoint}/user/onboarding?token=${token}`).pipe(catchError(err => this.handleError(err)));
  }

  acceptTC(): Observable<User> {
    return this.http.post<User>(`${this.endpoint}/user/onboarding/tc`, {}).pipe(catchError(err => this.handleError(err)));
  }

  sendPassword(data, token?: string): Observable<LoginWrapper> {
    token = token ? '?token=' + token : '';
    return this.http.post<LoginWrapper>(`${this.endpoint}/user/onboarding/password${token}`, data).pipe(catchError(err => this.handleError(err)));
  }

  editCompany(data: Company, files): Observable<Company> {
    const formData: FormData = this.formDataBuilder(data, files);
    return this.eventService.loadingOn(this.http.put<Company>(`${this.endpoint}/company/${data._id}`, formData), 'saving', true).pipe(catchError(err => this.handleError(err)));
  }

  editCompany2(companyId: string, data: any): Observable<Company> {
    return this.eventService.loadingOn(this.http.put<Company>(`${this.endpoint}/company/${companyId}`, data), 'saving', true).pipe(catchError(err => this.handleError(err)));
  }

  uploadLogo(id: string, file): Observable<Company> {
    const formData: FormData = this.formDataBuilder({}, file);
    return this.eventService.loadingOn(this.http.put<Company>(`${this.endpoint}/company/${id}/upload-logo`, formData), 'logo', true).pipe(catchError(err => this.handleError(err)));
  }

  requestCompanyValidation(id: string) {
    return this.eventService.loadingOn(this.http.put<any>(`${this.endpoint}/company/${id}/request-validation/`, {}), 'request', true).pipe(catchError(err => this.handleError(err)));
  }

  getFileDownloadLink(data) {
    return this.http.get<any>(`${this.endpoint}/${data.location}/${data.id}/files/${data.documentId}/versions/${data.versionId}/download`).pipe(catchError(err => this.handleError(err)));
  }

  cancelChangeSubscription() {
    return this.http.delete<any>(`${this.endpoint}/billing/own/subscription/next`).pipe(catchError(err => this.handleError(err)));
  }

  // COMPANY FAVORITES
  getFavoriteCompanies(): Observable<FavoriteCompanies> {
    return this.http.get<FavoriteCompanies>(`${this.endpoint}/favorites-company/`).pipe(catchError(err => this.handleError(err)));
  }

  saveFavoriteCompanie(data): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/favorites-company/`, data).pipe(catchError(err => this.handleError(err)));
  }

  deleteFavoriteCompanie(type: string, index: number): Observable<any> {
    return this.http.delete<any>(`${this.endpoint}/favorites-company/${type}/${index}`).pipe(catchError(err => this.handleError(err)));
  }

  // RECORDS
  getRecords(start, count, state, search?, participant?: boolean): Observable<any> {
    const participantQuery = participant ? `&participant=1` : `&participant=0`;
    const searchQuery = search ? `&search=${search}` : '';
    const countQuery = count ? `&count=${count}` : '';
    return this.http.get<Record[]>(`${this.endpoint}/record?start=${start * count}&state=${state}${countQuery}${searchQuery}${participantQuery}`).pipe(catchError(err => this.handleError(err)));
  }

  getRecord(id: string): Observable<any> {
    return this.eventService.loadingOn(this.http.get<Record>(`${this.endpoint}/record/${id}`)).pipe(catchError(err => this.handleError(err)));
  }

  createRecord(record: Record, files): Observable<any> {
    const formData: FormData = this.formDataBuilder(record, files);
    return this.eventService.loadingOn(this.http.post<any>(`${this.endpoint}/record`, formData)).pipe(catchError(err => this.handleError(err)));
  }

  cloneRecord(id): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/record/${id}/clone`, {}).pipe(catchError(err => this.handleError(err)));
  }

  getCVS(): Observable<any> {
    // @ts-ignore
    return this.http.get<any>(`${this.endpoint}/record/csv`, {responseType: 'text', observe: 'response'}).pipe(catchError(err => this.handleError(err)));
  }

  setCompliance(id: string, data): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/record/${id}/compliance`, data).pipe(catchError(err => this.handleError(err)));
  }

  editRecordWithFiles(record: Record, files): Observable<any> {
    const id = record._id;
    delete record._id;
    delete record.sequence;
    const formData: FormData = this.formDataBuilder(record, files);
    return this.eventService.loadingOn(this.http.put<any>(`${this.endpoint}/record/${id}`, formData)).pipe(catchError(err => this.handleError(err)));
  }

  closeRecord(id: string, state): Observable<Record> {
    return this.http.put<any>(`${this.endpoint}/record/${id}/state`, { state }).pipe(catchError(err => this.handleError(err)));
  }

  sendToAPI(id: string): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/record/${id}/send`, {}).pipe(catchError(err => this.handleError(err)));
  }

  // CONVERSATIONS DEPRECATED
  sendMessage(data): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/chat/${data.record_id}/${data.receiver}/messages`, { content: data.content }).pipe(catchError(err => this.handleError(err)));
  }

  getAllConversations(): Observable<ChatWrapper> {
    return this.http.get<ChatWrapper>(`${this.endpoint}/chat`).pipe(catchError(err => this.handleError(err)));
  }

  getOneConversation(record_id: string, actor): Observable<Conversation> {
    return this.http.get<Conversation>(`${this.endpoint}/chat/${record_id}/${actor}`).pipe(catchError(err => this.handleError(err)));
  }

  // INSURANCE

  getRecordInsuranceOffers(record_id: string): Observable<InsuranceOfferWrapper> {
    return this.http.get<InsuranceOfferWrapper>(`${this.endpoint}/insurance-offers?record=${record_id}`).pipe(catchError(err => this.handleError(err)));
  }

  // client
  acceptInsuranceOfferClient(offer_id: string, data): Observable<InsuranceOfferDetailWrapper> {
    return this.http.post<InsuranceOfferDetailWrapper>(`${this.endpoint}/insurance-offers/${offer_id}/client-accept`, data).pipe(catchError(err => this.handleError(err)));
  }
  rejectInsuranceOfferClient(offer_id: string, data: { reason: string, reason_test?: string }): Observable<InsuranceOfferWrapper> {
    return this.http.post<InsuranceOfferWrapper>(`${this.endpoint}/insurance-offers/${offer_id}/client-reject`, data).pipe(catchError(err => this.handleError(err)));
  }

  // provider
  rejectInsuranceOfferProvider(offer_id: string, data: { reason: { code: string, text: string } }): Observable<InsuranceOfferDetailWrapper> {
    return this.http.post<InsuranceOfferDetailWrapper>(`${this.endpoint}/insurance-offers/${offer_id}/provider-reject`, data).pipe(catchError(err => this.handleError(err)));
  }

  acceptInsuranceOfferProvider(offer_id: string): Observable<InsuranceOfferDetailWrapper> {
    return this.http.post<InsuranceOfferDetailWrapper>(`${this.endpoint}/insurance-offers/${offer_id}/provider-accept`, {}).pipe(catchError(err => this.handleError(err)));
  }

  validateFinalInsurancePrice(offer_id, data: { final_provider_premium_amount: string }): Observable<InsuranceOfferDetailWrapper> {
    return this.http.put<InsuranceOfferDetailWrapper>(`${this.endpoint}/insurance-offers/${offer_id}/final-prices`, data).pipe(catchError(err => this.handleError(err)));
  }

  getInsurances(start: number, count: number): Observable<InsuranceOfferWrapper> {
    return this.http.get<InsuranceOfferWrapper>(`${this.endpoint}/insurance-offers?start=${start * count}&count=${count}`).pipe(catchError(err => this.handleError(err)));
  }

  getInsurance(offer_id: string): Observable<InsuranceOfferDetailWrapper> {
    return this.http.get<InsuranceOfferDetailWrapper>(`${this.endpoint}/insurance-offers/${offer_id}`).pipe(catchError(err => this.handleError(err)));
  }

  markInsuranceOfferAsReaded(offer_id: string, read: boolean): Observable<void> {
    return this.http.put<void>(`${this.endpoint}/insurance-offers/${offer_id}/read`, { read }).pipe(catchError(err => this.handleError(err)));
  }

  adminAcceptInsuranceOffer(offer_id: string, data): Observable<InsuranceOfferWrapper> {
    return this.http.post<InsuranceOfferWrapper>(`${this.endpoint}/insurance-offers/${offer_id}/admin-accept`, data).pipe(catchError(err => this.handleError(err)));
  }

  adminRejectInsuranceOffer(offer_id: string, data: { reason: { code: string, text: string } }): Observable<InsuranceOfferWrapper> {
    return this.http.post<InsuranceOfferWrapper>(`${this.endpoint}/insurance-offers/${offer_id}/admin-reject`, data).pipe(catchError(err => this.handleError(err)));
  }

  // CHATS V2

  createChat(data): Observable<Chat> {
    return this.http.post<Chat>(`${this.endpoint}/chat`, data).pipe(catchError(err => this.handleError(err)));
  }

  getRecordChats(start: number, count: number, record_id: string, type?: string): Observable<ChatsWrapper> {
    let query = '';
    if (type === 'all') query = 'type=company';
    if (record_id && type !== 'all') query += `&record=${record_id}`;
    return this.http.get<ChatsWrapper>(`${this.endpoint}/chat?start=${start}&count=${count}&${query}`).pipe(catchError(err => this.handleError(err)));
  }

  getOneChat(chat_id): Observable<Chat> {
    return this.http.get<Chat>(`${this.endpoint}/chat/${chat_id}`).pipe(catchError(err => this.handleError(err)));
  }

  addMessageToChat(chat_id, message): Observable<Chat> {
    return this.http.put<Chat>(`${this.endpoint}/chat/${chat_id}/message`, message).pipe(catchError(err => this.handleError(err)));
  }

  markMessageAsUnread(chat_id, message_index) {
    return this.http.put<any>(`${this.endpoint}/chat/${chat_id}/unread/${message_index}`, {}).pipe(catchError(err => this.handleError(err)));

  }

  silenceParticipant(participant_id): Observable<void> {
    return this.http.put<void>(`${this.endpoint}/chat/${participant_id}/silence`, {}).pipe(catchError(err => this.handleError(err)));
  }
  getRecordParticipants(record_id): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/record/${record_id}/participants`).pipe(catchError(err => this.handleError(err)));
  }

  editParticipants(chat_id, data): Observable<Chat> {
    return this.http.put<Chat>(`${this.endpoint}/chat/${chat_id}/participants`, data).pipe(catchError(err => this.handleError(err)));
  }

  // INSURANCES

  addProvider(data): Observable<ProviderWrapper> {
    return this.http.post<ProviderWrapper>(`${this.endpoint}/providers`, data).pipe(catchError(err => this.handleError(err)));
  }

  getProvider(companyId: string, service_type?: string): Observable<ProvidersWrapper> {
    //    const queryEdocType = edoc_type ? '&edoc_type=' + edoc_type : '';
    return this.http.get<ProvidersWrapper>(`${this.endpoint}/providers/?companyId=${companyId}`).pipe(catchError(err => this.handleError(err)));
  }

  editProvider(providerId, data): Observable<ProviderWrapper> {
    return this.http.patch<ProviderWrapper>(`${this.endpoint}/providers/${providerId}`, data).pipe(catchError(err => this.handleError(err)));
  }

  updateIntegrations(providerId, data): Observable<Provider> {
    return this.http.post<Provider>(`${this.endpoint}/providers/${providerId}`, data).pipe(catchError(err => this.handleError(err)));
  }

  // INTEGRATIONS

  getIntegrations(companyId: string, serviceType?: string): Observable<any> {
    const param = !this.environment.bo ? 'own' : companyId;
    const query = serviceType ? '?service=' + serviceType : '';
    return this.http.get<any>(`${this.endpoint}/integrations/${param}${query}`).pipe(catchError(err => this.handleError(err)));
  }

  getImplementations() {
    return this.http.get<any>(`${this.endpoint}/edocs/implementations`).pipe(catchError(err => this.handleError(err)));
  }

  updateIntegration(integrationId, data): Observable<any> {
    return this.http.put<any>(`${this.endpoint}/integrations/${integrationId}`, data).pipe(catchError(err => this.handleError(err)));
  }

  // LANG
  setLang(language: string) {
    return this.http.put<any>(`${this.endpoint}/user/me/language`, { language }).pipe(catchError(err => this.handleError(err)));
  }

  getAppPermissions(): Observable<PermsWrapper> {
    return this.http.get<PermsWrapper>(`${this.endpoint}/permissions/.`).pipe(catchError(err => this.handleError(err)));
  }

  // PERMISSIONS
  getRecordPermissions(record_id: string, resource?: string) {
    const resourceQuery = resource ? `.${resource}` : '';
    return this.http.get<any>(`${this.endpoint}/permissions/records.${record_id}${resourceQuery}`).pipe(catchError(err => this.handleError(err)));
  }

  getPermissionsByRole(record_id, role?) {
    const roles = role ? `?roles=${role}` : '';
    return this.http.get<any>(`${this.endpoint}/permissions/records.${record_id}${roles}`).pipe(catchError(err => this.handleError(err)));
  }

  // NOTIFICATIONS

  getNotifications(start: number, count: number): Observable<NotificationWrapper> {
    const countQuery = count ? `&count=${count}` : '';
    return this.http.get<NotificationWrapper>(`${this.endpoint}/notifications?start=${start * count}${countQuery}`).pipe(catchError(err => this.handleError(err)));
  }

  markAsReadedNotification(id: string, isReaded): Observable<Notification> {
    return this.http.put<Notification>(`${this.endpoint}/notifications/${id}`, { read: isReaded }).pipe(catchError(err => this.handleError(err)));
  }

  markAllAsRead(): Observable<void> {
    return this.http.put<void>(`${this.endpoint}/notifications/all`, {}).pipe(catchError(err => this.handleError(err)));
  }

  // BACKOFFICE SERVICES //

  // COMPANIES

  getCompanies(search?: string) {
    const searchQuery = search ? `&search=${search}` : '';
    return this.eventService.loadingOn(this.http.get<any>(`${this.endpoint}/company?${searchQuery}`), 'Loading companies', true).pipe(catchError(err => this.handleError(err)));
  }

  getCompanyDetail(id) {
    return this.eventService.loadingOn(this.http.get<Company>(`${this.endpoint}/company/${id}`)).pipe(catchError(err => this.handleError(err)));
  }

  changePassword(data, token?: string): Observable<Company> {
    token = token ? 'token=' + token : '';
    return this.http.put<Company>(`${this.endpoint}/user-admin/password?${token}`, data).pipe(catchError(err => this.handleError(err)));
  }

  createCompany(data) {
    return this.eventService.loadingOn(this.http.post<any>(`${this.endpoint}/user/create-company`, data)).pipe(catchError(err => this.handleError(err)));
  }

  // VISIBILITY MATRIX //

  getRootVisibilityMatrix() {
    return this.http.get<any>(`${this.endpoint}/visibility-matrix/root`).pipe(catchError(err => this.handleError(err)));
  }

  getVisibilityMatrix(record_id: string, type: 'record' | 'root' | 'company') {
    return this.http.get<any>(`${this.endpoint}/visibility-matrix/${type}/${record_id}`).pipe(catchError(err => this.handleError(err)));
  }

  setVisibilityMatrix(id, type, data) {
    return this.http.put<any>(`${this.endpoint}/visibility-matrix/${type}/${id}`, data).pipe(catchError(err => this.handleError(err)));
  }

  // FINANCING

  getFinancingFromRecord(financing_id: string): Observable<Financing> {
    return this.http.get<Financing>(`${this.endpoint}/financing-requests-bbva/${financing_id}`).pipe(catchError(err => this.handleError(err)));
  }

  setFinancingFormRecord(data, financing_id: string): Observable<Financing> {
    return this.http.patch<Financing>(`${this.endpoint}/financing-requests-bbva/${financing_id}`, data).pipe(catchError(err => this.handleError(err)));
  }

  // DOCUMENTAI

  getInvoiceData(file): any {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<any>(`${this.endpoint}/documentai/invoice`, formData).pipe(catchError(err => this.handleError(err)));

  }
  // DATA //
  getLocations(location: string) {
    return this.http.get<any>(`${this.endpoint}/data/locations/${location}?select=code,displayName:name&asObject=code`).pipe(catchError(err => this.handleError(err)));
  }

  // EDOCS

  generateEdoc(recordId: string, data): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/record/${recordId}/edocs`, data).pipe(catchError(err => this.handleError(err)));
  }

  generateEdocNEW(data): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/edocs`, data).pipe(catchError(err => this.handleError(err)));
  }

  // TODO cambiar el nombre a get Edocs from record
  getAllEdocs(record_id: string, edoc_type: EDOCS): Observable<EdocsWrapper> {
    const queryEdocType = edoc_type ? '&edoc_type=' + edoc_type : '';
    return this.http.get<EdocsWrapper>(`${this.endpoint}/edocs?record_id=${record_id}${queryEdocType}`).pipe(catchError(err => this.handleError(err)));
  }

  deleteEdocDraft(edoc_id) {
    return this.http.delete<void>(`${this.endpoint}/edocs/${edoc_id}`).pipe(catchError(err => this.handleError(err)));
  }

  getEdoc(edoc_id): Observable<any> {
    return this.http.get<void>(`${this.endpoint}/edocs/${edoc_id}`).pipe(catchError(err => this.handleError(err)));
  }

  // TODO tipar los edoc data de cada tipo de edoc y cambiar los any
  editEdocDraft(record_id: string, edoc_id: string, edoc_data: any, providerId: string): Observable<any> {
    const data = { edoc_data, provider: providerId };
    return this.http.patch<void>(`${this.endpoint}/edocs/${edoc_id}`, data).pipe(catchError(err => this.handleError(err)));
  }
  emitEdoc(record_id: string, edoc_id: string, termsAndConditionsHash: { terms_and_conditions_hash: string }): Observable<any> {
    return this.eventService.loadingOn(this.http.post<any>(`${this.endpoint}/edocs/${edoc_id}/issue`, termsAndConditionsHash), 'loading.emitting').pipe(catchError(err => this.handleError(err)));
  }

  getEdocPreview(edoc_id): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(`${this.endpoint}/edocs/${edoc_id}/preview`).pipe(catchError(err => this.handleError(err)));
  }

  // EDOCS BO
  getEdocs(start: number, count: number, status?: string[], sort?: string, filter?: string): Observable<any> {
    let url = `${this.endpoint}/edocs?start=${start * count}&count=${count}`;
    if (status) {
      url += '&status=';
      status.forEach(element => url += element + ',');
    }
    if (sort) url = url + `&sort=${sort}`;
    if (filter) url = url + `&search=${filter}`;
    return this.http.get<any>(url).pipe(catchError(err => this.handleError(err)));
  }

  postSimple(edocId: string): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/simple/${edocId}`, {}).pipe(catchError(err => this.handleError(err)));
  }

  // COMMON //

  log(message: string, translate = false, params?: any) {
    this.openSnackBar(message, translate, 'success', params).then(noop);
  }

  logWarn(message: string, translate = false, params?: any) {
    this.openSnackBar(message, translate, 'warning', params).then(noop);
  }

  logError(message: string, translate = false, params?: any) {
    this.openSnackBar(message, translate, 'error', params).then(noop);
  }

  private async openSnackBar(message: string, translate: boolean, method: 'success' | 'warning' | 'error', params?: any) {
    if (translate) message = await this.translate.get(message, params).toPromise();
    this.snackBar[method](message, null, { timeOut: method === 'success' ? 5000 : 15000 });
  }

  // TIMELINE //
  getTimeline(record_id: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.endpoint}/timeline/record/${record_id}`).pipe(catchError(err => this.handleError(err)));
  }

  // ERROR CONSTROLERS //

  private removeEmpty(obj: any) {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        this.removeEmpty(obj[key]);
      } else if (obj[key] === undefined || obj[key] === null) {
        delete obj[key];
      }
    });
    return obj;
  }

  private handleError(err: HttpErrorResponse) {

    if (err.status === 0 || ((err.status === 503 || err.status === 502) && !err.headers['access-control-allow-origin'])) {
      this.logError('toasts.noServerConnection', true);
      return throwError(this.translate.instant('toasts.noServerConnection'));
    }

    if (err.error.code === 'BACK_INVALID_TOKEN') this.router.navigate(['login']);

    if (err.error.code === 'BACK_USAGE_LIMIT_EXCEEDED') {
      this.getUser().subscribe( user => {
        const params = { ...err.error.params, product: this.translate.instant('common.usage.' + err.error.params.product) };
        if (user.guest) this.logWarn('common.errorsHTTP.BACK_USAGE_LIMIT_EXCEEDED_GUEST', true, params);
        else this.logWarn('common.errorsHTTP.BACK_USAGE_LIMIT_EXCEEDED', true, params);
      });
      return of(null);
    }

    if (err.error.code === 'BACK_RECORD_STORAGE_EXCESS') {
      this.logWarn('common.errorsHTTP.BACK_RECORD_STORAGE_EXCESS', true);
      return of(null);
    }

    if (err.error.code === 'BACK_INCONSISTENT_INVITES') {
      err.error.params.translatedActor = this.translate.instant('common.resources.' + err.error.params.actor);
    }

    if (err.error.code === 'BACK_VALIDATION') {
      let msg = err.error.params.validation_errors[0].dataPath;
      if (msg[0] === '.') msg = msg.slice(1);
      err.error.params.validation = msg.split('.').join('/');
    }

    this.logError('common.errorsHTTP.' + err.error.code, true, err.error.params);
    return throwError(err);
  }

  private formDataBuilder(data, files) {
    const formData: FormData = new FormData();
    for (const key in files) {
      if (files.hasOwnProperty(key) && (data[key] === null || data[key] === undefined)) {
        formData.append(key, files[key]);
      }
    }
    this.removeEmpty(data);
    formData.append('data', JSON.stringify(data));

    return formData;
  }
}
