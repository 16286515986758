import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CacheService } from '../services/cache.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private cacheService: CacheService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.cacheService.getToken() || '';
    return next.handle(req.clone({ setHeaders: { Authorization: 'Bearer ' + token } }));
  }
}
