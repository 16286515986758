import { Injectable } from '@angular/core';
import { ApiCommonService } from '../../../common/services/apiCommon.service';
import { User } from '../../../common/models/app.models';

@Injectable({ providedIn: 'root' })
export class CacheService {

  user: User;

  constructor(private apiService: ApiCommonService) { }

  setUser(user: User, token: string) {
    this.user = user;
    window.localStorage.setItem('token', token);
  }

  async getUser(): Promise<User> {
    if (!this.user && this.getToken()) this.user = await this.apiService.getUser().toPromise();
    return this.user;
  }

  getToken(): string {
    return window.localStorage.getItem('token');
  }

  clear() {
    window.localStorage.clear();
  }
}
