import { Component, OnInit } from '@angular/core';
import { CacheService } from '../services/cache.service';
import { Router } from '@angular/router';
import packageJSON from '../../../../package.json';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { EventCommonService } from '../../../common/services/eventCommon.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root', templateUrl: './app.component.html', styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  version: string;
  isLogged = false;
  environment = environment;

  constructor(private titleService: Title, private cacheService: CacheService, private router: Router, public eventService: EventCommonService, private translate: TranslateService) { }

  async ngOnInit() {
    if (environment.bo && !environment.production) this.titleService.setTitle('Usyncro - Backoffice');
    this.translate.setDefaultLang('en');
    this.version = packageJSON.version;

    this.eventService.login.subscribe(() => {
      this.isLogged = !!this.cacheService.getToken();
    });

    if (this.cacheService.getToken()) this.eventService.login.emit();
  }

  logout() {
    this.cacheService.clear();
    this.isLogged = false;
    this.router.navigate(['login']);
  }
}
