import { Component, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiCommonService } from '../../services/apiCommon.service';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '../../models/app.models';
import { environment } from '../../common.environment';
import { Subscription } from '../../models/billing.models';
import { noop } from 'rxjs';
import { SegmentService } from 'ngx-segment-analytics';
import { Helper } from '../../../front/src/app/helper';

@Component({
  selector: 'app-company-invitations',
  templateUrl: './company-invitations.component.html',
  styleUrls: ['./company-invitations.component.scss'],
})
export class CompanyInvitationsComponent implements OnInit {
  @Input() user: User;
  @Input() hasHeader = true;
  @Input() companyId: string;
  @Input('subscription')
  set subscription(value: Subscription) {
    if (value) {
      this._subscription = value;
      if (!environment.bo && this._subscription && this._subscription.plan) this.checkLimits(this._subscription);
    }
  }
  @Input() permissions: string[];
  @ViewChild('invitedUsersPaginator') invitedUsersPaginator: MatPaginator;

  private _subscription: Subscription;
  isBO = environment.bo;
  limitInvitationMembers;
  invitationsMemberUsed = 0;
  invitedUsersColumns: string[] = ['email', 'name', 'state', 'companyOwner', 'registered_at'];
  dataInvitedUsers = null;
  newCompanyUserForm: FormGroup;
  langs: { cod: string, value: string }[] = [];
  managers: string[] = [];
  creator_is_manager: boolean;

  showAddMemberForm: boolean;

  constructor(private apiService: ApiCommonService, private formBuilder: FormBuilder, private segment: SegmentService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.langs = Helper.getAvailableLangs();
    if (!environment.bo) {
      this.invitedUsersColumns.push('token', 'managers');
      this.createNewCompanyUserForm();
      this.apiService.getCompany().subscribe(res => {
        this.creator_is_manager = res.creator_is_manager;
        this.managers = res.managers.map(e => e._id);
        this.companyId = res._id;
      });
    }
    this.invitedUsersColumns.push('active');
    this.getInvitedUsers();
  }

  checkLimits(subscription) {
    this.limitInvitationMembers = subscription.plan.products.find(el => el.id === 'MEMBERS').limit;
  }

  createNewCompanyUserForm() {
    this.newCompanyUserForm = this.formBuilder.group({
      email: [null, [Validators.email, Validators.required]],
      company_owner: [false, [Validators.required]],
      name: [null, [Validators.required]],
      language: [null, [Validators.required]],
    });
  }

  addUserCompany() {
    if (this.newCompanyUserForm.invalid) {
      return this.newCompanyUserForm.markAllAsTouched();
    }
    if (this.newCompanyUserForm.valid) {
      this.apiService.addUserCompany(this.newCompanyUserForm.getRawValue()).subscribe(res => {
        this.segment.track('Account Added User', {
          event_type: 'USER',
          event_source: 'front',
          type: this.hasHeader ? 'profile' : 'onboarding', // determines whether this action takes place on profile or during welcome tour (onboarding)
          role: res.company_owner ? 'Company owner' : 'Company user',
        }, { context: { group_id: this.user?.company?._id, company_name: this.user?.company?.name } }).then(noop);
        this.apiService.log('toasts.userInvited', true);
        this.createNewCompanyUserForm();
        this.dataInvitedUsers.data.unshift(res);
        this.updateNewUsersTable(this.dataInvitedUsers.data);
      });
    }
  }

  getInvitedUsers() {
    this.apiService.getInvitedUsers(this.companyId).subscribe(res => this.updateNewUsersTable(res.reverse()));
  }

  updateNewUsersTable(invitedUsers) {
    this.dataInvitedUsers = new MatTableDataSource(invitedUsers);
    this.dataInvitedUsers.paginator = this.invitedUsersPaginator;
    this.invitationsMemberUsed = invitedUsers.filter(e => e.active).length;
  }

  requestApiKeyToken(user) {
    const data = { receiverId: user._id };
    this.apiService.requestApiKeyToken(data).subscribe(res => user.token = res.token);
  }

  deleteApiKeyToken(user) {
    this.apiService.deleteApiKeyToken(user._id).subscribe(res => {
      this.getInvitedUsers();
      this.apiService.log('toasts.apikeyDeleted', true);
    });
  }

  copyToClipboard(user) {
    navigator.clipboard.writeText(user.token);
    this.apiService.log('toasts.apikeyCopied', true);
  }

  showAddMember() {
    this.showAddMemberForm = !this.showAddMemberForm;
    this.cdr.detectChanges(); // Para que se actualice el template con el nuevo elemento antes de scrollear al final
    window.scrollTo(0, document.body.scrollHeight);
  }

  saveManager(userId, event) {
    if (event.checked) this.managers.push(userId);
    else this.managers = this.managers.filter(e => e !== userId);
    const data = {
      managers: this.managers,
    };
    this.apiService.editCompany2(this.companyId, data).subscribe(noop);
  }

  disableUsers(userId, event) {
    const data = {
      status: {
        user: userId,
        active: event.checked,
      },
    };
    this.apiService.editCompany2(this.companyId, data).subscribe(() => this.getInvitedUsers());
    this.segment.track('Account Enabled CheckBox Clicked', {
      event_type: 'USER',
      event_source: 'front',
      is_enabled: event.checked,
      user: userId,
    }, { context: { group_id: this.user?.company?._id, company_name: this.user?.company?.name } }).then(noop);
  }

  saveCreatorAsManager(event) {
    const data = {
      creator_is_manager: event.checked,
    };
    this.apiService.editCompany2(this.companyId, data).subscribe(noop);
  }
}
