<mat-form-field *ngIf="!onlyNumbers" class="w-100">
  <textarea [formControl]="control" [maxlength]="maxLength" [placeholder]="('common.text.'+ placeholder | translate)" matInput cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" class="padding-textarea"></textarea>
  <mat-hint align="end">{{control.value?.length || 0}}/{{maxLength}}</mat-hint>
  <app-control-messages [control]=" control"></app-control-messages>
</mat-form-field>

<mat-form-field *ngIf="onlyNumbers" class="w-100">
  <textarea OnlyNumbers [formControl]="control" [maxlength]="maxLength" [placeholder]="('common.text.'+ placeholder | translate)" matInput cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" class="padding-textarea"></textarea>
  <mat-hint align="end">{{control.value?.length || 0}}/{{maxLength}}</mat-hint>
  <app-control-messages [control]="control"></app-control-messages>
</mat-form-field>

