import { Component, Input, OnInit } from '@angular/core';
import { InsuranceOffer } from 'projects/common/models/insurance.models';
import countries from 'projects/common/components/common/autocomplete-select/countries.json';
import { FROZENGOODS, ROLES } from 'projects/common/models/app.models';

@Component({
  selector: 'app-shipment-data-insurance',
  templateUrl: './shipment-data-insurance.component.html',
  styleUrls: ['./shipment-data-insurance.component.scss'],
})
export class ShipmentDataInsuranceComponent implements OnInit {

  @Input() insuranceOffer: InsuranceOffer;
  Object = Object;
  countries = countries;
  ports = ['reception', 'shipment', 'unload', 'transfer', 'delivery', 'final'];
  FROZENGOODS = FROZENGOODS;
  actors = ['buyer', 'consignee', 'supplier', 'reception', 'deal', 'transport', 'fwd', 'fwo', 'documents', 'edocs', 'notify', 'transporter', 'payment', 'financing', 'destination', 'compliance', 'invitations', 'visibility_matrix', 'insurance'];
  ROLES = ROLES;

  constructor() { }

  ngOnInit(): void {
  }

}
