<div class="d-flex justify-content-end p-0 mb-2">
  <div class="d-flex align-items-center">
    <app-notifications-bar-bo *ngIf="isLogged" class="mt-4 mr-2 menu-icon"></app-notifications-bar-bo>
    <i *ngIf="isLogged" class="material-icons pointer menu-icon logout mr-4 mt-4" [matMenuTriggerFor]="menu">account_circle</i>
</div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="/">Manage companies</button>
    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu>
</div>
<div class="mx-auto d-flex flex-column mb-4 justify-content-center align-items-center">
  <a routerLink="/"><img src="../../assets/logo.png" class="logo-ecustoms"></a>
  <div class="d-flex align-items-baseline">
    <h2 class="mt-4">BACKOFFICE</h2>
    <div *ngIf="version" class="ml-1" style="font-size: x-small;margin-bottom: 1px;">v{{version}}</div>
  </div>
</div>

<router-outlet></router-outlet>

<div *ngIf="(eventService.loading | async) as loading" class="loading">
  <mat-spinner></mat-spinner>
</div>



