<div class="container">

  <app-simple-searcher (search)="applyFilter($event)" [placeholder]="'common.bo.search'" [typingSearch]="true" class="w-100"></app-simple-searcher>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef>Company name</th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> Creation date</th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.createdAt | date: 'short'}} </td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>State</th>
      <td mat-cell *matCellDef="let element" class="width" [ngClass]="{'accepted': element.state === STATES.ACCEPTED, 'rejected':  element.state === STATES.REJECTED, 'incomplete':  STATES[element.state] === STATES.INCOMPLETED}"> {{STATES[element.state]}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="p-3 pointer" *matRowDef="let row; columns: displayedColumns;" routerLink="/company-detail/{{row._id}}"></tr>
  </table>

  <mat-paginator class="mt-4" [pageSize]="10" [hidePageSize]="true" showFirstLastButtons [ngClass]="{'d-none': !dataSource?.data?.length}"></mat-paginator>

</div>


