<div *ngIf="user" class="container mt-5">
  <div class="d-flex justify-content-end mb-4">
    <button mat-stroked-button routerLink="/companies" class="mt-4" color="primary">Go back</button>
  </div>
  <div class="mat-elevation-z8 p-4 d-flex justify-content-between">
    <div class="py-3 border w-100 p-4">
      <div>
        <div class="font-weight-bold">Personal Data:</div>
        <div>Email: {{user.email}}</div>
        <div>Name</div>
        <div>CompanyName</div>
      </div>
      <button mat-flat-button (click)="openChangePasswordModal()" class="mt-4" color="primary">Change password</button>
    </div>
  </div>
</div>
